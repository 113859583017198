<template>
    <el-row style="margin-bottom: 30px">
      <el-col :span="24">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item><u>{{ primary }}</u></el-breadcrumb-item>
          <el-breadcrumb-item>{{ secondary }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
</template>

<script>

export default {
  name: "Breadcrumbs",
  props: [
    "primary",
    "secondary"
  ],
  methods: {

  },
  data(){
    return {

    }
  },
}

</script>