<template>

  <div :class="`table-spinner-container ${!noMinHeight ? 'table-spinner-container-min-height' : ''}`">
    <el-skeleton :rows="rows || 5" animated v-show="visible" />
    <div v-show="!visible">
    <slot/>
    </div>
<!--    <div class="table-spinner-overlay" v-show="visible">-->
<!--      <div class="table-spinner-conent">-->
<!--        <font-awesome-icon icon="spinner" class="fa-spin" style="font-size: 46px"/>-->
<!--        <h3 class="uppercase">Загрузка данных</h3>-->
<!--      </div>-->
<!--    </div>-->

  </div>

</template>


<script>

export default {
  name: 'TableSpinner',
  props: ["loading", "rows", "noTimeout", "noMinHeight"],
  watch: {
    loading(newVal){
      this.visible = newVal
      // if(newVal){
      //   clearTimeout(this.timeout)
      //   this.visible = newVal
      // }
      // else{
      //   if(this.noTimeout) this.visible = false
      //   else
      //     this.timeout = setTimeout(() => {
      //       this.visible = false
      //     }, 500)
      // }
    }
  },
  data(){
    return {
      visible: Boolean(this.loading) || false,
      timeout: null
    }
  }
}

</script>

<style>

.table-spinner-container {
  position: relative;
}

.table-spinner-container-min-height{
  min-height: 150px;
}

.table-spinner-overlay {
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(86, 118, 140, 0.9);
  z-index: 1000;
  min-height: 150px;
}

.table-spinner-conent{
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

</style>