<template>
  <el-dialog
      v-model="addEditFormVisible"
      :title="ruleForm._id ? 'Редактирование правила' : 'Новое правило'"
      class="modal-95-70-60"
  >

    <el-form label-position="top" style="padding-top: 30px">
      <el-row :gutter="20">
        <el-col :md="12" :xs="24">
          <el-form-item label="Тип блокировки" prop="type">
            <el-radio-group v-model="ruleForm.type">
              <el-radio :label="'0'">блокировать трафик с этими подписями</el-radio>
              <el-radio :label="'1'">блокировать весь трафик, кроме выбранных подписей</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="sender">
            <template #label>
              <div style="display:flex; align-items: center">
                <span style="margin-right: 10px">Отправитель</span>
                <el-input size="small" v-model="filterNames" style="width: auto"/>
              </div>
            </template>
            <el-divider />

            <el-checkbox-group v-model="ruleForm.sender">
              <el-checkbox
                  class="full-width"
                  v-for="name in senders"
                  :label="name.name"
                  v-show="nameMatchesFilter(name.name)"
              >
                {{ name.name }}
              </el-checkbox>
            </el-checkbox-group>
            <!--            <el-select v-model="ruleForm.sender" class="full-width" :multiple="true">-->
            <!--              <el-option-->
            <!--                  v-for="name in senders"-->
            <!--                  :value="name.name"-->
            <!--                  :label="name.name"-->
            <!--              />-->
            <!--            </el-select>-->
          </el-form-item>

          <el-form-item label=" ">
            <el-button type="success" @click="saveRule" :loading="saving">Сохранить</el-button>
          </el-form-item>
        </el-col>
        <el-col :md="12" :xs="24">
          <el-form-item label=" ">
            <el-select v-model="filterRouteType" class="full-width">
              <el-option label="Все направления" value="all"/>
              <el-option label="РФ" value="rf"/>
              <el-option label="СНГ" value="sng"/>
              <el-option label="СНГ + РФ" value="sng_rf"/>
              <el-option label="Зарубежка" value="foreign"/>
              <el-option label="Зарубежка + СНГ" value="foreign_sng"/>
            </el-select>
          </el-form-item>
          <el-form-item label="Группа операторов" prop="route">
            <el-checkbox v-model="checkAll">Выбрать все</el-checkbox>
            <el-divider/>
            <el-checkbox-group v-model="ruleForm.operator_group">

              <el-checkbox
                  class="full-width"
                  v-for="g in visibleOperatorGroups"
                  :label="g._id"
              >
                {{ g.title }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>

  </el-dialog>


  <el-button type="success" @click="showAddEditDialog()">Добавить правило</el-button>
  <TableSpinner :loading="loading" :rows="5">
    <blocked-routes-table :blocked-routes="blockedRoutes">
      <template #admin-actions>
        <el-table-column label="" width="180">
          <template #default="scope">
            <ActionsDropdown>
              <ActionButton icon="pencil-alt" @click="showAddEditDialog(scope.row)">Изменить</ActionButton>
              <ActionButton type="remove" @confirm="removeOne(scope.row)" :loading="removingOne">Удалить
              </ActionButton>
            </ActionsDropdown>
          </template>
        </el-table-column>
      </template>
    </blocked-routes-table>
  </TableSpinner>
</template>

<script>

import BlockedRoutesTable from "@shared/components/finance/blocked-routes-table.component.vue";
import TableSpinner from "@shared/components/layout/table-spinner.component.vue";

export default {
  name: "ClientRouteBlockRulesView",
  props: [
    "systemId",
    "senders"
  ],
  components: {
    TableSpinner,
    BlockedRoutesTable,
  },
  watch: {
    systemId() {
      this.refresh()
    },
    filterRouteType() {
      this.checkAll = false
      this.ruleForm.operator_group = []
    },
    checkAll(v) {
      if (v) this.ruleForm.operator_group = this.visibleOperatorGroups.map(g => g._id)
      else this.ruleForm.operator_group = []
    }
  },
  computed: {
    visibleOperatorGroups() {
      return this.$store.getters.operatorsGroups.filter(g => {
        return g.mcc && this.filterRouteType === 'all' ||
            (this.filterRouteType === 'rf' && g.mcc === '250') ||
            (this.filterRouteType === 'sng' && ['400', '283', '257', '401', '437', '259', '434', '436'].includes(g.mcc)) ||
            (this.filterRouteType === 'sng_rf' && ['400', '283', '257', '401', '437', '259', '434', '436', '250'].includes(g.mcc)) ||
            (this.filterRouteType === 'foreign' && !['400', '283', '257', '401', '437', '259', '434', '436', '250'].includes(g.mcc)) ||
            (this.filterRouteType === 'foreign_sng' && g.mcc !== '250')
      })
    }
  },
  methods: {
    refresh() {
      this.loading = true
      this.axios.get("/admin/routing/clientRouteBlockRules", {params: {systemId: this.systemId}})
          .then(resp => {
            this.blockedRoutes = resp.data.routes
            this.loading = false
          })
    },
    saveRule(rule) {
      this.saving = true
      this.axios.post("/admin/routing/upsertRouteBlockRule", {user_id: this.systemId, ...this.ruleForm})
          .then(resp => {
            this.addEditFormVisible = false
            this.$gNotify("Правило добавлено", "success")
            this.refresh()
            this.saving = false
          })
    },
    removeOne(rule) {
      this.axios.delete("/admin/routing/routeBlockRule", {params: {ruleId: rule._id}})
          .then(resp => {
            this.$gNotify("Правило удалено", "success")
            this.refresh()
          })
    },
    showAddEditDialog(rule) {
      if (!rule)
        this.ruleForm = this.ruleFormInitialState()
      else {
        this.ruleForm._id = rule._id
        this.ruleForm.sender = rule.sender || []
        this.ruleForm.operator_group = rule.operator_group
        this.ruleForm.type = rule.type

      }
      this.addEditFormVisible = true
    },
    ruleFormInitialState() {
      return {
        _id: "",
        operator_group: [],
        sender: [],
        type: "0",
      }
    },
    nameMatchesFilter(name) {
      if(!this.filterNames.trim()) return true
      let filter = new RegExp(escapeRegExp(this.filterNames.trim()), "gi")
      return filter.test(name)
    }

  },
  data() {
    return {
      blockedRoutes: [],
      addEditFormVisible: false,
      loading: false,
      saving: false,
      ruleForm: this.ruleFormInitialState(),
      filterRouteType: "all",
      checkAll: "",
      filterNames: ""
    }
  }
}

</script>

<style>

</style>