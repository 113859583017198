

import ServiceTemplatesTable from "@shared/components/lk/service-templates-table.component.vue";
import {defineComponent} from "vue";
import RemoveButton from "@shared/components/layout/removeButton.component.vue";
import moment from "moment";
import ClientSelect from "../../../admin_ui/components/layout/client-select.component.vue";
import iconv from "iconv-lite";

const STATUSES = {
  'awaiting': 'На рассмотрении',
  'active': 'Активен',
  'rejected': 'Отклонён'
}


interface ServiceTemplate {
  id: string,
  opg_id: string,
  sender_name: string,
  template: string,
  oper_group: string,
  traffic_type: string,
  addEditDate: string
}

export default defineComponent({
  name: "ServiceTemplatesView",
  props: ["systemId", "senders"],
  components: {
    ClientSelect,
    RemoveButton,
    ServiceTemplatesTable
  },
  watch: {
    systemId(v) {
      if (v) this.loadTemplates()
    },
    showTuplesOnly() {
      this.selection = []
      this.selectAll = []
    },
    selectAll(v) {
      if (v && v.length) {
        this.selection = (this.$refs.mTable as any).getVisibleIds()
      } else {
        this.selection = []
      }
    },
    "dummy.userIdV": function (v) {
      if (this.isGlobalView)
        (this.$refs.mTable as any).setSenders(this.sendersList(v))
    },
    senders() {
      if (!this.isGlobalView)
        (this.$refs.mTable as any).setSenders(this.sendersList(""))
    }
  },
  mounted() {
    if (this.isGlobalView) {
      this.loadTemplates()
      this.loadSenders()
    }
  },
  methods: {
    loadSenders() {
      this.axios.get("/admin/support/allActiveNamesNoUnique")
          .then(resp => {
            this.allSenders = resp.data
          })
    },
    loadTemplates() {
      this.templatesLoading = true
      let params = this.isGlobalView ? {} : {userId: this.systemId}
      this.axios.get("/admin/clients/serviceTemplates", {params})
          .then(resp => {
            this.templates = resp.data as ServiceTemplate[]
            this.templatesLoading = false
          })
    },

    globalSelectionReset() {
      this.removing = false
      this.selection = []
      this.selectAll = []
      this.loadTemplates()
    },
    removeSelected() {
      this.removing = true
      this.axios.post("/admin/clients/removeServiceTemplates", {templates: this.selection})
          .then(resp => {
            this.globalSelectionReset()
            this.$gNotify("Шаблоны удалены", "success")
          })
    },
    changeStatus(status) {
      this.$confirm(`Подтвердите изменение статуса на: ${STATUSES[status]}`)
          .then(resp => {
            if (!resp) return
            this.removing = true
            this.axios.post("/admin/clients/changeServiceTemplatesStatus", {templates: this.selection, status})
                .then(resp => {
                  this.globalSelectionReset()
                  this.$gNotify("Статус шаблонов изменён", "success")
                })
          })
          .catch(() => {
          })
    },


    sendersList(userId) {
      return (this.senders && this.senders.length ? this.senders : this.allSenders.filter(s => (s as any).user_id === userId)).map(s => s.name)
    },
    saveNewTemplates({addForm, onSuccess}) {
      this.saving = true
      let payload = {...addForm}
      payload.userId = this.isGlobalView ? this.dummy.userIdV : this.systemId
      payload.status = this.dummy.status

      this.axios.post("/admin/clients/addServiceTemplates", payload)
          .then(resp => {
            this.saving = false
            if (resp.data.error) {
              this.$gNotify("Такой шаблон для этой подписи уже назначен", "error")
            } else {
              onSuccess()
              this.$gNotify(`Добавлено: ${resp.data.added} | Дубли: ${resp.data.skipped}`, "success")
              this.loadTemplates()
            }

          })
    },
    sendAllTemplatesChangenotes() {
      this.noticesSending = true
      this.axios.post("/admin/clients/sendAllServiceTemplatesChangenotes")
          .then(resp => {
            this.noticesSending = false
            this.$gNotify(`Новых: ${resp.data.newT || 0}  Изменений: ${resp.data.changedT || 0}`, "success")
          })
          .catch(e => {
            console.error(e)
            this.noticesSending = false
            this.$gNotify("", "error")
          })
    },
  },
  computed: {
    visibleTemplates(): ServiceTemplate[] {
      if (!this.showTuplesOnly) return this.templates
      let duplicates = {}
      for (let t of this.templates) {
        if (!duplicates[t.opg_id]) duplicates[t.opg_id] = {}
        let k = `${t.template}_${t.sender_name || ""}_${t.traffic_type || ""}`
        if (!duplicates[t.opg_id][k]) duplicates[t.opg_id][k] = []
        duplicates[t.opg_id][k].push(t)
      }
      return Object.values(duplicates).map(d => Object.values(d as object).filter(vv => vv.length > 1).flat()).filter(v => v.length).flat() as ServiceTemplate[]
    },
    isGlobalView() {
      return !this.systemId && !this.senders
    },

  },
  data() {
    return {
      allSenders: [],
      selectAll: [],
      selection: [] as string[],
      templates: [] as ServiceTemplate[],
      showTuplesOnly: false,

      saving: false,
      removing: false,
      templatesLoading: false,


      dummy: {
        userIdF: "",
        userIdV: "",
        status: ""
      },
      noticesSending: false,

    }
  }
})

