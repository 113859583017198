<template>
  <div>
      <el-row :gutter="20" v-for="(rates, rate_type_name) in groupedRates" style="margin-bottom: 20px;">

        <el-col :span="24">
            <h4 style="margin-top: 20px;margin-bottom: 20px">{{ rate_type_name }}</h4>

            <el-table
                :data="rateTableRows(rates.sort((r1, r2) => r2.user_data.priority - r1.user_data.priority))"
                style="width: 100%; margin-bottom: 20px"
            >
              <el-table-column label="Название тарифа" min-width="200">
                <template #default="scope">
                  <el-button
                      v-if="!scope.row.delimiter"
                      text class="table-text-button blue-button"
                      @click="$emit('showRate', scope.row)"
                  >
                    <span v-if="!adminView">
                    {{scope.row.actual_data.client_name || scope.row.actual_data.name}}
                    </span>
                    <span v-else>
                      {{scope.row.actual_data.name}}
                    </span>

                  </el-button>


                  <span v-if="scope.row.delimiter"><b>{{scope.row.name}}:</b></span>
                  <span
                      style="color: var(--el-text-color-disabled); display: block; margin-top: 4px"
                      v-if="adminView && scope.row.actual_data && scope.row.actual_data.client_name"
                  >
                      {{scope.row.actual_data.client_name}}
                    </span>
                </template>
              </el-table-column>
              <el-table-column
                  v-if="rates[0] && [0, 1].includes(rates[0].actual_data.type)"

                  :label="rates[0] && rates[0].actual_data.type === 1 ? 'Входящие ящики' : 'Подписи'"
                  min-width="150">
                <template #default="scope">
                  <el-button
                      v-if="adminView && !scope.row.delimiter"
                      type="success" size="small"
                      @click=" $emit('cellClick', scope.row)"
                  >
                    {{rates[0] && rates[0].actual_data.type === 1 ? amountOfInboxes(scope.row) : amountOfPayedNames(scope.row)}}
                  </el-button>
                  <span v-if="!adminView && !scope.row.delimiter">
                    {{rates[0] && rates[0].actual_data.type === 1 ? amountOfInboxes(scope.row) : amountOfPayedNames(scope.row)}}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="Абонентская плата" min-width="150">
                <template #default="scope">
                  <span v-html="rateFee(scope.row)"/>
                </template>
              </el-table-column>
              <el-table-column label="Период назначения" :formatter="assignPeriodFormat" width="220"/>
<!--              :formatter="row => row.actual_data.subtype !== 8 ? row.user_data.priority : 'Высший'"-->
              <el-table-column prop="user_data.priority" label="Приоритет" min-width="100"/>

              <el-table-column prop="text" label="Активен" min-width="100">
                <template #default="slot">
                  <div :class="`circle ${rateActive(slot.row) ? 'success' : 'danger'}`" v-if="!slot.row.delimiter"></div>

                </template>
              </el-table-column>
              <slot name="admin-rate-actions" ></slot>

            </el-table>

<!--          </el-card>-->
        </el-col>
      </el-row>
  </div>
</template>

<script>


import moment from "moment"

export default {
  name: "ClientRatesTable",
  props: ["rates", "inboxes", "adminView"],
  methods: {
    rateFee(row) {
      if(row.delimiter) return ""
      let amountOfPaidNames = Math.max(0, row.user_data.sender_names.length - (Number(row.actual_data.free_names_amount) || 0))
      let oneNamePrice = (Number(row.actual_data.one_name_price) || 0)
      let namesFee = amountOfPaidNames * oneNamePrice
      let retval = '<span>' + (this.rateActive(row) ? String(
          (row.actual_data.abonent_fee || 0) +
          namesFee
      ) : '0') + ' руб.</span>'
      if (amountOfPaidNames > 1 && namesFee > 0)
        retval += '<br/>' +
            `<span style="margin-top: 4px; color: var(--el-text-color-disabled); display: block">${amountOfPaidNames} * ${oneNamePrice} руб.</span>`
      return retval
    },

    rateActive(row) {
      return !row.actual_data.locked && (
          moment(row.user_data.assigned_from).valueOf() < Date.now() &&
          (!row.user_data.assigned_to || Date.now() < moment(row.user_data.assigned_to).valueOf()))
    },
    assignPeriodFormat(row) {
      if(row.delimiter) return ""
      let retval = `с ${moment(row.user_data.assigned_from).format("DD.MM.YYYY")}`
      if (row.user_data.assigned_to) retval += ` по ${moment(row.user_data.assigned_to).format("DD.MM.YYYY")}`
      return retval
    },
    amountOfPayedNames(row) {
      if(row.delimiter || !['2', '3', '7', '8'].includes(String(row.actual_data.subtype))) return ""
      return row.user_data.sender_names.length
    },
    amountOfInboxes(row) {
      if(row.delimiter) return ""
      if (String(row.actual_data.subtype) === "0") return this.inboxes.filter(i => i.type === "common").length
      if (String(row.actual_data.subtype) === "1") return this.inboxes.filter(i => i.rate_id === row.actual_data._id).length
      return 0
    },
    rateTableRows(rates){
      let rows = []
      let intRates = rates.filter(r => r.actual_data.subtype === 8)
      let profiRates = rates.filter(r => r.actual_data.subtype === 7)
      let regularRates = rates.filter(r => ![7,8].includes(r.actual_data.subtype))
      if(intRates.length)
        rows.push({delimiter: true, name: "Международные подписи"})
      rows.push(...intRates)
      if(profiRates.length)
        rows.push({delimiter: true, name: "Профи"})
      rows.push(...profiRates)
      if(intRates.length)
        rows.push({delimiter: true, name: "Обычные подписи"})
      rows.push(...regularRates)
      return rows
    }

  },
  computed: {
    groupedRates() {
      return this.rates.reduce((acc, r) => {
        let rateTypeName = RATE_TYPE_NAMES[r.actual_data.type]
        if (!acc[rateTypeName]) acc[rateTypeName] = []
        acc[rateTypeName].push(r)
        return acc
      }, {})
    },
  },
  data() {
    return {


    }
  }
}

</script>

<style>

</style>