class User {
  constructor(userData, userPrivileges) {
    this.userData = userData
    this.privileges = userPrivileges
    this.roleId = userData.privilege_role || ""
    this._id = userData._id
    this.isShortReg = userData.is_short_reg
    this.offerAccepted = false
    this.info = userData.info
    this.balanceBlocked = userData.balanceBlocked || false
    this.cabinetSendingDenied = (userData.settings || {}).deny_cabinet_send === 1 || false
    this.utcOffset = Number(userData.info ? userData.info.utcTimeShift : 0) || 0
  }

  systemId(real = false) {
    return this.userData.role === "manager" && real ? this.userData.subuser_system_id : this.userData.system_id
  }

  isPhysic(){
    return this.info.user_type === "physic"
  }

  isManager(){
    return this.userData.role === "manager"
  }

  hasPrivilege(privilegeId){
    return this.privileges &&
    (Array.isArray(privilegeId) ? privilegeId.some(e => this.privileges.includes(e)) : this.privileges.includes(privilegeId))
  }

  hasRole(role){
    return this.roleId && role.id && role.id === this.roleId
  }

  get
  contractType(){
    return ((this.info || {}).contract || {}).type || "0"
  }

  get
  canDoMessaging(){
    return !this.balanceBlocked && this.userData.active !== 0
  }


  clear() {
    this.userData = {}
    this.privileges = []
    this._id = ""
    this.isShortReg = false
    this.info = {}
  }

}

module.exports = User