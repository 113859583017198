

import {defineComponent} from 'vue'
import RemoveButton from "@shared/components/layout/removeButton.component.vue";
import iconv from "iconv-lite";
import moment from "moment/moment";

export default defineComponent({
  name: "IndividualSenderSubstitutionRulesTable",
  components: {RemoveButton},
  props: ["individualRules", "loading", "showActions"],
  methods: {
    exportTable(){
      let element = document.createElement('a')
      let text = "Пользователь;Отправитель;Подмена на;Группа операторов;Влияет на тарификацию;\n"
      for(let row of this.individualRules) {
        text += `${row.forUser};`
        text += `${row.vis.sender};`
        text += `${row.vis.target};`
        text += `${row.vis.oper};`
        text += `${row.affects_rating === '0' ? 'Нет' : 'Да'};\n`

      }
      // let csvContent = iconv.encode(text, 'win1251').toString("base64");
      // element.setAttribute('href', 'data:application/octet-stream;base64,' + encodeURIComponent(csvContent))
      element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text))
      element.setAttribute('download', `Список индивидуальных подмен отправителя.csv`)
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element);
    }
  },
  data() {
    return {

    }
  },
})

