<template>
    <el-dialog v-model="subscriptionLinkVisible" title="Подписка на Telegram бота" class="modal-95-70-60">

      <p>Отсканируйте QR-код или перейдите по ссылке ниже.</p>
      <p>После перехода в бота нажмите кнопку "Запустить"</p>
      <p>Ссылка действительна в течении часа</p>
      <div style="width: 100%; text-align: center">
        <qrcode-vue :value="subscriptionLink" :size="200" level="H"/><br/>
        <a style="margin-top: 20px" :href="subscriptionLink" target="_blank">{{ subscriptionLink }}</a>
      </div>
    </el-dialog>
    <el-row :gutter="20">
      <el-col :span="24">
          <h4 class="report-parameters-title">Зарегистрированные подписки</h4>
          <TableSpinner :loading="loading">
            <el-table :data="subscriptions" style="width: 100%" data-test="rolesTable">
              <el-table-column prop="tgId" label="ID пользователя" min-width="150"/>
              <el-table-column prop="name" label="Имя" min-width="150"/>
              <el-table-column :formatter="v => formatDateTime(v.subscribedAt)" label="Время подписки" min-width="150"/>
              <el-table-column :formatter="v => formatDateTime(v.lastAction)" label="Последнее действие"
                               min-width="150"/>
              <el-table-column label="" min-width="150">
                <template #default="scope">
                  <remove-button @confirm="removeSubscription(scope.row)">Удалить</remove-button>
                </template>
              </el-table-column>
            </el-table>
            <p style="color: var(--el-text-color-secondary); word-break: break-word"><span style="color: red">*</span>&nbsp;После удаления подписки, данный пользователь больше не сможет взаимодействовать с ботом, однако диалог останется на его устройстве</p>
          </TableSpinner>
          <el-button type="success" @click="getSubscriptionLink()" style="margin-top: 20px">
            Подписаться на бота
          </el-button>

      </el-col>
    </el-row>
</template>

<script>

import RemoveButton from "../layout/removeButton.component.vue";
import QrcodeVue from 'qrcode.vue'

export default {
  name: "TgBot",
  components: {
    RemoveButton,
    QrcodeVue
  },
  mounted() {
    this.loadSubscriptions()
  },
  computed: {},
  methods: {
    loadSubscriptions() {
      this.loading = true
      this.axios.get("/user/getTgBotSubscriptions")
          .then(resp => {
            this.subscriptions = resp.data
            this.loading = false
          })
    },
    removeSubscription(subscription) {
      this.axios.delete("/user/removeTgBotSubscription", {params: {sId: subscription._id}}).then(resp => {
        this.loadSubscriptions()
      })
    },
    getSubscriptionLink() {
      this.subscriptionLinkLoading = true
      this.axios.get("/user/getTgBotSubscriptionLink")
          .then(resp => {
            this.subscriptionLink = resp.data.link
            this.subscriptionLinkLoading = false
            this.subscriptionLinkVisible = true
          })
    }
  },
  data() {
    return {
      subscriptions: [],
      loading: false,
      subscriptionLink: "",
      subscriptionLinkLoading: false,
      subscriptionLinkVisible: false
    }
  }
}

</script>