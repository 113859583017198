import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox } from "vue"

const _hoisted_1 = { style: {"display":"flex","flex-direction":"row","justify-content":"space-between","width":"100%","margin-bottom":"15px","align-items":"center"} }
const _hoisted_2 = ["value"]
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_remove_button = _resolveComponent("remove-button")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_service_templates_table = _resolveComponent("service-templates-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_dialog, {
      class: "modal-95-70-60",
      title: "Счетчики сообщений по шаблону",
      modelValue: _ctx.countersModalVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.countersModalVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table, {
          data: _ctx.currentCounters,
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              label: "Месяц",
              formatter: r => _ctx.bMonth(r.month)
            }, null, 8, ["formatter"]),
            _createVNode(_component_el_table_column, {
              label: "Счетчик",
              prop: "counter"
            })
          ]),
          _: 1
        }, 8, ["data"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_el_dialog, {
      class: "modal-95-70-60",
      title: _ctx.addForm.id ? 'Редактировать шаблон' : 'Добавить шаблоны',
      modelValue: _ctx.addFormVisible,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.addFormVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          "label-position": "top",
          model: _ctx.addForm
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, { label: "Группа операторов" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.addForm.operatorGroup,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.addForm.operatorGroup) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      value: "570e2be8b52438c11a38f99b",
                      label: "РФ_МегаФон"
                    }),
                    _createVNode(_component_el_option, {
                      value: "570e2c11b52438c11a38f99c",
                      label: "РФ_МТС"
                    }),
                    _createVNode(_component_el_option, {
                      value: "570e2c6cb52438c11a38f99d",
                      label: "РФ_Билайн"
                    }),
                    _createVNode(_component_el_option, {
                      value: "570e2cb6b52438c11a38f99e",
                      label: "РФ_Теле2"
                    }),
                    _createVNode(_component_el_option, {
                      value: "570e2f37b52438c11a38f9a0",
                      label: "РФ_МОТИВ"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "Тип трафика" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.addForm.trafficType,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.addForm.trafficType) = $event)),
                  class: "full-width"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      value: "service",
                      label: "Сервисный"
                    }),
                    _createVNode(_component_el_option, {
                      value: "authorisation",
                      label: "Авторизационный"
                    }),
                    _createVNode(_component_el_option, {
                      value: "informational",
                      label: "Информационный"
                    }),
                    _createVNode(_component_el_option, {
                      value: "transactional",
                      label: "Транзакционный"
                    }),
                    _createVNode(_component_el_option, {
                      label: "Рекламный с шаблоном",
                      value: "templated_add"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "Подпись" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.addForm.sender,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.addForm.sender) = $event)),
                  filterable: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.senders, (s) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        value: s.name,
                        label: s.name
                      }, null, 8, ["value", "label"]))
                    }), 256))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_form_item, { label: "Шаблон (добавьте несколько разделяя одним или более символом новой строки)" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  type: "textarea",
                  rows: 5,
                  modelValue: _ctx.addForm.texts,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.addForm.texts) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              type: "success",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.saveNewTemplates())),
              loading: _ctx.saving,
              disabled: !_ctx.addForm.texts || !_ctx.addForm.sender || !_ctx.addForm.trafficType || !_ctx.addForm.operatorGroup
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.addForm.id ? 'Сохранить' : 'Добавить'), 1)
              ]),
              _: 1
            }, 8, ["loading", "disabled"])
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["title", "modelValue"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_button, {
        size: "small",
        type: "success",
        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showAddForm()))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Добавить шаблоны")
        ]),
        _: 1
      }),
      _withDirectives(_createVNode(_component_remove_button, {
        onConfirm: _cache[8] || (_cache[8] = ($event: any) => (_ctx.removeSelected())),
        loading: _ctx.removing
      }, {
        default: _withCtx(() => [
          _createTextVNode("Удалить выбранные шаблоны")
        ]),
        _: 1
      }, 8, ["loading"]), [
        [_vShow, _ctx.selection.length]
      ]),
      _createElementVNode("span", null, [
        _createVNode(_component_el_switch, {
          modelValue: _ctx.showTuplesOnly,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.showTuplesOnly) = $event))
        }, null, 8, ["modelValue"]),
        _createTextVNode(" Показывать только дубли")
      ])
    ]),
    _createVNode(_component_service_templates_table, {
      ref: "mTable",
      templates: _ctx.visibleTemplates,
      loading: _ctx.templatesLoading,
      small: true,
      onFiltered: _ctx.recalculateCumsum
    }, {
      "admin-select": _withCtx(() => [
        _createVNode(_component_el_table_column, {
          label: "",
          width: "30"
        }, {
          header: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.selectAll) = $event)),
              value: true
            }, null, 512), [
              [_vModelCheckbox, _ctx.selectAll]
            ])
          ]),
          default: _withCtx((scope) => [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.selection) = $event)),
              value: scope.row.id
            }, null, 8, _hoisted_2), [
              [_vModelCheckbox, _ctx.selection]
            ])
          ]),
          _: 1
        })
      ]),
      "admin-actions": _withCtx(() => [
        _createVNode(_component_el_table_column, {
          "min-width": "120",
          label: "Счетчик"
        }, {
          header: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "success",
              size: "small",
              onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.showAllCountersCumsum()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Счётчик:  " + _toDisplayString(_ctx.allCountersCumsum), 1)
              ]),
              _: 1
            })
          ]),
          default: _withCtx((scope) => [
            _createVNode(_component_el_button, {
              type: "success",
              size: "small",
              onClick: ($event: any) => (_ctx.showAllCounters(scope.row)),
              disabled: !scope.row.counters.length
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.lastMonthCounter(scope.row)), 1)
              ]),
              _: 2
            }, 1032, ["onClick", "disabled"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "",
          width: "100"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("button", {
              onClick: ($event: any) => (_ctx.editRow(scope.row))
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "edit" }),
              _createTextVNode(" Ред. ")
            ], 8, _hoisted_3)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["templates", "loading", "onFiltered"])
  ], 64))
}