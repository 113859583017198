<template>
  <div>
    <el-form label-position="top" v-if="inline" :size="small ? 'small' : 'default'">
      <el-row :gutter="20">

        <el-col :xs="24" :md="6">
          <el-form-item label="Дата" v-show="shortDate" :style="`${fullWidthSelect ? 'width:100%;' : ''}%`"
                        :size="small ? 'small' : 'default'">
            <el-select v-model="shortDateValue" :style="`${fullWidthSelect ? 'width:100%;' : ''}%`">
              <el-option label="---" value=""></el-option>
              <el-option label="Сегодня" value="0"></el-option>
              <el-option label="Вчера" value="1"></el-option>
              <el-option label="Позавчера" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="6">
          <el-form-item label="Месяц" v-show="month" :style="`${fullWidthSelect ? 'width:100%;' : ''}%`"
                        :size="small ? 'small' : 'default'">
            <el-select v-model="monthValue" :style="`${fullWidthSelect ? 'width:100%;' : ''}%`">
              <el-option label="---" value=""></el-option>
              <el-option
                  v-for="month in static.months"
                  :key="month.value"
                  :label="month.label"
                  :value="month.value"
              />

            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="6" v-show="freeDate || freeDateTime">
          <el-form-item label=" ">
            <el-date-picker
                v-model="freeDateTimeValueStart"
                type="datetime"
                format="DD.MM.YYYY HH:mm"
                placeholder="Начало"
                :default-time="static.defaultTime[0]"
                style="min-width: 200px; flex-grow: 1;"
                :size="small ? 'small' : 'default'"
                clearable
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="6" v-show="freeDate || freeDateTime">
          <el-form-item label=" ">
            <el-date-picker
                v-model="freeDateTimeValueEnd"
                type="datetime"
                format="DD.MM.YYYY HH:mm"
                placeholder="Конец"
                :default-time="static.defaultTime[1]"
                style="min-width: 200px; flex-grow: 1"
                clearable
                :size="small ? 'small' : 'default'"

            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-row v-if="!inline">
      <el-col :xs="24">
        <el-form label-position="top">
          <el-form-item label="Дата" v-show="shortDate" :style="`${fullWidthSelect ? 'width:100%;' : ''}%`"
                        :size="small ? 'small' : 'default'">
            <el-select v-model="shortDateValue" :style="`${fullWidthSelect ? 'width:100%;' : ''}%`">
              <el-option label="---" value=""></el-option>
              <el-option label="Сегодня" value="0"></el-option>
              <el-option label="Вчера" value="1"></el-option>
              <el-option label="Позавчера" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Месяц" v-show="month" :style="`${fullWidthSelect ? 'width:100%;' : ''}%`"
                        :size="small ? 'small' : 'default'">
            <el-select v-model="monthValue" :style="`${fullWidthSelect ? 'width:100%;' : ''}%`">
              <el-option label="---" value=""></el-option>
              <el-option
                  v-for="month in static.months"
                  :key="month.value"
                  :label="month.label"
                  :value="month.value"
              />

            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row v-show="freeDate || freeDateTime" :gutter="40"  v-if="!inline">
      <el-col :span="24">
        <el-form label-position="top">
          <el-form-item :label="freeDateLabel || 'Период'" class="full-width" :size="small ? 'small' : 'default'">
            <div
                style="width: 100%; display: flex; justify-content: space-around; align-items: center; flex-wrap: wrap; gap:20px; ">
              <el-date-picker
                  v-model="freeDateTimeValueStart"
                  type="datetime"
                  format="DD.MM.YYYY HH:mm"
                  placeholder="Начало"
                  :default-time="static.defaultTime[0]"
                  style="min-width: 200px; flex-grow: 1;"
                  :size="small ? 'small' : 'default'"
                  clearable
              >
              </el-date-picker>

              <el-date-picker
                  v-model="freeDateTimeValueEnd"
                  type="datetime"
                  format="DD.MM.YYYY HH:mm"
                  placeholder="Конец"
                  :default-time="static.defaultTime[1]"
                  style="min-width: 200px; flex-grow: 1"
                  clearable
                  :size="small ? 'small' : 'default'"

              >
              </el-date-picker>
            </div>

          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

  </div>
</template>

<script>

import moment from "moment"

export default {
  name: "TimeSearch",
  props: ["shortDate", "month", "year", "freeDate", "freeDateTime", "showLastMonth", "fullWidthSelect", "freeDateLabel", 'modelValue', 'small', 'inline'],
  emits: ['update:modelValue'],
  methods: {
    getDateTimeFilter() {
      if (this.activeFilter === "shortDate") {
        // let now = moment()
        // if(this.shortDateValue === "0") return {
        //   from: moment().startOf("day"),
        //   to: moment().endOf("day")
        // }
        return {
          from: moment().subtract(Number(this.shortDateValue), "days").startOf("day"),
          to: moment().subtract(Number(this.shortDateValue), "days").endOf("day")
        }
      }
      if (this.activeFilter === "month") {
        return {
          from: moment(this.monthValue, "DD.MM.YYYY").startOf("month"),
          to: moment(this.monthValue, "DD.MM.YYYY").endOf("month")
        }
      }
      if (this.activeFilter === "freeDateTime") {
        let from = ""
        let to = ""
        if (this.freeDateTimeValueStart) {
          from = moment(this.freeDateTimeValueStart)
          if (this.freeDate) from = from.startOf("day")
        }
        if (this.freeDateTimeValueEnd) {
          to = moment(this.freeDateTimeValueEnd)
          if (this.freeDate) to = to.startOf("day")
        } else {
          to = moment()
        }
        return {from, to}
      }
      return {
        from: "",
        to: ""
      }
    },
    handlePickerClose(visible) {
      console.log(visible)
      if (!visible) {
        console.log(this.freeDateTimeValue)
      }
    },
    reset() {
      this.freeDateTimeValueStart = ""
      this.freeDateTimeValueEnd = ""
      this.activeFilter = ""
    }
  },
  watch: {
    shortDateValue(newVal) {
      if (!newVal) return
      this.activeFilter = "shortDate"
      this.monthValue = ""
      this.freeDateTimeValue = ""
      this.freeDateTimeValueStart = ""
      this.freeDateTimeValueEnd = ""
      this.$emit("update:modelValue", this.getDateTimeFilter())
    },
    monthValue(newVal) {
      if (!newVal) return
      this.activeFilter = "month"
      this.shortDateValue = ""
      this.freeDateTimeValue = ""
      this.freeDateTimeValueStart = ""
      this.freeDateTimeValueEnd = ""
      this.$emit("update:modelValue", this.getDateTimeFilter())
    },
    freeDateTimeValueStart(newVal) {
      if (!newVal) {
        this.$emit("update:modelValue", this.getDateTimeFilter())
        return
      }
      this.activeFilter = "freeDateTime"
      this.shortDateValue = ""
      this.monthValue = ""
      this.$emit("update:modelValue", this.getDateTimeFilter())
    },
    freeDateTimeValueEnd(newVal) {
      if (!newVal) {
        this.$emit("update:modelValue", this.getDateTimeFilter())
        return
      }
      this.activeFilter = "freeDateTime"
      this.shortDateValue = ""
      this.monthValue = ""
      this.$emit("update:modelValue", this.getDateTimeFilter())
    }
  },
  mounted() {
    let now = moment()
    let months = []
    for (let i = 1; i <= 24; i++) {
      months.push({label: now.locale("ru").format("YYYY MMMM"), value: now.startOf("month").format("DD.MM.YYYY")})
      now = moment().subtract(i, "months")
    }
    this.static.months = months
    if (this.showLastMonth) this.monthValue = months[0].value
    if (!this.shortDate && !this.showLastMonth) this.activeFilter = ""
  },
  data() {
    return {
      shortDateValue: "0",
      monthValue: "",
      freeDateTimeValue: "",
      freeDateTimeValueStart: "",
      freeDateTimeValueEnd: "",
      activeFilter: "shortDate",
      static: {
        months: [],
        defaultTime: [moment().hour(0).minute(0).toDate(), moment().hour(23).minute(59).toDate()]
      }
    }
  },
}

</script>