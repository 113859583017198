<template>
  <el-dropdown trigger="click" :style="style">
    <el-button text size="small" class=" table-text-button" plain>
      <font-awesome-icon icon="chevron-down"></font-awesome-icon>&nbsp;{{ title }}
    </el-button>
    <template #dropdown>
      <el-dropdown-menu  class="actions-dropdown" >
        <slot />
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>

export default {
  name: "ActionsDropdown",
  props: {
    title: {
      type: String,
      default: "Действия"
    },
    style: {
      type: String,
      default: ""
    }
  },
  methods: {

  },
  data(){
    return {

    }
  },
}

</script>