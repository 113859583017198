export enum RoutingTypesTS {
    operator = "Опер.",
    operatorGroup = "Груп.",
    country = "Стра.",
    zone = "Зона"
}
export enum RoutingTypesT {
    operator = "Оператор",
    operatorGroup = "Группа операторов",
    country = "Страна",
    zone = "Зона"
}

export enum RoutingTypes {
    Operator = "operator",
    OperatorGroup = "operatorGroup",
    Country = "country"
}

export const RatingTypes = {
    ...RoutingTypes,
    Zone: "zone"
}