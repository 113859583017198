import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_TableSpinner = _resolveComponent("TableSpinner")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { gutter: 20 }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { span: 24 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_card, { class: "box-card no-top-body-padding no-border" }, {
            default: _withCtx(() => [
              _createVNode(_component_TableSpinner, { loading: _ctx.loading }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form, { "label-position": "top" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, { label: "Список е-мейл адресов для отчёта по блокировке/мониторингу" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: _ctx.settings.monitoringEmails,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.settings.monitoringEmails) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_form_item, { label: "Порог сообщений для уведомления по модерации" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: _ctx.settings.moderationNotificationThreshold,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.settings.moderationNotificationThreshold) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_form_item, { label: "Список е-мейл адресов для уведомления по модерации" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: _ctx.settings.moderationEmails,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.settings.moderationEmails) = $event))
                          }, null, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_button, {
                    type: "success",
                    onClick: _ctx.saveSettings
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Сохранить")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }, 8, ["loading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}