<template>
  <el-card class="box-card">
    <!--    <h3>Отправить уведомление</h3>-->
    <el-row :gutter="30">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <el-form label-position="top">
<!--          <el-form-item label="Событие">-->
<!--            <el-select v-model="notice.event">-->
<!--              <el-option label="-&#45;&#45;" value=""/>-->
<!--              <el-option label="Проблема" value="problem"/>-->
<!--              <el-option label="Восстановление" value="recovery"/>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
          <el-form-item label="">
            <el-switch
                v-model="notice.operatorType"
                style="--el-switch-on-color: #13ce66; --el-switch-off-color: var(--el-color-primary);"
                active-value="group"
                inactive-value="operator"
                active-text="Группа"
                inactive-text="Оператор"
            />
          </el-form-item>
          <el-form-item label="">
            <el-select
                filterable
                v-model="notice.operatorId"
                v-show="notice.operatorType === 'group'"
            >
              <el-option
                  v-for="o in $store.getters.operatorsGroups"
                  :value="o._id"
                  :label="o.title"
              />
            </el-select>
            <el-select
                v-show="notice.operatorType === 'operator'"
                filterable
                v-model="notice.operatorId"
            >
              <el-option
                  v-for="o in $store.getters.operators"
                  :value="o._id"
                  :label="o.OrgName"
              />
            </el-select>

          </el-form-item>

          <el-form-item label="Текст">
            <el-input type="textarea" :rows="6" v-model="notice.text"/>
          </el-form-item>

        </el-form>
<!--        <el-divider/>-->
        <pre style="background: #EEE; padding: 10px">{{ finalRequestText }}</pre>
<!--        <el-divider/>-->
        <div style="display: flex; justify-content: space-between">
          <el-button @click="testRequest" :loading="requestSending" :disabled="!finalRequestText">Протестировать</el-button>
          <el-button type="success" @click="sendRequest" :loading="requestSending" :disabled="!finalRequestText">Отправить</el-button>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <el-divider class="hidden-md-and-up"/>
        <p>Для подстановки названия оператора/группы используйте текстовую переменную <b>&#123;&#123;OPERATOR&#125;&#125;</b></p>
        <TableSpinner :loading="loading">
          <div style="margin-bottom: 20px" v-for="(t, k) in templates">
            <el-input type="textarea" :rows="5" class="full-width" v-model="templates[k].text"/>
            <div style="display: flex; justify-content: space-between; margin-top: 20px">
              <el-button @click="notice.text = t.text">Выбрать</el-button>
              <el-button type="success" @click="saveTemplate(t)" :disabled="!templates[k].text.trim()" :loading="templateSaving">Сохранить</el-button>
            </div>
            <el-divider />
          </div>
        </TableSpinner>
      </el-col>
    </el-row>
  </el-card>

</template>
<script>


import ClientSelect from "@/components/layout/client-select.component.vue";

export default {
  name: "ClientNotifications",
  title: "Уведомления клиентам",
  components: {ClientSelect},
  watch: {
    "notice.operatorType": function () {
      this.notice.operatorId = ""
    }
  },
  methods: {

    refresh() {
      this.loading = true
      this.axios.get("/admin/support/notificationTemplates")
          .then(resp => {
            this.templates = resp.data
            this.loading = false
          })
    },
    sendRequest() {
      this.requestSending = true
      this.axios.post("/admin/support/sendClientNotification", {text: this.finalRequestText})
          .then(resp => {
            this.$gNotify("Уведомление отправлено", "success")
            this.requestSending = false
          })
          .catch(e => {
            this.$gNotify("Ошибка отправки", "error")
            this.requestSending = false
          })
    },
    testRequest() {
      this.requestSending = true
      this.axios.post("/admin/support/testClientNotification", {text: this.finalRequestText})
          .then(resp => {
            this.$gNotify("Тестовое уведомление отправлено", "success")
            this.requestSending = false
          })
          .catch(e => {
            this.$gNotify("Ошибка отправки", "error")
            this.requestSending = false
          })
    },
    saveTemplate(t){
      this.templateSaving = true
      this.axios.post("/admin/support/saveClientNotificationTemplate", t)
          .then(resp => {
            this.$gNotify("Шаблон сохранён", "success")
            this.templateSaving = false
          })
          .catch(e => {
            this.$gNotify("Ошибка сохранения", "error")
            this.templateSaving = false
          })
    }
  },
  computed: {
    finalRequestText() {
      let substValue = this.notice.operatorId ?
          this.notice.operatorType === "operator" ? (this.$store.getters.operators || []).find(o => o._id === this.notice.operatorId).OrgName :
              (this.$store.getters.operatorsGroups || []).find(o => o._id === this.notice.operatorId).title
          : ""
      return substValue ? this.notice.text.replace(/{{OPERATOR}}/g, substValue) : this.notice.text
    },
    canSend() {
      return this.notice.event && this.notice.operatorId && this.finalRequestText.trim()
    }
  },
  mounted() {
    this.refresh()
  },
  data() {
    return {
      notice: {
        operatorType: "",
        operatorId: "",
        text: ""
      },
      visiblePanel: "newRequest",
      loading: false,
      templates: [],
      requestSending: false,

      templateSaving: false
    }
  }
}

</script>
