<template>
  <el-card>
    <el-form label-position="top" :model="filter" style="width: 100%; margin-bottom: 20px" size="small">
      <el-row :gutter="20">
        <el-col :xs="24" :md="12">
          <TimeSearch
              :full-width-select="true"
              :free-date-time="true"
              :month="true"
              :short-date="true"
              :small="true"
              ref="timeSearch"
          />
        </el-col>
        <el-col :xs="24" :md="12">
          <el-form-item label="Клиент">
            <client-select :with-all="true" :multiple="false" v-model="filter.user"/>
          </el-form-item>

          <el-form-item label="Свободный поиск">
            <el-input v-model="filter.token"/>
          </el-form-item>
          <el-button type="success" @click="getReport" style="margin-top: 20px" :loading="reportLoading">Найти</el-button>
        </el-col>

      </el-row>

    </el-form>
    <TableSpinner :loading="reportLoading">

      <el-table :data="emails" style="width: 100%">
        <el-table-column :formatter="r => formatDateTime(r.date)" label="Время" width="100"/>
        <el-table-column :formatter="emailStatus" label="Статус" width="120"/>
        <el-table-column label="Кому" width="200">
          <template #default="scope">
<!--            <span class="bold">{{ scope.row.user_id }}</span>-->
            <span>{{ scope.row.email_to instanceof Array ? scope.row.email_to.join(',') : scope.row.email_to }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="subject" label="Тема" width="200"/>
        <el-table-column label="Текст" min-width="300">
          <template #default="scope">
            <span v-html="scope.row.text"
                  style="display:block;max-height: 300px; overflow-y: scroll; max-width: 500px; overflow-x: scroll"></span>
          </template>
        </el-table-column>
        <el-table-column label="Вложения">
          <template #default="scope">
            <span v-for="a in scope.row.attachments || []">{{ a.name }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="solo-pagination-container">
        <el-pagination
            background
            layout="sizes, prev, pager, next"
            :total="totalRows"
            class="wide-page-size"

            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :pager-count="3"
            :hide-on-single-page="true"
            @size-change="handlePageSizeChange"
            @current-change="handleCurrentPageChange"
        >
        </el-pagination>
      </div>
    </TableSpinner>
  </el-card>
</template>

<script>


import CommonTable from "@shared/components/reports/common-sent-messages-table.component.vue"
import DetailTable from "@shared/components/reports/detail-sent-messages-table.component.vue"
import SentMessagesStatistics from "@shared/components/reports/statistics.component.vue";
import ClientSelect from "../../components/layout/client-select.component.vue";
import TimeSearch from "@shared/components/reports/time-search.component.vue";

export default {
  name: "SentEmails",
  title: "Отправленные е-мейлы",
  components: {
    TimeSearch,
    ClientSelect,

  },
  watch: {},
  mounted() {},
  computed: {},
  methods: {
    handlePageSizeChange(newSize) {
      this.pageSize = newSize
      this.getReport(false)
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage
      this.getReport(false)
    },
    __getReportParams() {
      let dateFilter = this.$refs.timeSearch.getDateTimeFilter()
      return {
        date_from: dateFilter.from.format("DD.MM.YYYY HH:mm"),
        date_to: dateFilter.to.format("DD.MM.YYYY HH:mm"),
        pageSize: this.pageSize,
        currentPage: this.currentPage - 1,
        token: this.filter.token,
        user: this.filter.user
      }
    },
    getReport(doCount = false) {
      this.reportLoading = true
      let params = this.__getReportParams()
      this.axios.post("/admin/support/getSentEmailsReport", {params})
          .then(resp => {

            this.emails = resp.data.records
            this.totalRows = resp.data.count
            this.$nextTick(() => {
              this.reportLoading = false
            })
          })
    },
    emailStatus(r){
      if(r.sendingError) return "Ошибка"
      if(r.read) return "Прочитано"
      return "Отправлено"
    }
  },
  data() {
    return {
      filter: {
        date: {
          from: "",
          to: ""
        },
        token: "",
        user: ""
      },
      emails: [],
      currentPage: 1,
      pageSize: 50,
      reportLoading: false,
      totalRows: 0
    }
  }
}

</script>