<template>
  <div>
    <el-row :gutter="40">
      <el-col :md="12" :xs="24">
        <TimeSearch
            :full-width-select="true"
            :free-date-time="true"
            :month="true"
            :short-date="true"
            ref="timeSearch"
        />

      </el-col>
      <slot name="admin-settings" :onUsersChange="setUsersToFilter"
            :onSenderTypeChange="val => extraSearchParams.senderType = val"></slot>
    </el-row>
    <el-button type="success" plain @click="getReport" :loading="reportLoading"
               style="margin-top: 20px; margin-bottom: 60px">Применить
    </el-button>
    <el-row v-show="reportReady">
      <el-col :span="24">
        <el-tabs v-model="visiblePanel1" class="inside-tabs">
          <el-tab-pane label="Общий" name="common">
            <el-table
                :data="commonReportData"
                border style="width: 100%"
                :summary-method="getCommonTableSummary"
                show-summary
                :span-method="commonReportSpanMethod"
                stripe
            >
              <el-table-column prop="status" label="Статус" min-width="200"/>
              <el-table-column prop="channel" label="Тип сообщения" min-width="150"/>
              <el-table-column prop="targets" label="Адресатов" min-width="150"/>
              <el-table-column prop="parts" label="Сообщений" min-width="150"/>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="По операторам" name="by_operator">
            <div class="solo-pagination-container" style="margin-top: 0px">
              <el-radio-group v-model="operatorReportGroupingType">
                <el-radio :label="'operator'">По операторам</el-radio>
                <el-radio :label="'operatorGroup'">По группам операторов</el-radio>
              </el-radio-group>
              <h4
                  style="color: var(--el-color-danger);cursor: pointer;text-decoration: underline; margin-top:0px;">
                В данной вкладке представлена информация только для СМС
              </h4>

            </div>
            <el-table
                :data="operatorReportData"
                border style="width: 100%"
                :summary-method="getOperatorTableSummary"
                show-summary
                stripe
            >
              <el-table-column prop="country" label="Страна" width="170"/>
              <el-table-column prop="operator" label="Оператор" width="200"
                               v-if="operatorReportGroupingType === 'operator'"/>
              <el-table-column prop="operatorGroup" label="Группа операторов" width="200"
                               v-if="operatorReportGroupingType === 'operatorGroup'"/>
              <el-table-column prop="mcc" label="MCC" min-width="70"/>
              <el-table-column prop="mnc" label="MNC" min-width="70"/>
              <el-table-column prop="sent" label="В процессе доставки" min-width="110"/>
              <el-table-column prop="delivered" label="Доставлено" min-width="110"/>
              <el-table-column prop="not_delivered" label="Не доставлено" min-width="110"/>
              <el-table-column prop="expired" label="Просрочено" min-width="100"/>
              <!--              <el-table-column prop="error" label="Ошибки" min-width="100" />-->
              <el-table-column prop="delivery_percent" label="% доставки" min-width="100"/>
              <el-table-column prop="sent_total" label="Всего" min-width="110"/>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="По странам" name="by_country">
            <h4
                style="color: var(--el-color-danger);cursor: pointer;text-decoration: underline; margin-top:0px;">
              В данной вкладке представлена информация только для СМС
            </h4>
            <el-table
                :data="countryReportData"
                border style="width: 100%"
                :summary-method="getCountryTableSummary"
                show-summary
                stripe
            >
              <el-table-column prop="country" label="Страна" width="170"/>
              <el-table-column prop="mcc" label="MCC" width="90"/>
              <el-table-column prop="sent" label="В процессе доставки" min-width="110"/>
              <el-table-column prop="delivered" label="Доставлено" min-width="110"/>
              <el-table-column prop="not_delivered" label="Не доставлено" min-width="110"/>
              <el-table-column prop="expired" label="Просрочено" min-width="110"/>
              <!--              <el-table-column prop="error" label="Ошибки" min-width="110" />-->
              <el-table-column prop="delivery_percent" label="% доставки" min-width="100"/>
              <el-table-column prop="sent_total" label="Всего" min-width="110"/>
            </el-table>
          </el-tab-pane>

        </el-tabs>
      </el-col>
    </el-row>

  </div>
</template>

<script>

import TimeSearch from "@shared/components/reports/time-search.component"


const STATUSES_MAP = {
  "2": 1,
  "6": 1,
  "5": 2,
  "7": 2,
  "8": 2,
  "3": 3,
}

const STATUSES_VERBOSE = ["В процессе доставки", "Доставлено", "Не доставлено", "Просрочено"] //, "Ошибки"

export default {
  name: "CommonTable",
  components: {
    TimeSearch
  },
  mounted() {
  },
  computed: {
    commonReportData() {
      // return Object.values(
      //     this.reportData.reduce((acc, r) => {
      //       let status = r._id.platform_status !== 10 ? "error" : STATUSES_MAP[String(r._id.status)] || 'in_transit'
      //       let key = `${status}_${r._id.channel}`
      //       if(!acc[key]) acc[key] = {targets: 0, parts: 0}
      //       retval[key].targets += r.count
      //       retval[key].parts += r.msg_count
      //     }, {})
      // )
      let retval = STATUSES_VERBOSE.map((s, i) => {
        return ["sms", "vk", "telegram"].map(c => {
          return {status: s, channel: CHANNEL_NAMES[c], targets: 0, parts: 0, i, c}
        })
      }).flat()

      // let retval = [
      //   {status: "В процессе доставки", targets: 0, parts: 0},
      //   {status: "Доставлено", targets: 0, parts: 0},
      //   {status: "Не доставлено", targets: 0, parts: 0},
      //   {status: "Просрочено", targets: 0, parts: 0},
      //   {status: "Ошибки", targets: 0, parts: 0},
      // ]
      for (let row of this.reportData) {
        let statusIndex = STATUSES_MAP[String(row._id.status)] || 0
        let index = retval.findIndex(r => r.i === statusIndex && r.c === row._id.channel)
        if (index < 0) return
        // if([2, 6].includes(row._id.status)) index = 1
        // if([5,7, 8].includes(row._id.status)) index = 2
        // if([3].includes(row._id.status)) index = 3
        // if(row._id.platform_status !== 10) index = 4
        retval[index].targets += row.count
        retval[index].parts += row.msg_count
      }
      return retval
    },
    operatorReportData() {
      let retval = {}
      let countries = this.$store.getters.countries
      for (let row of this.reportData) {
        if (row._id.channel !== "sms") continue
        let k = this.operatorReportGroupingType === "operator" ? row._id.operator_name : row._id.operator_group
        if (!retval[k]) retval[k] = {
          country: (countries.find(c => String(c.country_code) === String(row._id.mcc)) || {country_name: ""}).country_name,
          operator: row._id.operator_name === "undetected" || !row._id.operator_name ? "Не определён" : row._id.operator_name,
          operatorGroup: row._id.operator_group === "undetected" || !row._id.operator_group ? "Не определена" : (this.$store.getters.operatorsGroups.find(g => g._id === row._id.operator_group) || {title: ""}).title,
          mcc: row._id.mcc,
          mnc: row._id.mnc,
          sent_total: 0,
          sent: 0,
          delivered: 0,
          not_delivered: 0,
          expired: 0,
          // error: 0,
          delivery_percent: 0,
          __total_count: 0
        }
        retval[k].sent_total += row.msg_count
        // if(row._id.platform_status !== 10) retval[k].error += row.msg_count else
        if ([2, 6].includes(row._id.status)) retval[k].delivered += row.msg_count
        else if ([5, 7, 8].includes(row._id.status)) retval[k].not_delivered += row.msg_count
        else if ([3].includes(row._id.status)) retval[k].expired += row.msg_count
        else retval[k].sent += row.msg_count

        retval[k].delivery_percent = this.formatNumber((retval[k].delivered / retval[k].sent_total) * 100)
      }
      return Object.values(retval)
    },
    countryReportData() {
      let retval = {}
      let countries = this.$store.getters.countries
      for (let row of this.reportData) {
        if (row._id.channel !== "sms") continue
        let k = row._id.mcc
        if (!retval[k]) retval[k] = {
          country: (countries.find(c => String(c.country_code) === String(row._id.mcc)) || {country_name: ""}).country_name,
          mcc: row._id.mcc,
          sent_total: 0,
          sent: 0,
          delivered: 0,
          not_delivered: 0,
          expired: 0,
          // error: 0,
          delivery_percent: 0,
          __total_count: 0
        }
        retval[k].sent_total += row.msg_count
        // if(row._id.platform_status !== 10){
        //   retval[k].error += row.msg_count
        // } else
        if ([2, 6].includes(row._id.status))
          retval[k].delivered += row.msg_count
        else if ([5, 8, 7].includes(row._id.status))
          retval[k].not_delivered += row.msg_count

        else if ([3].includes(row._id.status))
          retval[k].expired += row.msg_count
        else
          retval[k].sent += row.msg_count
        retval[k].delivery_percent = this.formatNumber((retval[k].delivered / retval[k].sent_total) * 100)
      }
      return Object.values(retval)
    }
  },
  methods: {
    getReport() {
      this.reportLoading = true
      this.reportReady = false
      let dateFilter = this.$refs.timeSearch.getDateTimeFilter()
      this.axios.post("/reports/getSentMessagesCommonReport", this.extraSearchParams, {
        params: {
          date_from: dateFilter.from.format("DD.MM.YYYY HH:mm"),
          date_to: dateFilter.to.format("DD.MM.YYYY HH:mm"),
        }
      })
          .then(resp => {
            this.reportLoading = false
            this.reportReady = true
            this.reportData = resp.data.report
          })
    },
    getCommonTableSummary({columns, data}) {
      return [
        "Итого обработано",
        "",
        data.reduce((acc, r) => acc + r.targets, 0),
        data.reduce((acc, r) => acc + r.parts, 0)
      ]
    },
    getOperatorTableSummary({data}) {
      return [
        "Всего", "", "", "",

        data.reduce((acc, r) => acc + r.sent, 0),
        data.reduce((acc, r) => acc + r.delivered, 0),
        data.reduce((acc, r) => acc + r.not_delivered, 0),
        data.reduce((acc, r) => acc + r.expired, 0),
        // data.reduce((acc, r) => acc + r.error, 0),
        this.formatNumber(data.reduce((acc, r) => acc + Number(r.delivery_percent) || 0, 0) / Math.max(data.length, 1)),
        data.reduce((acc, r) => acc + r.sent_total, 0)
      ]
    },
    getCountryTableSummary({data}) {
      return [
        "Всего", "",

        data.reduce((acc, r) => acc + r.sent, 0),
        data.reduce((acc, r) => acc + r.delivered, 0),
        data.reduce((acc, r) => acc + r.not_delivered, 0),
        data.reduce((acc, r) => acc + r.expired, 0),
        // data.reduce((acc, r) => acc + r.error, 0),
        this.formatNumber(data.reduce((acc, r) => acc + Number(r.delivery_percent) || 0, 0) / Math.max(data.length, 1)),
        data.reduce((acc, r) => acc + r.sent_total, 0),
      ]
    },

    setUsersToFilter(users) {
      this.extraSearchParams.users = users
    },
    resetAdminFilter() {
      this.extraSearchParams.users = []
      this.extraSearchParams.senderType = ""
    },
    commonReportSpanMethod({row, column, columnIndex, rowIndex}) {
      if (columnIndex === 0) {
        if (rowIndex % 3 === 0) return [3, 1]
        else return [0, 0]
      }

    }
  },
  data() {
    return {
      reportReady: false,
      reportLoading: false,
      visiblePanel1: "common",
      extraSearchParams: {users: [], senderType: ""},
      reportData: [],
      operatorReportGroupingType: "operator"
    }
  }
}

</script>