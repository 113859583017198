class WsManager{
  constructor(){
    this.socket = null
    this.reconnectTimer = null
    this.store = null
    this.app = null
  }


  linkStore(store){
    this.store = store
  }

  linkEventBus(bus){
    this.eventBus = bus
  }

  reconnect(){
    this.reconnectTimer = setTimeout(() => {
      console.log("Reconnect attempt")
      this.connect()
    }, 2000)
  }

  connect(){
    if(!this.store.getters.bareToken) return
    this.socket = new WebSocket((process.env.VUE_APP_WS_HOST || "ws://localhost:3033") + "?token=" + this.store.getters.bareToken);
    this.socket.onopen = e => {
      console.log("WSS opened")
    };

    this.socket.onmessage = event => {
      try {
        this.processMessage(JSON.parse(event.data))
      }catch (e){
        console.log(e)
        console.log(event)
      }
    };

    this.socket.onclose = event => {
      if (!event.wasClean) {
        this.reconnect()
      }
    };

    this.socket.onerror = error => {
      console.error(`WSS error`)
      console.error(error)
    };
  }

  disconnect(){
    if(this.socket) this.socket.close()
    this.socket = null
    this.eventBus.all.clear()
  }

  processMessage(event){
    // console.log(event)
    if(event.message === "balance"){
      this.store.dispatch("setUserBalance", {balance: event.balance, overdraft: event.overdraft})
    }
    if(event.message === "baseLoadProgress"){
      this.eventBus.emit("baseLoadProgressGlobal", JSON.stringify(event))
      this.eventBus.emit("baseLoadProgress", JSON.stringify(event))
    }
    if(event.message === "unpaidMessagesAppeared"){
      this.eventBus.emit("unpaidMessagesAppeared", JSON.stringify(event))
    }

    if(event.message === "dialogMessage"){
      this.eventBus.emit("dialogMessage", JSON.stringify(event))
    }
    if(event.message === "supportMessage"){
      this.eventBus.emit("supportMessage", JSON.stringify(event))
    }
    if(event.message === "actsGenerated"){
      this.eventBus.emit("actsGenerated", JSON.stringify(event))
    }
    if(event.message === "requestTgPersonalPassword"){
      this.eventBus.emit("requestTgPersonalPassword", JSON.stringify(event))
    }
    if(event.message === "tgPersonalChannelCreated"){
      this.eventBus.emit("tgPersonalChannelCreated", JSON.stringify(event))
    }
    if(event.message === "whatsappPersonalChannelCreated"){
      this.eventBus.emit("whatsappPersonalChannelCreated", JSON.stringify(event))
    }
    if(event.message === "tgPersonalChannelQrCode"){
      this.eventBus.emit("tgPersonalChannelQrCode", JSON.stringify(event))
    }
    if(event.message === "duplicatedTgChannel"){
      this.eventBus.emit("duplicatedTgChannel", JSON.stringify(event))
    }
    if(event.message === "whatsappPersonalChannelQrCode"){
      this.eventBus.emit("whatsappPersonalChannelQrCode", JSON.stringify(event))
    }
    if(event.message === "taskCreated"){
      this.eventBus.emit("taskCreated", JSON.stringify(event))
    }
    if(event.message === "unpaidGroupsAppeared"){
      this.eventBus.emit("unpaidGroupsAppeared", event)
    }
    if(event.message === "unpaidGroupsCleared"){
      this.eventBus.emit("unpaidGroupsCleared", null)
    }
  }
}

module.exports = new WsManager()