<template>

  <el-row justify="center">
    <el-col :lg="7" :xl="5" :md="10" :sm="12" :xs="20">
      <el-card style="min-width: 300px; max-width:400px;text-align: center">
        <h3 class="uppercase align-center no-margin-top">Восстановление пароля</h3>
        <el-alert v-show="alertVisible" style="margin-bottom: 20px"
                  title="На вашу почту было отравлено письмо с инструкциями по смене пароля" type="success"/>
        <el-form ref="form" :model="requestForm" label-position="top" class="align-center" v-show="!$route.query.token"
                 data-test="resetPassForm">
          <el-form-item label="Email вашего аккаунта">
            <el-input v-model="requestForm.email"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="requestReset" class="uppercase full-width" size="medium"
                       :loading="loading">Продолжить
            </el-button>
          </el-form-item>
        </el-form>

        <el-form ref="resetForm" :model="resetForm" :rules="rules" label-position="top" class="align-center"
                 v-show="$route.query.token">
          <el-form-item label="Новый пароль" prop="password">
            <el-input v-model="resetForm.password" type="password"></el-input>
          </el-form-item>
          <el-form-item label="Подтвердите пароль" prop="passwordConfirm">
            <el-input v-model="resetForm.passwordConfirm" type="password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="confirmReset" class="uppercase full-width" size="medium"
                       :loading="loading">Продолжить
            </el-button>
          </el-form-item>

        </el-form>
        <el-button text @click="$router.push({path : '/login'})" data-test="passResetButton" style="margin-top: 20px">Назад к логину
        </el-button>
      </el-card>
    </el-col>

  </el-row>

</template>

<script>

export default {
  name: "ResetPassword",
  title: "Сброс пароля",
  methods: {
    requestReset: function () {
      this.loading = true
      this.axios.post('/auth/passResetRequest', this.requestForm)
          .then(() => {
            this.alertVisible = true
            this.loading = false
          })
          .catch(console.error)
    },
    confirmReset: function () {
      this.$refs.resetForm.validate()
          .then(v => {
            console.log(v)
            if (v) {
              this.loading = true
              this.axios.post('/auth/passResetConfirm', {...this.resetForm, token: this.$route.query.token})
                  .then(() => {
                    this.$router.push({path: "/login", query: {successReset: "1"}})
                  })
                  .catch(console.error)
            }
          })
          .catch(console.error)
    },
    validatePasswordMatch(_, value) {
      return value === this.resetForm.password
    },
  },
  data() {
    return {
      requestForm: {email: ""},
      resetForm: {password: "", passwordConfirm: ""},
      loading: false,
      alertVisible: false,
      rules: {
        password: [{required: true, message: "Это поле обязательно к заполнению", trigger: 'change'}],
        passwordConfirm: [
          {
            required: true,
            message: "Пароли не совпадают",
            trigger: 'change',
            validator: this.validatePasswordMatch
          }
        ],
      }
    }
  },
}

</script>