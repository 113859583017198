global.CHANNEL_NAMES = {
  'sms' : "СМС",
  "vk": "VK/OK",
  // "sms_viber": "Viber",
  "call" : "Звонок",
  "telegram": "Telegram",
  "whatsapp": "Whatsapp",
  "viber": "Viber"
}

global.CALL_TYPES = {
  "flash" : "Коды в номере",
  "voicePassword" : "Голосовые сообщения",
  "voiceCode" : "Коды голосом"
}

global.CALL_TYPES_BY_INDEX = {
  0: "Коды в номере",
  1: "Голосовые сообщения",
  2: "Коды голосом"
}

global.CALLS_SEGMENTATION_TYPE = {
  0: "Факт звонка",
  1: "Длительность звонка"
}
global.CALLS_SEGMENTATION_UNITS = {
  0: "Секунда",
  1: "Минута"
}

global.TRAFFIC_TYPES = {
  "" : "Мультиподпись",
  "add" : "Рекламный",
  "service" : "Сервисный",
  "informational" : "Информационный",
  "transactional" : "Транзакционный",
  "authorisation" : "Авторизационный",
  "templated_add" : "Рекламный с шаблоном",
  "flash" : "Коды в номере",
  "voicePassword" : "Голосовые сообщения",
  "voiceCode" : "Коды голосом"
}

global.RATE_TYPE_NAMES = [
  "Исходящие SMS",
  "Входящие SMS",
  "Прием оплаты через ассист",
  "Прием оплаты банковским переводом",
  "Внутренний перевод",
  "Бонус",
  "Робокасса",
  "ОСМП",
  "Использование овердрафта",
  "Скидка",
  "Штрафы",
  "Банковский перевод",
  "Вознаграждение коммерческому исполнителю",
  "Разовые услуги",
  "Звонки"
]

global.RATE_SUBTYPE_NAMES = {
  0: "Фиксированный для SMS",
  1: "Гибкий для SMS",
  2: "Посендерный",
  3: "Посендерный с шаблонами",
  7: "Профи",
  8: "Международный"
}
global.CALL_RATE_SUBTYPE_NAMES = {
  0: "Фиксированный для звонков",
  1: "Гибкий для звонков"
}

global.TEMPLATE_TRAFFIC_TYPES = {
  "no_template" : "Рекламный",
  "service": "Сервисный",
  "informational": "Информационный",
  "transactional": "Транзакционный",
  "authorisation": "Авторизационный",
  "templated_add" : "Рекламный с шаблоном",
}

global.AGREGATOR_STATUS_LIST = {
  '-1': 'Ожидает отправки',
  '1': 'Сообщение отправлено',
  '2': 'Доставлено адресату',
  '3': 'Просрочено',
  '4': 'Удалено',
  '5': 'Не доставлено',
  '6': 'сообщение находится в принятом состоянии (т.е. читалось вручную от имени абонента клиентской службой)',
  '7': 'сообщение находится в недопустимом состоянии',
  '8': 'сообщение находится в отклоненном состоянии'
}

global.AGREGATOR_STATUS_LIST_CALLS = {
  '-1': 'Вызов ожидает очереди',
  '1': 'В процессе вызова',
  '2': 'Вызов осуществлен успешно',
  '__default__': 'Ошибка вызова'
}

global.DOCUMENT_DELIVERY_TYPE_NAMES = {
  'paper': 'На бумажных носителях',
  'edo': 'ЕДО'
}

global.SCHEDULE_TYPES = {
  '-1' : 'Моментальная рассылка',
  '0': "Отложенная рассылка",
  '1': "Отложенная распределенная рассылка",
  '2': "События адресатов",
}

global.verbose_statuses_call = {
  "153": "Вызов заблокирован, номере телефона находится в черном списике",
  "2561": "Превышен лимит звонков 1 абоненту в сутки",
  "220": "Звонок не прошел модерацию",
  "210": "Звонок не прошел модерацию",
  "222": "Звонок не прошел модерацию",
  "221": "Звонок не прошел модерацию",
  "211": "Звонок не прошел модерацию",
  "252": "Звонок не прошел модерацию",
  "253": "Звонок не прошел модерацию",
  "200": "Звонок поставлен в очередь",
  "201": "Звонок поставлен в очередь"
}

global.SENDERS_OPERATOR_CONFIG = {
  mts: {name: "МТС", freeNameAvailable: false},
  megafon: {name: "Мегафон", freeNameAvailable: false},
  beeline: {name: "Билайн", freeNameAvailable: true},
  tele2: {name: "Теле2", freeNameAvailable: true},
}


global.patchPhone = phone => {
  phone = phone.replace(/\D/g, '')
  if (phone.length === 11 && phone.startsWith("89")) phone = phone.replace("8", "7")
  return phone
}

global.validatePhone = phone => {
  if(phone.startsWith("372") || phone.startsWith("65")) return (phone.length >= 10 && phone.length < 14)
  else return (phone.length > 10 && phone.length < 14)
}

global.escapeRegExp = str => {
  return str.replace(/[.*+?^${}()|[\]\\\-]/g, '\\$&')
}
