<template>
  <el-dialog v-model="locVisible"
             :fullscreen="true"
             :title="title"
             @close="$emit('close')"
  >
<!--    <p style="font-weight: bold"><span style="color: red">*</span>&nbsp;Тариф по умолчанию : для профи - источник стоимости подписи, для входящих - источник стоимости ящика</p>-->
    <el-form ref="rateForm" label-position="left" :model="form" label-width="220px" class="break-label">
      <el-row :gutter="40">
        <el-col :xs="24" :md="12">
          <el-form-item label="" prop="id" style="display: none">
            <el-input v-model="form.id"/>
          </el-form-item>
          <el-form-item label="Тип" prop="type">
            <el-select v-model="form.type" class="full-width" @change="resetMainFormFields()"
                       :disabled="!!this.form.id">
              <el-option value="0" label="Исходящие"/>
              <el-option value="1" label="Входящие"/>
              <el-option value="14" label="Звонки"/>
              <el-option value="3" label="Оплата банковским переводом"/>
              <el-option value="6" label="Робокасса"/>
              <el-option value="8" label="Использование овердрафта"/>
              <el-option value="13" label="Разовое списание"/>
            </el-select>
          </el-form-item>
          <el-form-item label="Название" prop="name">
            <el-input v-model="form.name"/>
          </el-form-item>

          <el-form-item label="Название (для клиента)" prop="client_name">
            <el-input v-model="form.client_name"/>
          </el-form-item>

          <el-form-item label="Абонентская плата" prop="abonent_fee">
            <el-input v-model="form.abonent_fee"/>
          </el-form-item>
          <el-form-item label="Назначается при регистрации" prop="set_on_reg">
            <el-checkbox v-model="form.set_on_reg"/>
          </el-form-item>
          <el-form-item label="Тариф по умолчанию" prop="is_default">
            <el-checkbox v-model="form.is_default"/>
          </el-form-item>
          <el-form-item label="Индивидуальный" prop="individual">
            <el-checkbox v-model="form.is_individual"/>
          </el-form-item>
          <el-form-item label="Изменения вступят в силу" prop="rate_change_application_type">
            <el-select v-model="form.rate_change_application_type" class="full-width">
              <el-option value="0" label="Сразу"/>
              <el-option value="1" label="Отложенные"/>
            </el-select>
          </el-form-item>
          <el-form-item label="Дата применения изменений" prop="rate_change_application_date"
                        v-show="form.rate_change_application_type === '1'">
            <el-date-picker
                v-model="form.rate_change_application_date"
                type="date"
                format="DD.MM.YYYY"
                style="width: 100%"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="12">
          <div v-show="['0', '1', '14'].includes(form.type)">
            <el-form-item label="Вид тарификации" prop="type">
              <el-select v-model="form.subtype" class="full-width" @change="resetThresholds()"
                         :disabled="!!this.form.id">
                <el-option value="0" label="Фиксированный для SMS" v-if="form.type === '0'"/>
                <el-option value="1" label="Гибкий для SMS" v-if="form.type === '0'"/>
                <el-option value="7" label="Профи" v-if="form.type === '0'"/>
                <el-option value="8" label="Международный" v-if="form.type === '0'"/>

                <el-option value="0" label="Фиксированный" v-if="form.type === '14'"/>
                <el-option value="1" label="Гибкий" v-if="form.type === '14'"/>

                <el-option value="0" label="Общий" v-if="form.type === '1'"/>
                <el-option value="1" label="Индивидуальный" v-if="form.type === '1'"/>


                <!--                <el-option value="5" label="Фиксированный для Viber" v-if="form.type === '0'" disabled/>-->
                <!--                <el-option value="6" label="Гибкий для Viber" v-if="form.type === '0'" disabled/>-->

              </el-select>
            </el-form-item>
            <el-form-item label="Считать возврат только для смс прошедших порог" prop="mts_type"
                          v-show="isFlexible && form.type === '0'">
              <el-checkbox v-model="form.mts_type"/>
            </el-form-item>
            <div v-show="form.subtype === '7' || form.subtype === '8'">
              <el-form-item v-show="form.subtype === '7'" label="Считать типы трафика раздельно" prop="separated_traffic_recalculation">
                <el-checkbox v-model="form.separated_traffic_recalculation"/>
              </el-form-item>
              <el-form-item label="Абонентская плата за одно имя отправителя в месяц" prop="one_name_price">
                <el-input v-model="form.one_name_price"/>
              </el-form-item>
              <el-form-item label="Количество бесплатных имен отправителя по тарифу" prop="free_names_amount">
                <el-input v-model="form.free_names_amount"/>
              </el-form-item>
            </div>
          </div>

          <el-form-item label="Подтип звонков" prop="call_rate_type" v-show="form.type === '14'">
            <el-select v-model="form.call_rate_type" class="full-width">
              <el-option value="0" label="Коды в номере"/>
              <el-option value="2" label="Коды голосом"/>
              <el-option value="1" label="Голосовые сообщения"/>
            </el-select>
          </el-form-item>

          <div v-show="form.type === '14' && form.call_rate_type === '1'">
            <el-form-item label="Сегмент тарификации" prop="segmentation">
              <el-select v-model="form.segmentation" class="full-width">
                <el-option value="0" label="Факт звонка"/>
                <el-option value="1" label="Длительность звонка"/>
              </el-select>
            </el-form-item>
            <div v-show="form.segmentation === '1'">
              <el-form-item label="Порог сегментации" prop="segmentation_value">
                <el-input v-model="form.segmentation_value"/>
              </el-form-item>
              <el-form-item label="Единица измерения" prop="segmentation_unit">
                <el-select v-model="form.segmentation_unit" class="full-width">
                  <el-option value="0" label="Секунда"/>
                  <el-option value="1" label="Минута"/>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div v-show="['6'].includes(form.type)">
            <el-form-item label="Размер комиссии" prop="comission">
              <el-input v-model="form.comission"/>
            </el-form-item>
            <el-form-item label="Минимальная комиссия" prop="minimal_comission">
              <el-input v-model="form.minimal_comission"/>
            </el-form-item>
            <el-form-item label="Минимальная сумма пополнения" prop="minimal_deposit_amount">
              <el-input v-model="form.minimal_deposit_amount"/>
            </el-form-item>
          </div>


        </el-col>
      </el-row>
    </el-form>

    <el-divider class="blue-divider"/>
    <el-form inline ref="thresholdsConfigForm" :model="thresholdsConfigForm" style="display: flex; justify-content: space-between">

      <el-form-item label="" prop="newPriceEntityType">
        <el-select v-model="thresholdsConfigForm.newPriceEntityType" style="margin-right: 20px">
          <el-option label="Оператор" value="operator"/>
          <el-option label="Группа операторов" value="operatorGroup"/>
          <el-option label="Страна" value="country" :disabled="form.subtype === '7'"/>
          <el-option label="Зона" value="zone" :disabled="form.subtype === '7'"/>

        </el-select>
        <el-select v-show="thresholdsConfigForm.newPriceEntityType === 'operator'"
                   filterable
                   v-model="thresholdsConfigForm.newPriceEntity">
          <el-option
              v-for="o in $store.getters.operators"
              :value="o._id"
              :label="o.OrgName"
          />
        </el-select>
        <el-select v-show="thresholdsConfigForm.newPriceEntityType === 'operatorGroup'"
                   filterable
                   v-model="thresholdsConfigForm.newPriceEntity">
          <el-option
              v-for="o in $store.getters.operatorsGroups"
              :value="o._id"
              :label="o.title"
          />
        </el-select>
        <el-select v-show="thresholdsConfigForm.newPriceEntityType === 'country'"
                   filterable
                   v-model="thresholdsConfigForm.newPriceEntity">
          <el-option
              v-for="o in $store.getters.countries"
              :value="o._id"
              :label="o.country_name"
          />
        </el-select>
        <el-select v-show="thresholdsConfigForm.newPriceEntityType === 'zone'"
                   v-model="thresholdsConfigForm.newPriceEntity">
          <el-option
              v-for="o in $store.getters.zones"
              :value="o._id"
              :label="o.name"
          />
        </el-select>
        <el-button type="success" @click="addPriceEntity()" style="margin-left: 20px">
          <font-awesome-icon icon="plus"></font-awesome-icon>
        </el-button>
      </el-form-item>

      <el-form-item v-show="form.subtype !== '0'" style="max-width: 250px; min-width:150px">
        <el-input
            style="width: 70%"
            placeholder="Порог"
            v-model="thresholdsConfigForm.newThreshold"
        />
        <el-button type="success" @click="addThreshold()" style="margin-left: 5px">
          <font-awesome-icon icon="plus"></font-awesome-icon>
        </el-button>
      </el-form-item>


    </el-form>
    <el-table :data="tableData">
      <el-table-column label="" min-width="160px">
        <template #header>
          <el-button text class="table-text-button blue-button" @click="addTrafficType()" v-show="form.subtype === '7'">
            <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp;Тип трафика
          </el-button>
        </template>
        <template #default="scope">
          {{ scope.row.entityName }}&nbsp;&nbsp;<font-awesome-icon icon="times" style="cursor: pointer"
                                                                   @click="removeRow(scope.row)"></font-awesome-icon>
        </template>
      </el-table-column>
      <el-table-column label="Тип трафика" v-if="form.subtype === '7'" min-width="120px">
        <template #default="scope">
          <el-select v-model="actualRows.find(r => r.id === scope.row.id).trafficType"
                     v-if="scope.row.trafficType !== 'no_template'">
            <el-option label="Сервисный" value="service"/>
            <el-option label="Рекламный (с шаблоном)" value="templated_add"/>
            <el-option label="Информационный" value="informational"/>
            <el-option label="Транзакционный" value="transactional"/>
            <el-option label="Авторизационный" value="authorisation"/>
            <!--            <el-option label="Без шаблона" value="no_template" v-if="scope.row.trafficType !== 'no_template'"/>-->
          </el-select>
          <span v-else>Рекламный (без шаблона)</span>
        </template>
      </el-table-column>
      <el-table-column
          v-for="(t, k) in thresholds" min-width="100px"
      >
        <template #header>
          {{ t.label }}&nbsp;&nbsp;<font-awesome-icon icon="times" v-if="t.value > 0" style="cursor: pointer"
                                                      @click="removeThreshold(t.value)"></font-awesome-icon>
        </template>
        <template #default="scope">
          <el-input v-model="actualRows.find(r => r.id === scope.row.id).thresholds[String(t.value)].price"/>
        </template>
      </el-table-column>

    </el-table>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="$emit('close')">Закрыть</el-button>
        <el-button type="success" @click="upsertRate" :loading="upserting">Сохранить</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>


import moment from "moment";

export default {
  name: "RateForm",
  props: ["visible", "editingRate"],
  watch: {
    visible(v) {
      this.locVisible = v
    },
    "thresholdsConfigForm.newPriceEntityType": function () {
      this.thresholdsConfigForm.newPriceEntity = ""
    },
    "form.subtype": function (v) {
      if (v === "7")
        this.thresholdsConfigForm.newPriceEntityType = "operatorGroup"
    },
    async editingRate(newVal) {
      if (!newVal || !newVal._id) {
        this.form = this.defaultFormState()
        this.resetThresholds()
      } else {
        this.form.id = String(newVal._id)
        this.form.name = String(newVal.name)
        this.form.client_name = String(newVal.client_name)
        this.form.type = String(newVal.type)
        this.form.subtype = String(newVal.subtype)
        this.form.call_rate_type = String(newVal.call_rate_type)
        this.form.set_on_reg = newVal.set_on_reg
        this.form.is_default = newVal.is_default
        this.form.mts_type = newVal.mts_type
        this.form.separated_traffic_recalculation = newVal.separated_traffic_recalculation
        this.form.one_name_price = Number(newVal.one_name_price) || 0
        this.form.free_names_amount = Number(newVal.free_names_amount) || 0
        this.form.comission = newVal.comission
        this.form.minimal_comission = newVal.minimal_comission
        this.form.minimal_deposit_amount = newVal.minimal_deposit_amount
        this.form.segmentation = String(newVal.segmentation)
        this.form.segmentation_value = String(newVal.segmentation_value)
        this.form.segmentation_unit = String(newVal.segmentation_unit)
        this.form.rate_change_application_type = String(newVal.rate_change_application_type || 0)
        this.form.rate_change_application_date = newVal.activateAt ? moment(newVal.activateAt, "DD.MM.YYYY").toDate() : ""
        if (newVal.thresholds && newVal.thresholds.length) {
          let {priceEntities, thresholds, actualRows} = (newVal.thresholds || []).reduce((acc, t) => {
            acc.priceEntities.add(t.oper_group_id)
            if (!acc.thresholds.find(et => et.value === Number(t.threshold))) acc.thresholds.push({
              label: String(t.threshold === "0" ? "1" : t.threshold),
              value: Number(t.threshold)
            })
            if (!acc.actualRows[t.oper_group_id + t.template_type]) acc.actualRows[t.oper_group_id + t.template_type] = {
              entityId: t.oper_group_id,
              id: String(Math.random()),
              trafficType: t.template_type,
              thresholds: {},
              bindType: t.bindType || "operatorGroup",
            }
            acc.actualRows[t.oper_group_id + t.template_type].thresholds[t.threshold] = {
              price: t.value
            }
            return acc
          }, {priceEntities: new Set(), actualRows: {}, thresholds: []})
          this.priceEntities = Array.from(priceEntities)
          this.thresholds = thresholds
          this.actualRows = Object.values(actualRows).sort((r1, r2) => r1.trafficType === r2.trafficType ? 0 : r1.trafficType === 'no_template' ? -1 : 1)
        }else this.resetThresholds()
      }
    }
  },
  mounted() {
    // this.axios.get("/resources/operators")
    //     .then(resp => {
    //       this.rawOperators = resp.data
    //     })
  },
  methods: {
    resetMainFormFields() {
      this.form.subtype = "0"
      this.form.call_rate_type = "0"
      this.form.mts_type = false
      this.form.separated_traffic_recalculation = false
      this.form.is_individual = false
      this.form.one_name_price = ""
      this.form.free_names_amount = ""
      this.form.comission = ""
      this.form.minimal_comission = ""
      this.form.minimal_deposit_amount = ""
      this.form.segmentation = "0"
      this.form.segmentation_value = ""
      this.form.segmentation_unit = ""
      this.resetThresholds()
    },
    resetThresholds() {
      this.thresholds = [{label: '1', value: 0}]
      this.priceEntities = []
      this.actualRows = []
    },
    upsertRate() {
      this.$refs.rateForm.validate()
          .then(valid => {
            if (!valid) return
            this.upserting = true
            this.axios.post("/admin/finance/upsertRate", {data: this.form, actualRows: this.actualRows})
                .then(resp => {
                  this.upserting = false
                  this.$emit("close")
                  this.$emit("reload")
                }).catch(console.error)
          })
    },
    addPriceEntity() {
      let type = this.thresholdsConfigForm.newPriceEntityType
      let entityId = this.thresholdsConfigForm.newPriceEntity
      if (!entityId) return this.$gNotify("Выберите значение для добавления", "error")
      if (this.priceEntities.includes(entityId)) return this.$gNotify("Такое значение уже добавлено", "error")
      if (this.priceEntities.length && this.form.subtype === '7') return this.$gNotify("Нельзя добавить больше одного оператора", "error")
      let oldRows = this.actualRows
      if (this.form.subtype === '7')
        oldRows.push({
          entityId,
          bindType: type,
          id: String(Math.random()),
          trafficType: "no_template",
          thresholds: this.thresholds.reduce((acc, t) => {
            acc[String(t.value)] = {price: "",}
            return acc
          }, {})
        })

      oldRows.push({
        entityId,
        bindType: type,
        id: String(Math.random()),
        trafficType: "",
        thresholds: this.thresholds.reduce((acc, t) => {
          acc[String(t.value)] = {price: "",}
          return acc
        }, {})
      })

      this.priceEntities.push(entityId)
      this.actualRows = oldRows
      this.thresholdsConfigForm.newOperator = ""
      this.thresholdsConfigForm.newZone = ""
    },
    addThreshold() {
      let tVal = Number(this.thresholdsConfigForm.newThreshold)
      if (!tVal) return this.$gNotify("Укажите порог", "error")
      if (this.thresholds.find(t => t.value === Number(tVal))) return this.$gNotify("Такой порог уже добавлен", "error")

      let oldThresholds = this.thresholds

      for (let row of this.actualRows)
        row.thresholds[String(tVal)] = {price: ""}

      oldThresholds.push({label: String(tVal), value: tVal})
      this.thresholds = oldThresholds.sort((t1, t2) => t1.value - t2.value)
      this.thresholdsConfigForm.newThreshold = ""
    },
    addTrafficType() {
      let entityId = this.actualRows[0].entityId
      let bindType = this.actualRows[0].bindType
      let oldRows = this.actualRows
      oldRows.push({
        entityId,
        bindType,
        id: String(Math.random()),
        trafficType: "",
        thresholds: this.thresholds.reduce((acc, t) => {
          acc[String(t.value)] = {
            price: "",
            trafficType: ""
          }
          return acc
        }, {})
      })
      this.actualRows = oldRows
    },

    removeThreshold(tVal) {
      let oldThresholds = this.thresholds
      oldThresholds = oldThresholds.filter(t => t.value !== tVal)
      for (let row of this.actualRows)
        delete row.thresholds[String(tVal)]
      this.thresholds = oldThresholds.sort((t1, t2) => t1.value - t2.value)
    },
    removeRow(row) {
      if (row.trafficType === "no_template" && this.form.subtype === '7') {
        this.actualRows = []
        this.priceEntities = []
      } else
        this.actualRows.splice(this.actualRows.findIndex(r => String(r.id) === String(row.id)), 1);
    },
    defaultFormState() {
      return Object.assign({}, {
        id: "",
        name: "",
        client_name: "",
        type: "0",
        subtype: "0",
        abonent_fee: "",
        call_rate_type: "0",
        set_on_reg: false,
        is_default: false,
        mts_type: false,
        separated_traffic_recalculation: false,
        one_name_price: "",
        free_names_amount: "",
        comission: "",
        minimal_comission: "",
        minimal_deposit_amount: "",
        segmentation: "0",
        segmentation_value: "0",
        segmentation_unit: "0",
        rate_change_application_type: "0",
        rate_change_application_date: "",
        is_individual: false
      })
    },

  },
  computed: {
    title() {
      return this.editingRate && this.editingRate._id ? "Редактирование тарифа" : "Новый тариф"
    },
    isFlexible() {
      return ['1', '6', '7', '8'].includes(this.form.subtype)
    },
    tableData() {
      return this.actualRows.map(r => {
        if (r.bindType === 'operator') r.entityName = "[Опер.] " + (this.$store.getters.operators.find(o => o._id === r.entityId) || {OrgName: "N/A"}).OrgName
        if (r.bindType === 'operatorGroup') r.entityName = "[Груп.] " + (this.$store.getters.operatorsGroups.find(o => o._id === r.entityId) || {title: "N/A"}).title
        if (r.bindType === 'country') r.entityName = "[Стра.] " + (this.$store.getters.countries.find(z => z._id === r.entityId) || {country_name: "N/A"}).country_name
        if (r.bindType === 'zone') r.entityName = "[Зона] " + (this.$store.getters.zones.find(z => z._id === r.entityId) || {name: "N.A"}).name
        return r
      })
    }
  },
  data() {
    return {
      form: this.defaultFormState(),
      thresholdsConfigForm: {
        newPriceEntityType: "operatorGroup",
        newPriceEntity: "",
        newThreshold: "",
      },
      actualRows: [],
      thresholds: [{label: '1', value: 0}],
      priceEntities: [],
      rawOperators: [],
      removingOne: false,

      upserting: false,
      locVisible: false
    }
  },
}

</script>