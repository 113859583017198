<template>
  <div>

    <el-row :gutter="20">
      <el-col :span="24">

        <el-form label-width="200" label-position="top" size="small">
          <div style="display: flex;flex-grow: 1; gap:30px">
            <el-form-item label="Введите сумму платежа в руб." class="text-bold" style="flex-grow: 1">
              <el-input v-model="newOperation.amount"/>
            </el-form-item>

            <el-form-item label="На основании договора" class="text-bold" v-show="newOperation.type === 'bank'"  style="flex-grow: 1">
              <input type="checkbox" v-model="newOperation.byAgreement"/>
            </el-form-item>
          </div>
          <div style="display: flex;flex-grow: 1; gap:30px">

            <el-form-item label="Тип операции" class="text-bold"  style="flex-grow: 1">
              <el-select v-model="newOperation.type" style="width: 100%">
                <el-option value="bank" label="Безналичный банковский перевод"/>
                <el-option value="one_time" label="Разовое списание"/>
                <el-option value="yandex" label="Яндекс.Деньги"/>
                <el-option value="webmoney" label="WebMoney"/>
                <el-option value="qiwi" label="Qiwi"/>
                <el-option value="test" label="Переводы средств для тестирования"/>
              </el-select>

            </el-form-item>

            <el-form-item label="Неоплаченный счет" class="text-bold"  style="flex-grow: 1"
                          v-show="!newOperation.byAgreement && newOperation.type === 'bank'">
              <el-select v-model="newOperation.unpaidBill" style="width: 100%">
                <el-option value="" label="----"/>
                <el-option v-for="b in unpaidRequests" :value="b._id"
                           :label="`Счет ${b.payment_order}-${contractType === 0 ? 'А' : 'К'} на ${b.sum} руб.`"
                />

              </el-select>
            </el-form-item>
          </div>
          <!--              <el-form-item label="Платёжное поручение" class="text-bold">-->
          <!--                <div style="display: flex; justify-content: space-between; gap: 20px; flex-grow: 1">-->
          <!--                  <el-input v-model="newOperation.ppNumber" placeholder="Номер" style="flex-grow: 1; max-width: 50%"-->
          <!--                            :disabled="!!newOperation.unpaidBill"/>-->
          <!--                  <el-date-picker-->
          <!--                      v-model="newOperation.ppDate" placeholder="От" style="width: auto; flex-grow: 1"-->
          <!--                      :disabled="!!newOperation.unpaidBill"-->
          <!--                      format="DD.MM.YYYY"-->
          <!--                  />-->
          <!--                </div>-->
          <!--              </el-form-item>-->


        </el-form>
        <el-button type="success" @click="createPayment()" :loading="paymentProcessing" style="margin-top: 10px">
          Выполнить
        </el-button>
        <el-divider/>

        <h4 class="report-parameters-title"
            style="display:flex;justify-content: space-between; align-items: center; margin-bottom: 10px">
          <span>Счета и зачисления</span>
          <el-form label-width="200" label-position="top" inline size="small">
            <el-form-item label="Создать новый счет" class="text-bold">
              <el-input v-model="newBillAmount" placeholder="Введите сумму платежа в руб."/>
            </el-form-item>
            <el-form-item label=" " class="text-bold">
              <el-button type="success" @click="createBill()" :loading="billCreating">Сформировать
              </el-button>
            </el-form-item>
          </el-form>
        </h4>
        <TableSpinner :loading="dataLoading">
          <el-table
              :data="billsVisible"
              style="width: 100%"
              stripe
              :row-class-name="opsRowClassName"
          >
            <el-table-column :formatter="dateTimeFormat" label="Создан" min-width="150"/>
            <el-table-column prop="name" label="Название" min-width="280"/>
            <el-table-column prop="sum" label="Сумма" min-width="140"/>
            <el-table-column prop="payment_order" label="Платежное поручение" min-width="200"/>
            <el-table-column width="160" align="right">
              <template #default="scope">
                <a :href="`${axios.defaults.baseURL}/admin/finance/downloadBill?billId=${scope.row._id}&token=${$store.getters.bareToken}&systemId=${scope.row.user_id}`"
                   target="_blank"
                   v-if="scope.row.number && !scope.row.robo_signature && !scope.row.isUMoney"
                >
                  <el-button text class="text-button" type="success" size="small"
                             style="">
                    Скачать
                  </el-button>
                </a>
                <remove-button
                    style="" v-if="String(scope.row.accepted) !== '1'"
                    @confirm="removeBill(scope.row._id)" :loading="billRemoving"
                >
                  Удалить
                </remove-button>
                <!--                  <el-button-->
                <!--                      text class="text-button" type="primary" size="small"-->
                <!--                      @click="showEditBillModal(scope.row)"-->
                <!--                      style="min-height: auto; padding: 0px" v-else-->
                <!--                  >-->
                <!--                    Изменить-->
                <!--                  </el-button>-->
              </template>
            </el-table-column>
          </el-table>
          <div class="solo-pagination-container">
            <el-pagination
                background
                class="wide-page-size"
                layout="sizes, prev, pager, next"
                :total="bills.length"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="pageSize"
                :hide-on-single-page="true"
                :pager-count="3"
                :current-page="currentPage"
                @size-change="handlePageSizeChange"
                @current-change="handleCurrentPageChange"
            >
            </el-pagination>
          </div>
        </TableSpinner>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import moment from "moment"
import RemoveButton from "@shared/components/layout/removeButton.component.vue";

export default {
  name: "ClientFinancialOperations",
  props: ['systemId', 'clientData'],
  components: {RemoveButton},
  watch: {
    systemId(v) {
      if (v)
        this.getUserOperations()
    },
    "newOperation.unpaidBill": function (billId) {
      let theBill = this.bills.find(b => b._id === billId)
      if (theBill && !this.newOperation.amount) {
        this.newOperation.amount = Number(theBill.sum)
      }
    }
  },
  mounted() {

  },
  computed: {
    billsVisible() {
      return this.bills.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    },
    unpaidRequests() {
      return this.bills.filter(b => String(b.accepted) !== '1' && !b.is_automatic) || []
    },
    contractType() {
      return this.user ? this.user.info.contract.payment_type : 0
    }
  },
  methods: {
    opsRowClassName({row}) {
      let className = ""
      if (String(row.accepted) === '1') className = ' blue-bill-row'
      if (row.is_automatic) className = ' green-bill-row'
      return className
    },
    handlePageSizeChange(newSize) {
      this.pageSize = newSize
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage
    },
    dateTimeFormat(row) {
      return moment(row.date_create).format("DD.MM.YYYY HH:mm")
    },
    getUserOperations() {
      this.dataLoading = true
      this.axios.get("/admin/finance/getUserBills", {params: {system_id: this.systemId}})
          .then(resp => {
            this.dataLoading = false
            this.bills = resp.data.autopayments.concat(resp.data.bills).sort((b1, b2) => moment(b2.date_create).valueOf() - moment(b1.date_create).valueOf())
          })
          .catch(console.error)
    },
    createPayment() {
      if (this.newOperation.amount <= 0)
        return this.$gNotify("Неверная сумма операции", "error")

      if (this.clientData.active === '0')
        return this.$gNotify("Пополнение баланса запрещено: данный пользователь заблокирован", "error")

      if (this.clientData.balance.blocked === '1')
        return this.$gNotify("Пополнение баланса запрещено: счет данного пользователя заблокирован", "error")

      if (this.newOperation.type === 'bank' && !this.newOperation.byAgreement && !this.newOperation.unpaidBill)
        return this.$gNotify("Выберите неоплаченный счёт", "error")
      this.paymentProcessing = true
      this.axios.post("/admin/finance/executeOperation", {operation: this.newOperation, systemId: this.systemId})
          .then(resp => {
            this.paymentProcessing = false
            if (resp.data && resp.data.error) {
              this.$gNotify(resp.data.error, "error")
            } else {
              this.$gNotify("Операция выполнена", "success")
              this.newOperation.unpaidBill = ""
              this.newOperation.type = "bank"
              this.newOperation.amount = 0
              this.newOperation.byAgreement = false
              this.getUserOperations()
            }
          })
          .catch(e => {
            console.error(e)
            this.$gNotify("Ошибка выполнения", "error")
            this.paymentProcessing = false
          })
    },
    createBill() {
      if (this.newBillAmount <= 0) {
        return this.$gNotify("Неверная сумма счета", "error")
      }
      this.billCreating = true
      this.axios.post("/admin/finance/createBill", {amount: this.newBillAmount, systemId: this.systemId})
          .then(resp => {
            this.$gNotify("Счет успешно сформирован", "success")
            this.getUserOperations()
            this.newBillAmount = 0
            this.billCreating = false
          })
          .catch(console.error)
    },
    removeBill(billId) {
      this.billRemoving = true
      this.axios.delete("/admin/finance/removeBill", {params: {billId}})
          .then(resp => {
            this.$gNotify("Счет удалён", "success")
            this.getUserOperations()
            this.billRemoving = false
          })
          .catch(console.error)
    }
    // showEditBillModal(bill) {
    //   this.editBill._id = bill._id
    //   this.editBill.incomeType = bill.
    // }
  },
  data() {
    return {
      ratesLoaded: false,
      dataLoading: false,
      paymentProcessing: false,
      newOperation: {
        amount: 0,
        type: "bank",
        byAgreement: false,
        unpaidBill: "",
        ppNumber: "",
        ppDate: new Date()
      },
      newBillAmount: 0,
      billCreating: false,
      billRemoving: false,
      bills: [],
      pageSize: 10,
      currentPage: 1,

      editBillModalVisible: false,
      editBill: {
        _id: "",
        incomeType: ""
      }
    }
  }
}

</script>

<style>

.green-bill-row td,
.blue-bill-row td {
  background-color: transparent !important;
}

.green-bill-row td .cell {
  font-weight: bold;
  color: var(--el-color-success) !important;
}

.blue-bill-row > td:nth-child(2) > .cell {
  color: var(--el-color-primary-dark-2) !important;
}

</style>
