<template>

  <el-form-item class="center-content">
    <div id="captcha-custom-container" ref="capContainer">
      <input type="hidden" name="smart-token" ref="smart-input" />
    </div>

<!--    <img :id="imgId"/>-->
<!--    <span style="cursor : pointer; font-size : 30px;" @click="getNewCaptcha"><font-awesome-icon-->
<!--        icon="redo"></font-awesome-icon></span>-->
<!--    <el-input v-model="captcha" placeholder="Код с картинки"></el-input>-->
  </el-form-item>


</template>

<script>

export default {
  name: "CaptchaInput",
  props: {
    modelValue: String,
    ctype: String
  },

  emits: ['update:modelValue'],
  mounted() {
    this.mutObserve = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'value') {
          this.captcha = mutation.target.value
        }
      }
    })
    this.getNewCaptcha()
    // setTimeout(() => {
    //
    // }, 2000)

  },
  beforeUnmount() {
    if(this.mutObserve) this.mutObserve.disconnect()
  },
  watch:{
    captcha(newVal){
      this.$emit('update:modelValue', newVal);
    }
  },
  methods: {
    getNewCaptcha() {
      if (window.smartCaptcha) {
        this.captcha = ""
        if(this.lastWidgetId)
          window.smartCaptcha.destroy(this.lastWidgetId)
        const container = this.$refs.capContainer
        this.lastWidgetId = window.smartCaptcha.render(container, {
          sitekey: process.env.VUE_APP_YANDEX_CAPTCHA_TOKEN,
          hl: 'ru',
        });

        const targetElement = Array.from(container.children).find(c => c.name === 'smart-token');
        this.mutObserve.observe(targetElement, { attributes: true });
      }
      // document.getElementById(this.imgId).setAttribute(
      //     "src",
      //     this.axios.defaults.baseURL + `/auth/captcha?type=${this.ctype || 'login'}&t=${Date.now()}`
      // );
    }
  },
  data() {
    return {
      captcha: "",
      lastWidgetId: "",
      imgId: "captcha_" + parseInt(Math.random() * 1000),
      mutObserve: null
    }
  }
}

</script>