<template>
  <el-dialog v-model="intVisible"
             :title="title"
             @close="$emit('close')"
             class="modal-95-70-60"
  >
    <el-row>
      <el-col :span="24">
        <el-form label-position="top" ref="nameForm" :rules="formRules"  :model="form">
          <el-form-item label="" prop="id" style="display: none">
            <el-input v-model="form.id"/>
          </el-form-item>
          <el-form-item label="Подпись" prop="name">
            <el-input v-model="form.name"/>
          </el-form-item>
          <el-form-item label="Оператор" prop="operatorId">
            <el-select v-model="form.operatorId" class="full-width" filterable>
              <el-option
                  v-for="operator in internationalOperators"
                  :value="operator._id"
                  :label="platformOperatorName(operator)"
              />

            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="$emit('close')">Закрыть</el-button>
        <el-button type="success" @click="upsertName" :loading="upserting">Сохранить</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>


export default {
  name: "IntNameForm",
  props: ["visible", "editingName", "internationalOperators", "platformOperators", "isExclusion"],
  watch: {
    visible(v){
      this.intVisible = v
    },
    async editingName(newVal) {
      if (!newVal || !newVal._id){

        this.form.id = ""
        this.form.name = ""
        this.form.operatorId = ""
      } else {
        this.form.id = String(newVal._id)
        this.form.name = newVal.name
        this.form.operatorId = newVal.operatorId

      }
    }
  },
  methods: {
    upsertName() {
      let url = '/admin/routing/upsertInternationalName'
      if(this.isExclusion)
        url = '/admin/routing/upsertInternationalNameExclusion'
      this.$refs.nameForm.validate()
          .then(valid => {
            if(!valid) return
            this.upserting = true
            this.axios.post(url, {data: this.form})
                .then(resp => {
                  this.upserting = false
                  this.$emit("close")
                  this.$emit("reload")
                }).catch(console.error)
          })

    },
    platformOperatorName(row) {
      return row.bindType === 'group' ?
          (this.$store.getters.operatorsGroups.find(o => o._id === row.platformOperatorId) || {title: "N/A"}).title + " (Группа)" :
          (this.platformOperators.find(o => o._id === row.platformOperatorId) || {OrgName: "N/A"}).OrgName + " (Оператор)"
    },
  },
  computed: {
    title() {
      return this.editingName && this.editingName._id ? "Редактирование имени" : "Новое имя"
    },

  },
  data() {
    return {
      form: {
        id: "",
        name: "",
        operatorId:""
      },
      formRules: {
        id: [{required: false}],
        name: [{required: true, trigger: "blur"}],
        operatorId: [{required: true, trigger: "blur"}]
      },
      upserting: false,
      intVisible: false
    }
  },
}

</script>