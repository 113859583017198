<template>
  <el-dialog v-model="intVisible"
             :title="title"
             class="modal-95-70-60"
  >
    <el-row>
      <el-col :span="24">
        <el-form label-position="top" ref="agregatorForm" :rules="formRules" :model="form">
          <el-form-item label="" prop="id" style="display: none">
            <el-input v-model="form.id"/>
          </el-form-item>
          <el-form-item label="Название" prop="name">
            <el-input v-model="form.name"/>
          </el-form-item>
          <el-form-item label="Тип" prop="type">
            <el-select v-model="form.type" class="full-width">
              <el-option value="smpp" label="SMPP"/>
              <el-option value="vk" label="VK/OK"/>
              <el-option value="telegram" label="Telegram"/>
              <el-option value="calls" label="Звонки"/>
              <el-option value="mock" label="Имитация"/>
            </el-select>
          </el-form-item>

          <div v-show="form.type === 'smpp'">
            <el-form-item label="Хост" prop="ip">
              <el-input v-model="form.ip"/>
            </el-form-item>
            <el-form-item label="Порт" prop="port">
              <el-input v-model="form.port"/>
            </el-form-item>
            <el-form-item label="Логин" prop="login">
              <el-input v-model="form.login"/>
            </el-form-item>
            <el-form-item label="Пароль" prop="password">
              <el-input v-model="form.password"/>
            </el-form-item>
            <el-form-item label="Режим" prop="mode">
              <el-select v-model="form.mode" class="full-width">
                <el-option value="bind_transceiver" label="Transceiver (Приём/Передача)"/>
                <el-option value="bind_transmitter" label="Transmitter (Передача)"/>
                <el-option value="bind_receiver" label="Receiver (Приём)"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Скорость (смс/сек)" prop="speed">
              <el-input v-model="form.speed"/>
            </el-form-item>
          </div>

          <!-- MOCK TYPE -->
          <div v-show="form.type === 'mock'">
            <el-form-item label="% статуса Доставлено" prop="deliveredPercent">
              <el-input v-model="form.deliveredPercent"/>
            </el-form-item>
<!--            <el-form-item label="% статуса Прочитано" prop="readPercent">-->
<!--              <el-input v-model="form.readPercent"/>-->
<!--            </el-form-item>-->
            <el-form-item label="% статуса Не доставлено" prop="undeliveredPercent">
              <el-input v-model="form.undeliveredPercent"/>
            </el-form-item>
            <el-form-item label="% статуса Просрочено" prop="expiredPercent">
              <el-input v-model="form.expiredPercent"/>
            </el-form-item>
            <el-form-item label="Задержка статуса 'от' (в секундах)" prop="delayFrom">
              <el-input v-model="form.delayFrom"/>
            </el-form-item>
            <el-form-item label="Задержка статуса 'до' (в секундах)" prop="delayTo">
              <el-input v-model="form.delayTo"/>
            </el-form-item>
          </div>


        </el-form>
      </el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="$emit('close')">Закрыть</el-button>
        <el-button type="success" @click="upsertAgregator" :loading="upserting">Сохранить</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>


export default {
  name: "AgregatorForm",
  props: ["visible", "editingAgregator"],
  watch: {
    visible(v){
      this.intVisible = v
    },
    async editingAgregator(newVal) {
      if (!newVal || !newVal._id){
        this.form = this.defaultFormState()
      }
      else {
        this.form.id = String(newVal._id)
        this.form.name = newVal.name
        this.form.type = newVal.type || "smpp"
        this.form.ip = newVal.ip
        this.form.port = newVal.port
        this.form.login = newVal.login
        this.form.password = newVal.password
        this.form.mode = newVal.mode
        this.form.speed = newVal.speed
        this.form.deliveredPercent = newVal.deliveredPercent
        this.form.readPercent = newVal.readPercent
        this.form.undeliveredPercent = newVal.undeliveredPercent
        this.form.expiredPercent = newVal.expiredPercent
        this.form.delayFrom = newVal.delayFrom
        this.form.delayTo = newVal.delayTo
      }
    }
  },
  methods: {
    upsertAgregator() {
      this.$refs.agregatorForm.validate()
          .then(valid => {
            if(!valid) return
            this.upserting = true
            this.axios.post("/admin/routing/upsertAgregator", {data: this.form})
                .then(resp => {
                  this.upserting = false
                  this.$emit("close")
                  this.$emit("reload")
                }).catch(console.error)
          })

    },
    defaultFormState(){
      return Object.assign({}, {
        id: "",
        type: "smpp",
        name: "",
        ip: "",
        port: "",
        login: "",
        password: "",
        mode: "",
        speed: "",
        deliveredPercent: "",
        readPercent: "",
        undeliveredPercent: "",
        expiredPercent: "",
        delayFrom: "",
        delayTo: "",
      },)
    }
  },
  computed: {
    title() {
      return this.editingAgregator && this.editingAgregator._id ? "Редактирование агрегатора" : "Новый агрегатор"
    },
    isSmpp(){
      return this.form.type === "smpp"
    }
  },
  data() {
    return {
      form: this.defaultFormState(),
      formRules: {
        id: [{required: false}],
        name: [{required: true, trigger: "blur"}],
        type: [{required: true, trigger: "blur"}],
        speed: [{required: false, trigger: "blur"}],
        ip: [{required: this.isSmpp, trigger: "blur"}],
        port: [{required: this.isSmpp, trigger: "blur"}],
        login: [{required: this.isSmpp, trigger: "blur"}],
        password: [{required: this.isSmpp, trigger: "blur"}],
        mode: [{required: this.isSmpp, trigger: "blur"}],
        deliveredPercent: [{required: false, trigger: "blur"}],
        readPercent: [{required: false, trigger: "blur"}],
        undeliveredPercent: [{required: false, trigger: "blur"}],
        expiredPercent: [{required: false, trigger: "blur"}],
        delayFrom: [{required: false, trigger: "blur"}],
        delayTo: [{required: false, trigger: "blur"}],
      },
      upserting: false,
      intVisible: false
    }
  },
}

</script>