<template>
  <div>
    <el-dialog
        v-model="intVisible"
        title="Информация о тарифном плане"
        @close="$emit('close')"
        class="modal-95-70-60"
    >
      <el-row>
        <el-col :xs="24">
          <ul style="padding-left: 0px; list-style: none; word-break: break-word">
            <li>
              <span class="text-bold">Тип тарифа:</span>&nbsp;&nbsp;{{ selectedRateType }}
            </li>
            <li>
              <span class="text-bold">Название тарифа:</span>&nbsp;&nbsp;{{ selectedRate.client_name || selectedRate.name }}
            </li>
            <li>
              <span class="text-bold">Заригестрировано имён: </span>&nbsp;&nbsp;{{ selectedRateRegisteredSenderNamesAmount }}<br/>
            </li>
            <li v-if="selectedRate.free_names_amount">
              <span class="text-bold">
                Количество бесплатных имён:</span>&nbsp;&nbsp;{{ selectedRate.free_names_amount || 0 }}<br/>
            </li>
            <li>
              <span class="text-bold">Стоимость одного имени:</span>&nbsp;&nbsp;{{ selectedRate.one_name_price || 0 }}&nbsp;руб.<br/>
            </li>
            <li><span class="text-bold">Абонентская плата:</span>&nbsp;&nbsp;{{ selectedRateFee }}&nbsp;руб.<br/></li>
            <li v-show="selectedRate.type === 14">
              <span class="text-bold">Вид звонков:</span>&nbsp;&nbsp;{{ callsType }}<br/>
            </li>
            <li  v-show="selectedRate.type === 14 && selectedRate.call_rate_type === 1">
              <span class="text-bold">Сегмент тарификации:</span>&nbsp;&nbsp;{{ callsSegmentationType }}<br/>
            </li>
            <li  v-show="selectedRate.type === 14 && selectedRate.call_rate_type === 1">
              <span class="text-bold">Порог сегментации:</span>&nbsp;&nbsp;{{ selectedRate.segmentation_value }}<br/>
            </li>
            <li  v-show="selectedRate.type === 14 && selectedRate.call_rate_type === 1">
              <span class="text-bold">Единица измерения:</span>&nbsp;&nbsp;{{ callsSegmentationUnit}}<br/>
            </li>

          </ul>
        </el-col>

      </el-row>
      <el-row v-show="selectedRate.type !== 1">
        <el-col :span="24">
          <el-divider></el-divider>
          <div v-show="selectedRateRegisteredSenderNames.length">
            <p><span class="text-bold">Подписи:&nbsp;&nbsp;</span>{{ selectedRateRegisteredSenderNames }}
            </p>
            <el-divider></el-divider>
          </div>
          <div v-if="[0, 14].includes(selectedRate.type) && selectedRateThresholds && selectedRateThresholds.length">
            <h4 style="margin-top: 60px">Стоимость по регионам</h4>
            <el-table
                :data="selectedRateThresholds"
                style="width: 100%"
            >
              <el-table-column prop="oper_group_name" min-width="180">
                <template #header>
                  <span class="text-bold">Группа операторов</span>
                </template>
              </el-table-column>
              >
              <el-table-column :formatter="bTemplateType" min-width="200" v-if="showTemplateTypeColumn">
                <template #header>
                  <span class="text-bold">Тип трафика</span>
                </template>
              </el-table-column>
              >
              <el-table-column
                  min-width="100"
                  v-for="(threshold, i) in selectedRateThresholds[0].thresholds.length"
                  :key="i+'threshold_col'"
              >
                <template #header>
                  <span
                      class="text-bold">{{
                      Math.max(Number(selectedRateThresholds[0].thresholds[i].threshold), 1)
                    }}+</span>
                </template>
                <template #default="scope">
                  {{ scope.row.thresholds[i].value }}
                </template>
              </el-table-column>

            </el-table>
          </div>
        </el-col>
      </el-row>
      <el-row v-show="selectedRate.type === 1">
        <el-col :span="24">
          <el-divider></el-divider>
          <div v-show="selectedRateInboxes.length">
            <p class="text-bold">Входящие ящики</p>
          </div>
          <div>
            <el-table
                :data="selectedRateInboxes"
                style="width: 100%"
            >
              <el-table-column prop="phone" label="Номер"></el-table-column>
              <el-table-column prop="prefix" label="Префикс"></el-table-column>


            </el-table>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>


import moment from "moment"


export default {
  name: "ClientRateDetails",
  props: ["selectedRate", "inboxes", "senders", "visible"],
  methods: {
    bTemplateType(row) {
      return TEMPLATE_TRAFFIC_TYPES[row.template_type]
    },
  },
  watch:{
    visible(v){
      this.intVisible = v
    }
  },
  computed: {

    selectedRateType() {
      return this.selectedRate.type === 14 ? CALL_RATE_SUBTYPE_NAMES[this.selectedRate.subtype] : RATE_SUBTYPE_NAMES[this.selectedRate.subtype]
    },
    showTemplateTypeColumn() {
      return this.selectedRate.subtype === 7
    },
    selectedRateRegisteredSenderNames() {
      return this.senders.filter(s => this.selectedRate.sender_names.includes(s._id)).map(s => s.name).join(", ")
    },
    selectedRateRegisteredSenderNamesAmount() {
      return this.selectedRateRegisteredSenderNames.split(",").length
    },
    selectedRateFee(){
      return Math.max((this.selectedRateRegisteredSenderNamesAmount - (Number(this.selectedRate.free_names_amount) || 0)), 0) * (Number(this.selectedRate.one_name_price) || 0)
    },
    selectedRateThresholds() {
      let thres = this.selectedRate.thresholds.filter(t => !t.isZone).sort((v1, v2) => v1.oper_group_name.split("-").pop().localeCompare(v2.oper_group_name.split("-").pop(), "ru"))
      thres.push(...this.selectedRate.thresholds.filter(t => t.isZone))
      let retval = Object.values(thres.reduce((acc, t) => {
        let key = t.oper_group_id
        if (this.selectedRate.subtype === 7) key = t.template_type + t.oper_group_id
        if (!acc[key]) acc[key] = {
          thresholds: [],
          oper_group_id: t.oper_group_id,
          template_type: t.template_type,
          oper_group_name: t.oper_group_name
        }
        acc[key].thresholds.push(t)
        acc[key].thresholds = acc[key].thresholds.sort((t1, t2) => Number(t1.threshold) - Number(t2.threshold))
        return acc
      }, {}))
      if (this.selectedRate.subtype === 7 && retval.length) {
        let noTemplateRowIndex = retval.findIndex(r => r.template_type === "no_template")
        retval.push(retval.splice(noTemplateRowIndex, 1)[0]);
      }
      return retval
    },
    selectedRateInboxes() {
      if (String(this.selectedRate.subtype) === "0") return this.inboxes.filter(i => i.type === "common")
      if (String(this.selectedRate.subtype) === "1") return this.inboxes.filter(i => i.rate_id === this.selectedRate._id)
      return []
    },
    callsType(){
      return CALL_TYPES_BY_INDEX[this.selectedRate.call_rate_type]
    },
    callsSegmentationType(){
      return CALLS_SEGMENTATION_TYPE[this.selectedRate.segmentation]
    },
    callsSegmentationUnit(){
      return CALLS_SEGMENTATION_UNITS[this.selectedRate.segmentation_unit]
    }
  },
  data() {
    return {
      intVisible: false
    }
  }
}

</script>

<style>

</style>