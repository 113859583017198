<template>

  <el-card>
    <tg-bot/>
  </el-card>

</template>


<script>


import SupportView from "@shared/views/support/index.view.vue";
import DialogArchetypeSelect from "@shared/components/dialogs/archetype-select.component.vue";
import ManageTicket from "@shared/components/support/manage-ticket.component.vue";
import TgBot from "@shared/components/lk/tgBot.component.vue";

export default {
  name: 'AdminTgBot',
  title: "Админ. Телеграм-бот",
  components: {TgBot},
  props: [],
  watch: {

  },
  mounted(){

  },
  methods: {

  },
  computed: {},
  data() {
    return {

    }
  }
}


</script>

<style>

</style>