<template>
  <div>

    <el-row :gutter="20">
      <el-col :span="24">
        <el-tabs v-model="visiblePanel" class="primary-heading-tabs">
          <el-tab-pane label="Группы" name="groups">
            <TableSpinner :loading="loading">
              <groups-table-and-form :operators="operators" :groups="groups"
                                        @reload="e => refresh(e)"
              />
            </TableSpinner>
          </el-tab-pane>
          <el-tab-pane label="Операторы" name="operators">

            <TableSpinner :loading="loading">
             <operators-table-and-form :operators="operators" :groups="groups"
                @reload="refresh()"
             />
            </TableSpinner>
          </el-tab-pane>

        </el-tabs>

      </el-col>
    </el-row>
  </div>
</template>
<script>


import TimeSearch from "@shared/components/reports/time-search.component"
import moment from "moment"
import OperatorsTableAndForm from "../../components/routing/operators/operatorsTableAndForm.component.vue";
import GroupsTableAndForm from "../../components/routing/operators/groupsTableAndForm.component.vue";

export default {
  name: "Operators",
  title: "Операторы",
  components: {
    GroupsTableAndForm,
    OperatorsTableAndForm,
    TimeSearch
  },
  methods: {
    async getOperators() {
      let resp = await this.axios.get("/resources/operators")
      this.operators = resp.data.sort((g1, g2) => g1.OrgName.localeCompare(g2.OrgName))
    },
    async getGroups() {
      let resp = await this.axios.get("/admin/routing/operatorGroups")
      this.groups = resp.data.filter(g => g.title.startsWith("РФ")).sort((g1, g2) => g1.title.localeCompare(g2.title)).concat(
          resp.data.filter(g => !g.title.startsWith("РФ")).sort((g1, g2) => g1.title.localeCompare(g2.title))
      )

    },
    refresh(noLoad=false) {
      if(!noLoad) this.loading = true
      Promise.all([
        this.getOperators(),
        this.getGroups()
      ]).then(() => {
        if(!noLoad) this.loading = false
      })
    },
    operGroupName(operator){
      let g = this.groups.find(g => g._id === operator.group)
      return g ? g.title : "N/A"
    },
    removeOperator(operator){

    },

  },
  computed: {

  },
  mounted() {
    this.refresh()
  },
  data() {
    return {
      loading: false,
      operators: [],
      groups: [],
      visiblePanel: "groups",

    }
  }
}

</script>