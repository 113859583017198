<template>
  <div>
    <el-dialog
        v-model="addFormVisible"
        title="Новая нумерация"
        class="modal-95-70-60"
    >

      <el-form label-position="top" ref="numberingForm" :rules="formRules" :model="numberingForm">
        <el-form-item label="Юр. Лицо" prop="legalEntity">
          <el-select v-model="numberingForm.legalEntity">
            <el-option v-for="e in settings.legalEntities" :value="e._id" :label="e.company_name"/>
          </el-select>
        </el-form-item>
        <el-form-item label="Стартовый номер" prop="startNumber">
          <el-input v-model="numberingForm.startNumber"/>
        </el-form-item>
        <el-form-item label="Дата начала периода" prop="activationDate">
          <el-date-picker
              v-model="numberingForm.activationDate"
              type="date"
              format="DD.MM.YYYY"
              style="width:100%"
              clearable
          >
          </el-date-picker>
        </el-form-item>
      </el-form>

      <template #footer>
      <span class="dialog-footer">
        <el-button @click="addFormVisible = false">Закрыть</el-button>
        <el-button type="success" @click="saveNumbering" :loading="saveLoading">Сохранить</el-button>
      </span>
      </template>
    </el-dialog>

    <el-row :gutter="20">
      <el-col :span="24">
        <TableSpinner :loading="loading">
          <el-form inline size="small">
            <el-form-item label="Юр. Лицо">
              <el-select v-model="numberingFilterLegalEntity">
                <el-option value="" label="Все" />
                <el-option v-for="e in settings.legalEntities" :value="e._id" :label="e.company_name"/>
              </el-select>
            </el-form-item>
          </el-form>
          <el-table
              :data="visibleNumberings" style="width: 100%;"
          >
            <el-table-column :formatter="legalEntityName" label="Стартовая нумерация" min-width="200"/>
            <el-table-column prop="number" label="Стартовая нумерация" min-width="200"/>
            <el-table-column :formatter="row => formatDate(row.date_from)" label="Дата активации" min-width="150"/>
            <el-table-column min-width="100">
              <template #default="scope">
                <RemoveButton :loading="removing" :table-button="false" @confirm="removeNumbering(scope.row)">Удалить
                </RemoveButton>
              </template>
            </el-table-column>
          </el-table>
          <el-button type="success" @click="showAddForm()" style="margin-top: 40px">
            <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp;Добавить нумерацию
          </el-button>
        </TableSpinner>

      </el-col>
    </el-row>
  </div>
</template>

<script>

import RemoveButton from "../../../../shared/components/layout/removeButton.component.vue";

export default {
  name: "FinanceActsNumbering",
  props: ["settings", "loading"],
  components: {RemoveButton},
  methods: {
    async showAddForm() {
      this.addFormVisible = true
      await this.$nextTick()
      this.$refs.numberingForm.resetFields()

    },
    saveNumbering() {
      if(!this.numberingForm.legalEntity || !this.numberingForm.startNumber || !this.numberingForm.activationDate)
        return this.$gNotify("Заполните все поля", "error")

      this.saveLoading = true
      this.axios.post("/admin/finance/saveActsNumbering", {numbering: this.numberingForm})
          .then(resp => {
            this.saveLoading = false
            this.$refs.numberingForm.resetFields()
            this.addFormVisible = false
            this.$emit('reload')
          })
    },
    removeNumbering(numbering) {
      this.removing = true
      this.axios.delete("/admin/finance/removeActsNumbering", {params: {numberingId: numbering._id}})
          .then(resp => {
            this.removing = false
            this.$emit('reload')
          })
    },
    legalEntityName(numbering){
      if(!numbering.legalEntity) return ""
      let le = this.settings.legalEntities.find(l => l._id === numbering.legalEntity)
      return le.company_name
    },
  },
  computed: {
    allNumberings() {
      return (this.settings.acts_numeration || []).sort((n1, n2) => new Date(n2.date_from).valueOf() - new Date(n1.date_from).valueOf())
    },
    visibleNumberings(){
      return this.allNumberings.filter(n => {
        if(!this.numberingFilterLegalEntity) return n
        return n.legalEntity === this.numberingFilterLegalEntity
      })
    }
  },
  data() {
    return {
      addFormVisible: false,
      saveLoading: false,
      removing: false,
      numberingForm: {
        legalEntity: "",
        startNumber: "",
        activationDate: ""
      },
      entityForm: {
        _id: ""
      },
      formRules: {},
      numberingFilterLegalEntity: ""
    }
  },
}

</script>