<template>


  <user-card-component
      :system-id="systemId"
      :info-in="clientInfo"
      :embedded="true"
      :editing="editMode"
      ref="userCardComponent"
  >
    <template v-slot:admin-edit-info>
      <el-button type="primary" size="small" @click="editMode = true" v-show="!editMode">Редактировать</el-button>
      <div style="display: flex;justify-content: space-between" v-show="editMode">
        <el-button type="success"  size="small" @click="saveUserInfo" :loading="saving">Сохранить</el-button>
        <el-button type="warning"  size="small" @click="editMode = false" :loading="saving">Отмена</el-button>
      </div>
      <el-divider />
    </template>
    <template v-slot:admin-settings>
      <h3>Административная настройка
        <el-button
            text
            class="text-button no-left-padding-small-screen" size="small" plain @click="adminSettingsEditing = true"
            v-show="!adminSettingsEditing"
        >
          <font-awesome-icon icon="pencil-alt" class="el-icon--left"></font-awesome-icon>
          Редактировать
        </el-button>

        <div v-show="adminSettingsEditing" style="display: inline">
          <el-button
              text
              type="success" class="text-button no-left-padding-small-screen" size="small" plain
              :loading="saving"
              @click="saveAdminSettings"
          >
            <font-awesome-icon icon="check" class="el-icon--left"></font-awesome-icon>
            Сохранить
          </el-button>
          <el-button
              text
              type="danger" class="text-button" size="small" plain @click="adminSettingsEditing = false"
              :loading="saving"
          >
            <font-awesome-icon icon="times" class="el-icon--left"></font-awesome-icon>
            Отмена
          </el-button>
        </div>
      </h3>
      <div class="el-table--fit el-table--border el-table--scrollable-x el-table--enable-row-hover el-table">
        <div class="el-table__body-wrapper is-scrolling-left">
          <table class="el-table__body" cellspacing="0" cellpadding="0" border="0" style="width: 100%">
            <tbody>
            <tr class="el-table__row">
              <td class=" el-table__cell  text-bold">Ответственный менеджер</td>
              <td class=" el-table__cell " v-show="!adminSettingsEditing">
                {{ responsibleManagerStr }}
              </td>
              <td class=" el-table__cell " v-show="adminSettingsEditing">
                <el-select v-model="responsibleManager" class="full-width">
                  <el-option
                      v-for="(m, k) in managers" :key="k" :label="formatManager(m)" :value="m.system_id"/>
                </el-select>
              </td>
            </tr>

            <tr class="el-table__row">
              <td class=" el-table__cell  text-bold">Код привлекателя</td>
              <td class=" el-table__cell " v-show="!adminSettingsEditing">
                {{ agentStr }}
              </td>
              <td class=" el-table__cell " v-show="adminSettingsEditing">
                <el-input v-model="agentCode" class="full-width" />

              </td>
            </tr>

            <tr class="el-table__row">
              <td class=" el-table__cell  text-bold">Компания для счетов</td>
              <td class=" el-table__cell " v-show="!adminSettingsEditing">
                {{ contractorCompanyStr }}
              </td>
              <td class=" el-table__cell " v-show="adminSettingsEditing">
                <el-select v-model="contractorCompany" class="full-width">
                  <el-option
                      v-for="(m, k) in financeLegalEntities" :key="k" :label="formatLegalEntity(m)" :value="m._id"/>
                </el-select>
              </td>
            </tr>
            <tr class="el-table__row">
              <td class=" el-table__cell  text-bold"> Служебная информация</td>
              <td class=" el-table__cell " v-show="!adminSettingsEditing">
                {{ adminNoticeStr }}
              </td>
              <td class=" el-table__cell " v-show="adminSettingsEditing">
                <el-input type="textarea" :rows="3" v-model="adminNotice"/>
              </td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>
      <el-divider/>
      <div style="display: flex; justify-content: space-between">
        <div>
          <remove-button
              type="danger" :regular="true"
              v-if="!clientBlock.userBlocked"
              :loading="saving"
              confirm-message="Подтвердите блокировку пользователя"
              @confirm="toggleClientBlock(true)"
          >
            Заблокировать клиента
          </remove-button>
          <remove-button
              type="warning" :regular="true"
              v-if="clientBlock.userBlocked"
              :loading="saving"
              @confirm="toggleClientBlock(false)"

              confirm-message="Подтвердите разблокировку пользователя"
          >
            Разблокировать клиента
          </remove-button>
        </div>

        <div>
          <remove-button
              type="danger" :regular="true"
              v-if="!clientBlock.balanceBlocked"
              :loading="saving"
              confirm-message="Подтвердите блокировку счёта"
              @confirm="toggleBalanceBlock(true)"
          >
            Заблокировать счёт
          </remove-button>


          <remove-button
              type="warning" :regular="true"
              v-if="clientBlock.balanceBlocked"
              :loading="saving"
              confirm-message="Подтвердите разблокировку счёта"
              @confirm="toggleBalanceBlock(false)"
          >
            Разблокировать счёт
          </remove-button>
        </div>
      </div>
    </template>
  </user-card-component>


</template>


<script>

import TableSpinner from "@shared/components/layout/table-spinner.component.vue";
import UserCardComponent from "@shared/components/lk/user-card.component.vue";
import RemoveButton from "@shared/components/layout/removeButton.component.vue";

export default {
  name: 'UserCardConfig',
  components: {RemoveButton, UserCardComponent, TableSpinner},
  props: ["clientData", "systemId", "managers", "financeLegalEntities"],
  watch: {
    clientData(val) {
      this.responsibleManager = this.clientData.responsible_manager
      this.adminNotice = this.adminNoticeStr
      this.contractorCompany = this.currentContractor ? this.currentContractor._id : ""
      this.editMode=false
      this.saving = false
    }
  },
  methods: {
    saveAdminSettings() {
      this.saving = true
      this.axios.post("/admin/clients/setAdminSettings", {
        systemId: this.systemId,
        notice: this.adminNotice,
        responsibleManager: this.responsibleManager,
        contractorId: this.contractorCompany,
        agentCode: this.agentCode
      })
          .then(resp => {
            this.saving = false
            this.adminSettingsEditing = false
            this.$emit('reloadUser')
          })
    },
    formatManager(mgr) {
      return `${mgr.system_id} ${mgr.info.lastname} ${mgr.info.firstname} ${mgr.info.patronymic}`
    },
    formatLegalEntity(le) {
      return `${le.company_name} (${le.inn})`
    },
    toggleClientBlock(onOff) {

      this.saving = true
      this.axios.post("/admin/clients/toggleClientBlock", {
        systemId: this.systemId,
        onOff
      })
          .then(resp => {
            this.saving = false
            this.$emit('reloadUser')
          })

    },
    toggleBalanceBlock(onOff) {
      this.saving = true
      this.axios.post("/admin/clients/toggleBalanceBlock", {
        systemId: this.systemId,
        onOff
      })
          .then(resp => {
            this.saving = false
            this.$emit('reloadUser')
          })

    },
    saveUserInfo(onOff) {
      let newInfo = this.$refs.userCardComponent.getEditingInfo()
      this.saving = true
      this.axios.post("/admin/clients/updateBaseInfo", {
        systemId: this.systemId,
        newInfo
      })
          .then(resp => {
            this.$emit('reloadUser')
          })

    }

  },
  computed: {
    clientBlock() {
      return this.clientData.block || {}
    },
    clientInfo() {
      return this.clientData || null
    },
    adminNoticeStr() {
      return !this.clientData ? "" : this.clientData.adminNotice || ""
    },
    currentContractor() {
      if (!this.clientData || !this.financeLegalEntities) return null
      let contractor = this.financeLegalEntities.find(l => l._id === this.clientData.contractorCompany)
      if (!contractor)
        contractor = this.financeLegalEntities.find(l => l.primary)
      return contractor
    },
    contractorCompanyStr() {
      if (!this.currentContractor) return ""
      return this.formatLegalEntity(this.currentContractor)
    },
    responsibleManagerStr() {
      if (!this.clientData || !this.managers) return "N/A"
      let mgr = this.managers.find(m => m.system_id === this.clientData.responsible_manager)
      if (!mgr) return this.clientData.responsible_manager + " (Такой менеджер не найден)"
      return this.formatManager(mgr)
    },
    agentStr(){
      return this.clientData && this.clientData.agent_code ? this.clientData.agent_code : "Не указан"
    }
  },
  data() {
    return {
      adminSettingsEditing: false,
      responsibleManager: "",
      agentCode: "",
      contractorCompany: "",
      adminNotice: "",
      saving: false,
      editMode: false
    }
  }
}

</script>

<style>

</style>