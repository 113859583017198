<template>
  <div>
    <agregator-pool-form
        :visible="addEditPoolFormVisible"
        :editing-pool="editingPool"
        :agregators="agregators"
        @close="addEditPoolFormVisible = false"
        @reload="$emit('reload')"
    ></agregator-pool-form>
    <el-row>
      <el-col :span="24">
        <el-table :data="pools">
          <el-table-column label="Название" prop="name" min-width="140"/>
          <el-table-column label="Агрегаторы"  min-width="140">
            <template #default="scope">
              <span v-for="a in scope.row.agregators || []">{{agregatorName(a.agregator_id)}}<br/></span>
            </template>
          </el-table-column>
          <el-table-column label="">
            <template #default="scope">
              <ActionsDropdown>
                <ActionButton icon="pencil-alt" @click="onEdit(scope.row)">Изменить</ActionButton>
                <ActionButton type="remove" @confirm="removeOne(scope.row)" :loading="removingOne">Удалить
                </ActionButton>
              </ActionsDropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-button type="success" style="margin-top: 40px" @click="onAdd">Добавить</el-button>
  </div>
</template>

<script>


import AgregatorPoolForm from "./agregator-pool-form.component.vue";

export default {
  name: "AgregatorsPoolsTable",
  props: ['pools', 'agregators'],
  components: {AgregatorPoolForm},
  methods: {
    agregatorName(aId){
      return (this.agregators.find(a => a._id === aId) || {name: "N/A"}).name
    },
    onEdit(pool){
      console.log(pool)
      this.editingPool = pool
      this.addEditPoolFormVisible = true
    },
    onAdd(){
      this.editingPool = {}
      this.addEditPoolFormVisible = true
    },
    removeOne(pool){
      this.removingOne = true
      this.axios.delete("/admin/routing/removePool", {params: {poolId: pool._id}})
          .then(resp => {
            this.removingOne = false
            this.$emit('reload')
          })
          .catch()
    }
  },
  computed: {},
  data() {
    return {
      removingOne: false,
      addEditPoolFormVisible: false,
      editingPool: {}
    }
  },
}

</script>