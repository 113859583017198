<template>
  <el-dialog v-model="agentNetModalVisible"
             :title="'Реферальная сеть агента ' + showingAgent.system_id"
             class="modal-95-70-60"
             @close="agentNetModalVisible = false"
  >
    <el-table :data="showingAgent.net">
      <el-table-column label="Контрагент" prop="system_id" min-width="100"/>
      <el-table-column label="Ф.И.О." prop="sid" min-width="100">
        <template #default="c">
          {{`${c.row.info.user_type === 'physic' ? `${c.row.info.lastname} ${c.row.info.firstname} ${c.row.info.patronymic}` : `${c.row.info.legal_form || ""} ${c.row.info.company_name}`}`}}
        </template>
      </el-table-column>
      <el-table-column label="Телефон" prop="info.phone" min-width="100"/>
      <el-table-column label="E-mail" prop="info.email" min-width="100"/>
    </el-table>
  </el-dialog>

  <el-dialog v-model="addAgentFormVisible"
             title="Добавить агента"
             class="modal-95-70-60"
             @close="addAgentFormVisible = false"
  >

    <el-form label-position="top">
      <el-row>
        <el-col :xs="24" :md="24">
          <el-form-item label="">
            <el-select
                v-model="toBeAddedAgentId"
                filterable
                remote
                placeholder="Введите идентификатор, имя или название компании"
                :remote-method="getNewAgentOptions"
                :loading="agentOptionsLoading"
                class="full-width"
            >
              <el-option
                  v-for="item in newAgentOptions"
                  :disabled="item.disabled"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-button type="success" @click="addAgent" :loading="adding">Назначить агентом</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>

  <div>
    <!--
    <el-row style="margin-bottom: 20px">
      <el-col :span="24">
        <el-card class="box-card no-top-body-padding no-border">
          <el-form label-position="top" ref="searchForm" :model="search" size="small">
            <el-row :gutter="40">
              <el-col :xs="24" :md="8">
                <el-form-item label="Название" prop="token">
                  <el-input class="full-width" style="margin-bottom: 20px" placeholder="" clearable
                            v-model="search.token"/>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :md="8">
                <el-form-item label="Тип">
                  <el-select v-model="search.type" class="full-width" prop="type">
                    <el-option value="" label="Все"/>
                    <el-option value="0" label="Исходящие"/>
                    <el-option value="14" label="Звонки"/>
                    <el-option value="1" label="Входящие"/>
                    <el-option value="3" label="Оплата банковским переводом"/>
                    <el-option value="6" label="Робокасса"/>
                    <el-option value="8" label="Использование овердрафта"/>
                    <el-option value="13" label="Разовое списание"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :md="8">
                <el-form-item label="Активность" prop="isActive">
                  <el-select v-model="search.isActive" class="full-width">
                    <el-option value="" label="Все"/>
                    <el-option value="active" label="Активные"/>
                    <el-option-group label="Не активные">
                      <el-option value="all_inactive" label="Все неактивные"/>
                      <el-option value="delayed" label="Отложенная активация"/>
                      <el-option value="locked" label="Блокированые"/>
                      <el-option value="archived" label="Архив"/>
                    </el-option-group>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-button type="danger" @click="resetSearchForm">Сбросить</el-button>
        </el-card>
      </el-col>
    </el-row>
  -->


    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card">
          <el-button type="success" style="margin-bottom: 40px" @click="addAgentFormVisible = true">Добавить агента
          </el-button>
          <TableSpinner :loading="loading">
            <el-table :data="allAgents">
              <el-table-column label="Пользователь" min-width="180" prop="name">
                <template #default="scope">
                  <span style="color: var(--el-text-color-secondary)">{{ scope.row.system_id }}</span>
                  <br/>
                  {{ scope.row.full_agent_name }}
                </template>
              </el-table-column>
              <el-table-column label="Дата регистрации" prop="registrationDate" min-width="100"/>
              <el-table-column label="Размер сети" min-width="100">
                <template #default="scope">
                  <el-skeleton style="width: 100px" :loading="true" animated v-show="moneyLoading">
                    <template #template>
                      <el-skeleton-item variant="text"/>
                    </template>
                  </el-skeleton>
                  <span v-show="!moneyLoading" class="table-text-button blue-button" style="cursor: pointer"
                        @click="showAgentNet(scope.row)">
                    {{ scope.row.net.length }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="Код" prop="code" min-width="100"/>
              <el-table-column label="Продажи за пр. месяц" min-width="180">
                <template #default="scope">
                  <el-skeleton style="width: 100px" :loading="true" animated v-show="moneyLoading">
                    <template #template>
                      <el-skeleton-item variant="text"/>
                    </template>
                  </el-skeleton>
                  <span v-show="!moneyLoading">
                    {{ formatNumber(scope.row.spending, '.', 4) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="Действие" min-width="300">
                <template #default="scope">
                  <ActionsDropdown>
                    <!--                    <ActionButton icon="pencil-alt" @click="editAgent(scope.row)">Изменить</ActionButton>-->
                    <ActionButton type="remove" @confirm="removeAgent(scope.row)">Удалить</ActionButton>
                  </ActionsDropdown>

                </template>
              </el-table-column>
            </el-table>
          </TableSpinner>


        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import moment from "moment";
import RemoveButton from "@shared/components/layout/removeButton.component.vue";
import ActionsDropdown from "@shared/components/layout/actionsDropdown.component.vue";
import RateForm from "@/components/rating/rate-form.component.vue";
import ClientSelect from "@/components/layout/client-select.component.vue";
import TableSpinner from "@shared/components/layout/table-spinner.component.vue";

export default {
  name: "AgencyNet",
  title: "Агентская сеть",
  components: {
    TableSpinner,
    ClientSelect,
    RateForm,
    ActionsDropdown,
    RemoveButton,
  },
  watch: {},
  methods: {
    async getAllAgents() {
      this.loading = true
      let resp = await this.axios.get("/admin/agents/getAll")
      this.allAgents = resp.data.map(r => {
        return {
          system_id: r.system_id,
          full_agent_name: `${r.info.user_type === 'physic' ? `${r.info.lastname} ${r.info.firstname} ${r.info.patronymic}` : `${r.info.legal_form || ""} ${r.info.company_name}`}`,
          registrationDate: moment(r.agentData.registeredAt).format("DD.MM.YYYY"),
          code: r.agentData.code,
          net: [],
          spending: 0
        }
      }).sort((r1, r2) => Number(r1.agent_system_id) - Number(r2.agent_system_id))
      this.loading = false
    },
    async loadAgentsMoney() {
      this.moneyLoading = true
      let resp = await this.axios.get("/admin/agents/getFinance")
      for (let agentData of resp.data) {
        let index = this.allAgents.findIndex(a => a.system_id === agentData.agentSystemId)
        if (index < 0) continue
        this.allAgents[index].net = agentData.referrals
        this.allAgents[index].spending = agentData.totalSpending
      }
      this.moneyLoading = false
    },
    refresh() {
      this.getAllAgents().then(() => {
        this.loadAgentsMoney()
      })

    },
    addAgent() {
      this.adding = true
      this.axios.post("/admin/agents/create", {systemId: this.toBeAddedAgentId})
          .then(() => {
            this.adding = false
            this.toBeAddedAgentId = ""
            this.addAgentFormVisible = false
            this.refresh()
          })
          .catch(e => {
            this.$gNotify("Что-то пошло не так. Попробуйте чуть позже или обратитесь к программисту", "error")
            this.adding = false
          })
    },
    editAgent(agent) {
      this.editingAgent = agent
      this.editAgentFormVisible = true
    },
    removeAgent(agent) {
      this.axios.delete("/admin/agents/removeOne", {params: {systemId: agent.system_id}})
          .then(resp => {
            this.refresh()
          })
    },


    getNewAgentOptions(q) {
      if (!q) {
        this.newAgentOptions = []
        return
      }
      this.agentOptionsLoading = true
      this.axios.get("/admin/clients/getFullyActiveByToken", {params: {token: q}})
          .then(resp => {
            this.newAgentOptions = resp.data.result.map(r => {
              return {
                label: `${r.system_id} | ${r.info.user_type === 'physic' ? `${r.info.lastname} ${r.info.firstname} ${r.info.patronymic}` : `${r.info.legal_form || ""} ${r.info.company_name}`}`,
                value: r.system_id,
                disabled: r.agentData && r.agentData.registered
              }
            })
            this.agentOptionsLoading = false
          })
          .catch(console.error)
    },

    showAgentNet(agent){
      this.agentNetModalVisible = true
      this.showingAgent = agent
    }

  },

  computed: {},
  mounted() {
    this.refresh()
  },
  data() {
    return {
      loading: false,
      moneyLoading: true,
      adding: false,
      allAgents: [],
      addAgentFormVisible: false,
      editAgentFormVisible: false,
      editingAgent: {},
      toBeAddedAgentId: "",
      newAgentOptions: [],
      agentOptionsLoading: false,


      agentNetModalVisible: false,
      showingAgent: {

      }
    }
  },
}

</script>