import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_remove_button = _resolveComponent("remove-button")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_TableSpinner = _resolveComponent("TableSpinner")!

  return (_openBlock(), _createBlock(_component_TableSpinner, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      (!_ctx.showActions)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            text: "",
            class: "text-button",
            onClick: _ctx.exportTable,
            style: {"margin-bottom":"15px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_font_awesome_icon, { icon: "external-link-alt" }),
              _createTextVNode(" Экспортировать ")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_el_table, { data: _ctx.individualRules }, {
        default: _withCtx(() => [
          (_ctx.showActions)
            ? (_openBlock(), _createBlock(_component_el_table_column, {
                key: 0,
                prop: "forUser",
                label: "Пользователь"
              }))
            : (_openBlock(), _createBlock(_component_el_table_column, {
                key: 1,
                label: "Пользователь"
              }, {
                default: _withCtx((scope) => [
                  _createVNode(_component_el_button, {
                    text: "",
                    class: "table-text-button",
                    onClick: ($event: any) => (_ctx.$emit('onEdit', scope.row))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(scope.row.forUser), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 1
              })),
          _createVNode(_component_el_table_column, {
            prop: "vis.sender",
            label: "Отправитель"
          }),
          _createVNode(_component_el_table_column, {
            prop: "vis.target",
            label: "Подмена на"
          }),
          _createVNode(_component_el_table_column, {
            prop: "vis.oper",
            label: "Группа операторов"
          }),
          _createVNode(_component_el_table_column, {
            formatter: r => r.affects_rating === '0' ? 'Нет' : 'Да',
            label: "Влияет на тарификацию"
          }, null, 8, ["formatter"]),
          (_ctx.showActions)
            ? (_openBlock(), _createBlock(_component_el_table_column, {
                key: 2,
                label: ""
              }, {
                default: _withCtx((scope) => [
                  _createVNode(_component_el_button, {
                    size: "small",
                    text: "",
                    class: "text-button table-text-button",
                    onClick: ($event: any) => (_ctx.$emit('onEdit', scope.row)),
                    style: {"margin-right":"10px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_font_awesome_icon, { icon: "pencil-alt" }),
                      _createTextVNode("  Изменить ")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]),
                  _createVNode(_component_remove_button, {
                    onConfirm: ($event: any) => (_ctx.$emit('onDelete', scope.row))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Удалить")
                    ]),
                    _: 2
                  }, 1032, ["onConfirm"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["data"])
    ]),
    _: 1
  }, 8, ["loading"]))
}