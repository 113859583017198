

import {defineComponent} from "vue";

import ServiceTemplatesView from "@shared/views/rating/service-templates.view.vue";


export default defineComponent({
  name: "AdminServiceTemplatesView",
  components: {
    ServiceTemplatesView
  },

  data() {
    return {}
  }
})

