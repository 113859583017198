<template>

  <div class="main-dialog-container">
    <div v-if="$slots['archetype-selector']">
      <slot
          name="archetype-selector"
      />
    </div>


    <div class="main-dialog-container-2">

      <div class="dialog-select-container">
        <div :class="`dialog-select-actual ${actualDialogSelectExpanded ? 'expanded' : ''}`">
          <div v-if="$slots['connection-selector']">
            <slot
                name="connection-selector"
            />
          </div>

          <slot
              name="dialog-selector"
              :onDialogSelected="hideDialogSelectMenu"
          />

        </div>

        <!-- Mobile view operators -->
        <div class="overlay" v-show="actualDialogSelectExpanded" @click="hideDialogSelectMenu()"></div>
        <div class="dialog-select-thumb" @click="toggleDialogSelectMenu()">
          <font-awesome-icon icon="chevron-right"/>
        </div>

      </div>


      <div class="dialog-feed-container">
        <div v-if="$slots['subscriber-card']">
          <slot
              name="subscriber-card"
          />
        </div>

        <slot
            name="message-feed"
        />

      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "DialogsScaffold",
  props: [],
  components: {},

  methods: {
    hideDialogSelectMenu() {
      this.actualDialogSelectExpanded = false
    },
    toggleDialogSelectMenu() {
      this.actualDialogSelectExpanded = !this.actualDialogSelectExpanded
    }
  },

  data() {
    return {
      actualDialogSelectExpanded: false,
    }
  }
}

</script>
