<template>
  <div>

    <el-table
        :data="blockedRoutes"
        style="width: 100%; margin-top: 20px"
    >
      <el-table-column label="Направление" min-width="200">
        <template #default="scope">
          <span v-html="blockedRouteOperatorGroups(scope.row)"></span>
        </template>
      </el-table-column>
      <el-table-column label="Подписи" min-width="200">
        <template #default="scope">
          <span v-html="(scope.row.sender || []).join('<br/>')"></span>
        </template>
      </el-table-column>
      <el-table-column
          :formatter="row => row.type === '0' ? 'Блокируется трафик с данными подписями' : 'Блокируется весь трафик кроме данных подписей' "
          label="Тип блокировки" min-width="200"/>
      <slot
          name="admin-actions"
      ></slot>
    </el-table>

  </div>
</template>


<script>

export default {
  name: "BlockedRoutesTable",
  props: ["blockedRoutes"],
  methods: {
    blockedRouteOperatorGroups(row) {
      return (row.operator_group || []).map(gid =>
          (this.$store.getters.operatorsGroups.find(g => String(g._id) === gid) || {title: ""}).title
      ).join("<br/>")
    }
  },
  data() {
    return {}
  }
}

</script>
