module.exports = {
  install(app, options) {
    app.config.globalProperties.$gNotify = (title, type, message) => {
      app.config.globalProperties.$notify({
        type,
        customClass: `tech-notification tech-notification-${type}`,
        title,
        message,
        // duration: 30000
      })
    }
  }
}