<template>
  <sender-names-manage-modal
      ref="manageModal"
      @refresh="$emit('refresh')"
  />
  <el-button type="success" @click="showAddNameDialog()">Добавить имя</el-button>
  <client-sender-names-table
      ref="allNamesTable"
      :senders="senders"
      :is-admin-view="true"
      @refresh="$emit('refresh')"
      @onRemove="removeName"
      @onSetDefault="setDefaultName"
      @onManage="manageName"
  />
</template>

<script>

import ClientSenderNamesTable from "@shared/components/lk/client-sender-names-table.component.vue";
import SenderNamesManageModal from "@/components/support/sender-name-manage-modal.component.vue";
import {ElMessageBox} from "element-plus";
import verifySenderNameUsageMixin from "@shared/mixins/verify-sender-name-usage.mixin";


export default {
  name: "ClientSenderNamesView",
  props: [
    "systemId",
    "senders"
  ],
  mixins: [verifySenderNameUsageMixin],
  components: {
    SenderNamesManageModal,
    ClientSenderNamesTable,
  },
  mounted() {
  },
  methods: {
    manageName(request) {
      this.$refs.manageModal.openEdit(request)
    },
    removeName(nameId){
      this.verifySenderNameUsage(nameId, () => {
        this.axios.delete("/admin/support/removeSenderName", {params: {requestId: nameId}})
            .then(resp => {
              this.$gNotify("Имя удалено", "success")
              this.$emit('refresh')
            })
      })
    },
    setDefaultName(nameId){
      this.axios.post("/admin/support/setUserDefaultSender", {nameId})
          .then(resp => {
            this.$emit("refresh")
          })
    },
    showAddNameDialog(){
      this.$refs.manageModal.openAdd(this.systemId)
    }

  },
  data() {
    return {}
  }
}

</script>

<style>

.sendername-usage-p p > p{
  display:flex;
  justify-content: space-between;
  border-bottom: 1px solid #DDD;
  margin-bottom: 10px;
}

</style>