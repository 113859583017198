<template>
  <el-tabs
      v-model="activeTab"
      :class="`navigation-tabs no-border-tabs auto-width-tabs ${extraSmall ? 'extra-small-tabs' : ''}`"

  >
    <el-tab-pane
        v-for="archetype in archetypes"
        :name="archetype.key"
    >
      <template #label>
        <span class="custom-tabs-label">
          <span>
<!--            <img :src="`@shared/assets/static_img/${group.image}`" v-if="group.image" style="width: 20px"/>-->
            {{ archetype.label }}
          </span>

        </span>
      </template>
    </el-tab-pane>
  </el-tabs>

</template>

<script>

export default {
  name: "DialogArchetypeSelect",
  props: ["archetypes", "modelValue", "extraSmall"],
  emits: ['update:modelValue'],
  components: {},
  watch: {
    activeTab(v){
      this.$emit("update:modelValue", v)
    }
  },
  mounted() {
    if(this.archetypes.length)
      this.activeTab = this.archetypes[0].key
  },
  methods: {
  },
  computed: {},
  data() {
    return {
      activeTab: ""
    }
  }
}

</script>
