import mitt from "mitt";

import TableSpinner from "@shared/components/layout/table-spinner.component.vue"
import TitleMixin from "@shared/components/layout/title.component.vue"
import Breadcrumbs from "@shared/components/layout/breadcrumbs.component.vue"
import ActionsDropdown from "@shared/components/layout/actionsDropdown.component.vue"
import ActionButton from "@shared/components/layout/actionButton.component.vue"


import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/ru'
import moment from "moment"
moment.locale("ru")

let {createApp, h} = require('vue')
let VueAxios = require('vue-axios')
let { FontAwesomeIcon } = require('@fortawesome/vue-fontawesome')

import { library } from '@fortawesome/fontawesome-svg-core'
import {faDownload, faEnvelope, faHome, faChartBar, faEdit, faCreditCard, faLifeRing, faPlus, faTimes, faPencilAlt, faCopy, faCalendarAlt, faInfoCircle, faSignOutAlt, faSpinner, faMinus, faCheck, faExternalLinkAlt, faClone, faPlusCircle, faWrench, faEye, faFile, faRedo, faChevronDown, faChevronRight, faClock, faBan, faCheckCircle, faBars, faTimesCircle, faSlidersH, faSuitcase, faUser, faMobile, faArchive, faStopCircle, faPaperPlane, faSitemap} from '@fortawesome/free-solid-svg-icons'

library.add(faEnvelope, faHome, faChartBar, faEdit, faCreditCard, faLifeRing, faPlus, faTimes, faPencilAlt, faCopy, faCalendarAlt, faInfoCircle, faSignOutAlt, faSpinner, faMinus, faCheck, faExternalLinkAlt, faClone, faPlusCircle, faWrench, faEye, faFile, faRedo, faChevronDown,faChevronRight, faClock, faBan, faCheckCircle, faBars, faTimesCircle, faSlidersH, faSuitcase, faUser, faDownload, faMobile, faArchive, faStopCircle, faPaperPlane, faSitemap)


require('element-plus/dist/index.css')
require('@shared/assets/style/main.css')
require('@shared/assets/style/mobile.css')
require('@shared/assets/style/dialogs.css')
import 'element-plus/theme-chalk/display.css'


let axios = require('@shared/axios')
let store = require('./store')
let router = require('./router')
let customNotify = require('../shared/plugins/notifyWrapper')

require("../shared/globals")

const app = createApp({render: ()=>h(require('./App.vue').default)})

// installElementPlus(app)
app.use(ElementPlus, {locale})
app.mixin(TitleMixin)
app.use(VueAxios, axios)
app.use(customNotify)

const emitter = mitt()


declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    axios: typeof axios,
    $emitter: typeof emitter,
    $gNotify: Function,
    $confirm: Function,
    $store: typeof store,
    formatNumber: (n: string, d?: string, p?: number) => string
    escapeHtml: (n: string) => string
  }
}

app.config.globalProperties.axios=axios
app.config.globalProperties.$emitter = emitter
app.config.globalProperties.formatNumber = (n: string, d?: string, p?: number) => parseFloat(n).toFixed(p || 2).replace(/\./g, !!d ? d : '.')
app.config.globalProperties.formatDate = (n: string) => moment(n).format("DD.MM.YYYY")
app.config.globalProperties.formatDateTime = (n: string) => moment(n).format("DD.MM.YYYY HH:mm")
app.config.globalProperties.$fileToBase64 = (file: any) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
})

app.config.globalProperties.escapeHtml = (v: string) => {
  return (v || "")
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;")
}



store.dispatch("__loadResources")
store.dispatch("connectWs", emitter)

app.use(router)
// global.cypressNavigate = path => router.push({path})
// global.cypressSignOut = () => {store.dispatch("logout"); router.push({path: "/login"})}
app.use(store)

app.component('font-awesome-icon', FontAwesomeIcon)
app.component('TableSpinner', TableSpinner)
app.component('Breadcrumbs', Breadcrumbs)
app.component('ActionsDropdown', ActionsDropdown)
app.component('ActionButton', ActionButton)
app.mount("#app")