<template>
  <div>

  </div>
</template>

<script>


import TimeSearch from "@shared/components/reports/time-search.component"
import moment from "moment"

export default {
  name: "Prefixes",
  title: "Префиксы",
  components: {
    TimeSearch
  },
  methods: {

  },
  computed: {},
  data() {
    return {

    }
  },
}

</script>