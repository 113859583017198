<template>

  <el-tabs v-model="visiblePanel" class="primary-heading-tabs">
    <el-tab-pane label="Системные уведомления" name="addresses">
      <TableSpinner :loading="loading">

        <el-form label-position="top">
          <el-form-item label="Заявка на регистрацию">
            <el-select class="full-width" multiple v-model="emailSettings.registrationRequest">
              <el-option
                  v-for="m in managers"
                  :value="m.system_id"
                  :label="m.fio"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Регистрация имени отправителя">
            <el-select class="full-width" multiple v-model="emailSettings.senderNameRequest">
              <el-option
                  v-for="m in managers"
                  :value="m.system_id"
                  :label="m.fio"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="Тикеты техподдержки">
            <el-select class="full-width" multiple v-model="emailSettings.supportTickets">
              <el-option
                  v-for="m in managers"
                  :value="m.system_id"
                  :label="m.fio"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Все прочие">
            <el-select class="full-width" multiple v-model="emailSettings.all">
              <el-option
                  v-for="m in managers"
                  :value="m.system_id"
                  :label="m.fio"
              />
            </el-select>
          </el-form-item>
          <el-form-item label=" ">
            <el-button type="success" @click="updateSettings" :loading="saving">Сохранить</el-button>
          </el-form-item>
        </el-form>
      </TableSpinner>
    </el-tab-pane>

  </el-tabs>

</template>
<script>


import TableSpinner from "@shared/components/layout/table-spinner.component.vue";

export default {
  name: "SupportSettings",
  title: "Настройки поддержки",
  components: {TableSpinner},
  methods: {
    async loadManagers() {
      let resp = await this.axios.get("/admin/users/getManagers")
      this.managers = resp.data
    },
    async loadSettings() {
      let resp = await this.axios.get("/admin/support/getSettings")
      let settings = resp.data
      for (let [k, v] of Object.entries(settings))
        this.emailSettings[k] = (v || [])
    },
    async refresh() {
      this.loading = true
      await Promise.all([
        this.loadManagers(),
        this.loadSettings()
      ])
      this.loading = false
    },

    updateSettings() {
      this.saving = true
      let settings = {}
      for (let [k, v] of Object.entries(this.emailSettings))
        settings[k] = (v || []).map(v => v.trim()).filter(v => v)

      this.axios.post("/admin/support/updateSettings", {settings})
          .then(resp => {
            this.saving = false
            this.$gNotify("Настройки обновлены", "success")
            this.loadSettings()
          })
    }
  },
  mounted() {
    this.refresh()
  },
  data() {
    return {
      visiblePanel: "addresses",
      loading: false,
      saving: false,
      managers: [],
      emailSettings: {
        "all": [],
        "supportTickets": [],
        "registrationRequest": [],
        "senderNameRequest": []
      }
    }
  }
}

</script>
