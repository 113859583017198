<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-tabs v-model="visiblePanel" class="primary-heading-tabs">
          <el-tab-pane label="Агрегаторы" name="agregators">
            <TableSpinner :loading="loading">
              <agregators-table
                  :agregators="allAgregators"
                  @reload="refresh"
              />
            </TableSpinner>
          </el-tab-pane>
          <el-tab-pane label="Пулы" name="pools">

            <TableSpinner :loading="loading">
              <agregators-pools-table
                  :pools="allAgregatorsPools"
                  :agregators="allAgregators"
                  @reload="refresh"
              />
            </TableSpinner>
          </el-tab-pane>

        </el-tabs>

      </el-col>
    </el-row>
  </div>
</template>

<script>


import AgregatorsTable from "../../components/routing/agregators-table.component.vue";
import AgregatorsPoolsTable from "../../components/routing/agregators-pools-table.component.vue";

export default {
  name: "Agregators",
  title: "Агрегаторы",
  components: {
    AgregatorsPoolsTable,
    AgregatorsTable
  },
  methods: {
    async getAgregators() {
      let resp = await this.axios.get("/admin/routing/allAgregators")
      this.allAgregators = resp.data
    },
    async getPools() {
      let resp = await this.axios.get("/admin/routing/allPools")
      this.allAgregatorsPools = resp.data
    },
    refresh() {
      this.loading = true
      Promise.all([
        this.getAgregators(),
        this.getPools()
      ]).then(() => {
        this.loading = false
      })
    },

  },
  computed: {},
  mounted() {
    this.refresh()
  },
  data() {
    return {
      loading: false,
      allAgregators: [],
      allAgregatorsPools: [],
      visiblePanel: "agregators",

    }
  },
}

</script>