<template>
  <el-dialog
      title="Новый запрос"
      v-model="newTicketModalVisible"
      class="modal-95-70-60"
  >


    <el-form :model="newTicketForm" label-position="top" :rules="rules" :ref="v=> newTicketFormEl = v">
      <el-form-item label="Тема запроса" prop="title">
        <el-input v-model="newTicketForm.title"/>
      </el-form-item>
      <el-form-item label="Текст запроса" prop="ticketText">
        <el-input type="textarea" :rows="5" v-model="newTicketForm.ticketText"/>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="success" @click="startNewDialog()" :loading="creating">Создать</el-button>
      </el-form-item>

    </el-form>
  </el-dialog>

  <el-button @click="showNewTicketForm()" style="width: 100%; border-radius: 0px" type="success">
    <font-awesome-icon icon="plus"/>&nbsp;Новый запрос
  </el-button>
</template>

<script>


export default {
  name: "NewTicketModal",

  methods: {

    showNewTicketForm() {
      this.newTicketForm.title= ""
      this.newTicketForm.ticketText = ""

      this.newTicketModalVisible = true
    },
    startNewDialog() {
      if (this.newTicketFormEl) {
        this.newTicketFormEl.validate().then((v, f) => {

          if (!v) return
          console.log("Valid")
          this.creating = true
          this.axios.post("/support/createRequest", this.newTicketForm)
              .then(resp => {

                let newTicket = {
                  unreadCount: 0,
                  ...resp.data.ticket
                }
                this.$emit('ticketCreated', newTicket)
                this.creating = false
                this.newTicketModalVisible = false

              })
        })

      }

    },

  },

  data() {
    return {
      newTicketModalVisible: false,
      newTicketForm: {
        title: "",
        ticketText: ""
      },
      creating: false,
      newTicketFormEl: null,
      rules: {
        title: [{required: true, trigger: "blur", message: "Заполните тему запроса"}],
        ticketText: [{required: true, trigger: "blur", message: "Заполните текст запроса"}]
      }
    }
  }
}

</script>
