<template>
  <div>
    <int-name-form
        :visible="addEditFormVisible"
        :editing-name="editingName"
        :international-operators="internationalOperators"
        :platform-operators="platformOperators"
        :is-exclusion="true"
        @close="addEditFormVisible = false"
        @reload="$emit('reload')"
    ></int-name-form>
    <el-row>
      <el-col :span="24">
        <el-form label-position="top" size="small">
          <el-row :gutter="40" style="margin-bottom: 20px">
            <el-col :xs="24" :md="8">
              <el-form-item label="Поиск">

                <el-input class="full-width" style="margin-bottom: 20px" placeholder="" clearable
                          v-model="freeSearchToken"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :md="8">
              <el-form-item label="Оператор">
                <el-select v-model="internalOperatorFilter" class="full-width">
                  <el-option value="" label="Все"/>
                  <el-option
                      v-for="operator in internationalOperators"
                      :value="operator._id"
                      :label="platformOperatorName1(operator)"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-table :data="visibleNames">
          <el-table-column label="Подпись" prop="name" min-width="140"/>
          <el-table-column label="Оператор" :formatter="platformOperatorName" min-width="140"/>
          <el-table-column label="Дата добавления" :formatter="row => formatDate(row.dateAdded)" min-width="140"/>
          <el-table-column label="" min-width="100">
            <template #default="scope">
              <ActionsDropdown v-if="!scope.row.external">
                <ActionButton icon="pencil-alt" @click="onEdit(scope.row)">Изменить</ActionButton>
                <ActionButton type="remove" @confirm="removeOne(scope.row)" :loading="removingOne">Удалить
                </ActionButton>
              </ActionsDropdown>
            </template>
          </el-table-column>
        </el-table>
        <div class="solo-pagination-container">
          <el-button type="success" style="margin-top: 40px" @click="onAdd">Добавить</el-button>
          <el-pagination
              background
              class="wide-page-size"
              layout="sizes, prev, pager, next"
              :total="filteredNames.length"
              :pager-count="3"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageSize"
              :hide-on-single-page="true"
              @size-change="handlePageSizeChange"
              @current-change="handleCurrentPageChange"
          >
          </el-pagination>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>

import IntNameForm from "./int-names-form.component.vue";

export default {
  name: "IntNamesExclusionsTable",
  props: ['internationalOperators', 'exclusions', "platformOperators"],
  components: {IntNameForm},
  methods: {
    platformOperatorName(row) {
      let interOper = this.internationalOperators.find(o => o._id === row.operatorId)
      if (!interOper) return "N/A"
      return interOper.bindType === 'group' ?
          (this.$store.getters.operatorsGroups.find(o => o._id === interOper.platformOperatorId) || {title: "N/A"}).title + " (Группа)" :
          (this.platformOperators.find(o => o._id === interOper.platformOperatorId) || {OrgName: "N/A"}).OrgName + " (Оператор)"
    },
    platformOperatorName1(row) {
      return row.bindType === 'group' ?
          (this.$store.getters.operatorsGroups.find(o => o._id === row.platformOperatorId) || {title: "N/A"}).title + " (Группа)" :
          (this.platformOperators.find(o => o._id === row.platformOperatorId) || {OrgName: "N/A"}).OrgName + " (Оператор)"
    },
    onEdit(name) {
      this.editingName = name
      this.addEditFormVisible = true
    },
    onAdd() {
      this.editingName = {}
      this.addEditFormVisible = true
    },
    removeOne(row) {
      this.removingOne = true
      this.axios.delete("/admin/routing/removeInternationalNameExclusion", {params: {nameId: row._id}})
          .then(resp => {
            this.$emit('reload')
            this.removingOne = false
          })
          .catch()
    },
    handlePageSizeChange(newSize) {
      this.pageSize = newSize
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage
    },
  },
  computed: {
    visibleNames(){
      return this.filteredNames.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    },
    filteredNames(){
      return this.exclusions.filter(n => {
        if(this.internalOperatorFilter && this.internalOperatorFilter !== n.operatorId) return false
        if(this.freeSearchToken && !(new RegExp(this.freeSearchToken, "gi")).test(n.name)) return false
        return true
      })

    }
  },
  data() {
    return {
      removingOne: false,
      addEditFormVisible: false,
      editingName: {},
      freeSearchToken: "",
      internalOperatorFilter: "",
      pageSize: 10,
      currentPage: 1
    }
  },
}

</script>