import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, toDisplayString as _toDisplayString, createSlots as _createSlots, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","flex-direction":"row","justify-content":"space-between","width":"100%","margin-bottom":"15px","align-items":"center"} }
const _hoisted_2 = { style: {"padding":"5px","border":"1px solid #DDD"} }
const _hoisted_3 = ["value"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_remove_button = _resolveComponent("remove-button")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_client_select = _resolveComponent("client-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_service_templates_table = _resolveComponent("service-templates-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("div", _hoisted_2, [
        _createVNode(_component_remove_button, {
          onConfirm: _cache[0] || (_cache[0] = ($event: any) => (_ctx.removeSelected())),
          loading: _ctx.removing
        }, {
          default: _withCtx(() => [
            _createTextVNode("Удалить выбранные шаблоны")
          ]),
          _: 1
        }, 8, ["loading"]),
        _createVNode(_component_el_button, {
          plain: "",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeStatus('awaiting'))),
          loading: _ctx.removing,
          class: "text-yellow text-button table-text-button"
        }, {
          default: _withCtx(() => [
            _createTextVNode("На рассмотрении ")
          ]),
          _: 1
        }, 8, ["loading"]),
        _createVNode(_component_el_button, {
          plain: "",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeStatus('active'))),
          loading: _ctx.removing,
          class: "text-green text-button table-text-button"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Подтвердить ")
          ]),
          _: 1
        }, 8, ["loading"]),
        _createVNode(_component_el_button, {
          plain: "",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changeStatus('rejected'))),
          loading: _ctx.removing,
          class: "text-red text-button table-text-button"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Отклонить ")
          ]),
          _: 1
        }, 8, ["loading"])
      ], 512), [
        [_vShow, _ctx.selection.length]
      ]),
      _createElementVNode("span", null, [
        _createVNode(_component_el_switch, {
          modelValue: _ctx.showTuplesOnly,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showTuplesOnly) = $event))
        }, null, 8, ["modelValue"]),
        _createTextVNode(" Показывать только дубли")
      ]),
      _createVNode(_component_el_button, {
        size: "small",
        type: "warning",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.sendAllTemplatesChangenotes())),
        loading: _ctx.noticesSending
      }, {
        default: _withCtx(() => [
          _createTextVNode("Отправить все уведомления об изменениях ")
        ]),
        _: 1
      }, 8, ["loading"])
    ]),
    _createVNode(_component_service_templates_table, {
      ref: "mTable",
      templates: _ctx.visibleTemplates,
      loading: _ctx.templatesLoading,
      small: true,
      embedded: true,
      onOnSave: _ctx.saveNewTemplates,
      onOnEdit: _cache[11] || (_cache[11] = r => {
        _ctx.dummy.status = r.status
        _ctx.dummy.userIdV = r.user_id
      }),
      onOnAddForm: _cache[12] || (_cache[12] = () => {
        _ctx.dummy.status = 'active'
        _ctx.dummy.userIdV = ''
      })
    }, _createSlots({
      "admin-add-form-components": _withCtx(() => [
        (_ctx.isGlobalView)
          ? (_openBlock(), _createBlock(_component_el_form_item, {
              key: 0,
              label: "Пользователь"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_client_select, {
                  modelValue: _ctx.dummy.userIdV,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.dummy.userIdV) = $event)),
                  "hide-inactive": true
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_el_form_item, { label: "Статус" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: _ctx.dummy.status,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dummy.status) = $event)),
              class: "full-width"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_option, {
                  value: "active",
                  label: "Активен"
                }),
                _createVNode(_component_el_option, {
                  value: "awaiting",
                  label: "На рассмотрении"
                }),
                _createVNode(_component_el_option, {
                  value: "rejected",
                  label: "Отклонён"
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      "admin-select": _withCtx(() => [
        _createVNode(_component_el_table_column, {
          label: "",
          width: "30"
        }, {
          header: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.selectAll) = $event)),
              value: true
            }, null, 512), [
              [_vModelCheckbox, _ctx.selectAll]
            ])
          ]),
          default: _withCtx((scope) => [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.selection) = $event)),
              value: scope.row.id
            }, null, 8, _hoisted_3), [
              [_vModelCheckbox, _ctx.selection]
            ])
          ]),
          _: 1
        })
      ]),
      "admin-actions": _withCtx(({onEditRow}) => [
        _createVNode(_component_el_table_column, {
          label: "",
          width: "100"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("button", {
              onClick: ($event: any) => (onEditRow(scope.row))
            }, [
              _createVNode(_component_font_awesome_icon, { icon: "edit" }),
              _createTextVNode(" Ред. ")
            ], 8, _hoisted_4)
          ]),
          _: 2
        }, 1024)
      ]),
      _: 2
    }, [
      (_ctx.isGlobalView)
        ? {
            name: "admin-client-filter",
            fn: _withCtx(({onClientFilterChange}) => [
              _createVNode(_component_el_form_item, { label: "Пользователь" }, {
                default: _withCtx(() => [
                  _createVNode(_component_client_select, {
                    onChange: onClientFilterChange,
                    "hide-inactive": true,
                    modelValue: _ctx.dummy.userIdF,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.dummy.userIdF) = $event))
                  }, null, 8, ["onChange", "modelValue"])
                ]),
                _: 2
              }, 1024)
            ]),
            key: "0"
          }
        : undefined,
      (_ctx.isGlobalView)
        ? {
            name: "admin-user-id-col",
            fn: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                label: "Польз.",
                width: "80"
              }, {
                default: _withCtx((scope) => [
                  _createElementVNode("span", null, _toDisplayString(scope.row.user_id), 1)
                ]),
                _: 1
              })
            ]),
            key: "1"
          }
        : undefined
    ]), 1032, ["templates", "loading", "onOnSave"])
  ], 64))
}