<template>
  <div>
    <int-name-operator-form
        :visible="addEditFormVisible"
        :editing-operator="editingOperator"
        :platform-operators="platformOperators"
        @close="addEditFormVisible = false"
        @reload="$emit('reload')"
    ></int-name-operator-form>
    <el-row>
      <el-col :span="24">
        <el-table :data="internationalOperators">
<!--          <el-table-column label="Название" prop="name" min-width="140"/>-->
          <el-table-column label="Платформенный оператор" :formatter="platformOperatorName" min-width="140"/>
          <el-table-column label="Тип привязки" :formatter="row => row.bindType === 'group' ? 'Группа' : 'Оператор' " min-width="140"/>
          <el-table-column label="Внешний" :formatter="row => row.externalSource ? 'Да' : 'Нет'" min-width="100"/>
          <el-table-column label="Источник" :formatter="row => row.externalSource || ''" min-width="180"/>
          <el-table-column label="">
            <template #default="scope">
              <ActionsDropdown>
                <ActionButton icon="pencil-alt" @click="onEdit(scope.row)">Изменить</ActionButton>
                <ActionButton type="link" icon="download" :to="`${axios.defaults.baseURL}/admin/routing/downloadInternationalNames?token=${$store.getters.bareToken}&operatorId=${scope.row._id}`">Скачать имена</ActionButton>
                <ActionButton type="remove" @confirm="removeOne(scope.row)" :loading="removingOne">Удалить</ActionButton>
              </ActionsDropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-button type="success" style="margin-top: 40px" @click="onAdd">Добавить</el-button>
  </div>
</template>

<script>

import IntNameOperatorForm from "./int-names-operator-form.component.vue";

export default {
  name: "IntNamesOperatorsTable",
  props: ['internationalOperators', 'platformOperators'],
  components: {IntNameOperatorForm},
  methods: {
    platformOperatorName(row) {
      return row.bindType === 'group' ?
          (this.$store.getters.operatorsGroups.find(o => o._id === row.platformOperatorId) || {title: "N/A"}).title :
          (this.platformOperators.find(o => o._id === row.platformOperatorId) || {OrgName: "N/A"}).OrgName
    },
    onEdit(operator) {
      this.editingOperator = operator
      this.addEditFormVisible = true
    },
    onAdd() {
      this.editingOperator = {}
      this.addEditFormVisible = true
    },
    removeOne(row){
      this.removingOne = true
      this.axios.delete("/admin/routing/removeInternationalNamesOperator", {params: {operatorId: row._id}})
          .then(resp => {
            this.$emit('reload')
            this.removingOne = false
          })
          .catch()
    },
    onDownload(row){

    }
  },
  computed: {},
  data() {
    return {
      removingOne: false,
      addEditFormVisible: false,
      editingOperator: {}
    }
  },
}

</script>