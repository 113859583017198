

import {defineComponent} from 'vue'
import ClientView from "../../components/clients/client-view.component.vue";
import {GlobalSubstitutionRule, SubstitutionRule} from "../../schemas/routing";
import IndividualSenderSubstitutionRulesTable
  from "../../components/routing/individual-sender-substitution-rules-table.component.vue";

export default defineComponent({
  name: "SenderSubstitutionRules",
  title: "Списки подмены отправителя",
  components: {IndividualSenderSubstitutionRulesTable, ClientView},
  watch: {
    "newRuleForm.operator_type": function(v){
      this.newRuleForm.operator_group = ""
    }
  },
  methods: {
    async getAllRules() {
      this.loading = true
      let resp = await this.axios.get("/admin/routing/allSenderSubstitutions")
      this.allRules = resp.data
      this.loading = false
    },
    async getPools() {
      let resp = await this.axios.get("/admin/routing/allPools")
      this.allAgregatorsPools = resp.data
    },
    async getProviders() {
      let resp = await this.axios.get("/admin/routing/allProviders")
      this.allProviders = resp.data
    },
    onAdd() {
      this.newRuleForm = new GlobalSubstitutionRule()
      this.newRuleVisible = true
    },
    editOne(rule: SubstitutionRule) {
      this.newRuleForm = rule
      this.newRuleVisible = true
    },
    async removeOne(ruleId: string) {
      await this.axios.delete("/admin/routing/removeGlobalSenderSubstitutionRule", {params: {ruleId}})
      this.$gNotify("Правило удалено", "success")
      this.allRules.splice(this.allRules.findIndex(r => r._id === ruleId), 1)
    },
    async saveRule(){
      if(this.newRuleForm.original_name === "*" && this.newRuleForm.operator_group === "any" && this.newRuleForm.agregator_pool === "any")
        return this.$gNotify("Нельзя создать настолько глобальное правило", "error")
      if(this.newRuleForm.global_rule_type === "by_provider" && this.newRuleForm.provider_id === "Укажите поставщика")
        return this.$gNotify("Нельзя создать настолько глобальное правило", "error")
      if(!this.newRuleForm.operator_group || !this.newRuleForm.agregator_pool || !this.newRuleForm.original_name || !this.newRuleForm.change_with)
        return this.$gNotify("Заполните необходимые поля", "error")
      this.saving = true
      await this.axios.post("/admin/routing/saveGlobalSenderSubstitutionRule", this.newRuleForm)
      this.saving = false
      this.newRuleVisible = false
      this.$gNotify("Правило сохранено", "success")
      this.getAllRules()
    },
    async showUserProfile(row) {
      await (this.$refs.clientView as any).show(row.forUser, "individualSenderSubstitutions")
    }
  },
  computed: {
    globalRules(): Array<SubstitutionRule> {
      return this.allRules.filter(r => r.global)
    },
    individualRules(): Array<SubstitutionRule> {
      return this.allRules.filter(r => !r.global)
    },
    title(): string {
      return this.newRuleForm._id ? "Редактирование правила" : "Новое правило"
    }
  },
  mounted() {
    this.getAllRules()
    this.getPools()
  },
  data() {
    return {
      loading: false,
      allRules: [] as SubstitutionRule[],
      allAgregatorsPools: [], allProviders: [],
      visiblePanel: "global",
      removingOne: false,
      newRuleVisible: false,
      newRuleForm: new GlobalSubstitutionRule() as SubstitutionRule,
      saving: false
    }
  },
})

