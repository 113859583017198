<template>
  <div>
    <el-dialog v-model="rateUsersVisible"
               :title="rateUsersModalTitle"
               class="modal-95-70-60"
    >
      <el-button type="danger" style="margin-bottom: 20px" @click="assignToEverybody">Назначить всем</el-button>
      <el-table :data="rateUsersModalTableData">
        <el-table-column label="Контрагент" prop="sid" min-width="100"/>
        <el-table-column label="Период назначения" prop="sid" min-width="100">
          <template #default="scope">
            с {{ _formatDate(scope.row.a_from) }} {{ scope.row.a_to ? ' до ' + _formatDate(scope.row.a_to) : '' }}
          </template>
        </el-table-column>
        <el-table-column label="Приоритет" prop="priority" min-width="100"/>
      </el-table>
    </el-dialog>
    <client-rate-details
        :selected-rate="selectedRateForDetails"
        :senders="[]"
        :inboxes="[]"
        :visible="rateDetailsVisible"
        @close="rateDetailsVisible = false"
    />
    <el-table :data="visibleRates" >
      <el-table-column label="Название" min-width="180" prop="name">
        <template #default="scope">
          <span class="table-text-button blue-button" style="cursor: pointer" @click="showRateDetails(scope.row)">
            {{ scope.row.name }}
          </span>
          <br/>
          <span
              style="color: var(--el-text-color-disabled); display: block; margin-top: 4px"
              v-if="scope.row.client_name"
          >
            {{scope.row.client_name}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Подтип" :formatter="subtype" min-width="100"/>
      <el-table-column label="Дата активации" :formatter="activationDate"
                       min-width="120"/>
      <el-table-column label="Польз." min-width="80">
        <template #default="slot">
          <el-button text class="table-text-button blue-button" @click="showRateUsers(slot.row)">
            {{ (slot.row.usedBy || []).length }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Активен" :formatter="rateActive" min-width="100"/>
      <el-table-column label="Действие" min-width="300">
        <template #default="scope">
          <el-button text class="table-text-button" size="small" v-if="!scope.row.inArchive"
                     @click="$emit('editRate', scope.row)" style="margin-right: 10px">
            Изменить
          </el-button>
          <el-button text class="table-text-button" size="small" v-if="!scope.row.locked"
                     @click="$emit('blockRate', scope.row, 1)" style="margin-right: 10px">
            Заблокировать
          </el-button>
          <el-button text class="table-text-button" size="small" v-if="scope.row.locked"
                     @click="$emit('blockRate', scope.row, 0)" style="margin-right: 10px">
            Разблокировать
          </el-button>
          <el-button text class="table-text-button" size="small" v-if="!scope.row.inArchive && scope.row.locked"
                     @click="$emit('archiveRate', scope.row, 1)" style="margin-right: 10px">
            Архивировать
          </el-button>
          <el-button text class="table-text-button" size="small" v-if="scope.row.inArchive"
                     @click="$emit('archiveRate', scope.row, 0)" style="margin-right: 10px">
            Разархивировать
          </el-button>
          <remove-button @confirm="$emit('removeRate', scope.row)">Удалить</remove-button>

        </template>
      </el-table-column>
    </el-table>
    <div class="solo-pagination-container">
      <el-pagination
          background
          layout="sizes, prev, pager, next"
          :total="rates.length"
          class="wide-page-size"
          :page-sizes="[20, 50, 100]"
          :page-size="pageSize"
          :pager-count="3"
          :hide-on-single-page="true"
          @size-change="handlePageSizeChange"
          @current-change="handleCurrentPageChange"
      >
      </el-pagination>
    </div>

  </div>
</template>

<script>


import moment from "moment/moment";
import RemoveButton from "../../../shared/components/layout/removeButton.component.vue";
import TableSpinner from "../../../shared/components/layout/table-spinner.component.vue";
import ClientRateDetails from "../../../shared/components/finance/client-rate-details.component.vue";

export default {
  name: "AdminRatesTable",
  components: {ClientRateDetails, TableSpinner, RemoveButton},
  props: ['rates'],

  methods: {
    _formatDate: function (v) {
      return this.formatDate(v)
    },
    activationDate(row) {
      return row.activateAt || this.formatDate(row.date_created)
    },
    handlePageSizeChange(newSize) {
      this.pageSize = newSize
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage
    },
    rateActive(row) {
      if (row.inArchive) return "Архив"
      if (row.locked) return "Блок."
      if (row.activateAt && moment(row.activateAt, "DD.MM.YYYY").isAfter(moment())) return "Нет"
      return 'Да'
    },
    subtype(row) {
      switch (String(row.subtype)) {
        case "0":
          return row.type === 1 ? "Общий" : "Фикс."
        case "1":
          return row.type === 1 ? "Индив." : "Гибк."
        case "7":
          return "Профи"
        case "8":
          return "Междун."
        default:
          return "--"
      }
    },
    showRateUsers(rate) {
      this.rateUsersModalTitle = rate.name
      this.assignToEverybodyRateId = rate._id
      this.rateUsersModalTableData = (rate.usedBy || [])
      this.rateUsersVisible = true
    },
    showRateDetails(rate) {
      rate.sender_names = []
      this.selectedRateForDetails = rate
      this.rateDetailsVisible = true
    },
    assignToEverybody(){
      this.$confirm("Подтвердите назначение данного тарифа всем пользователям!")
          .then(con => {
            if(con){
              this.axios.post("/admin/clients/assignRateToEverybody", {rateId: this.assignToEverybodyRateId})
                  .then(resp => {
                    this.rateUsersVisible = false
                    this.assignToEverybodyRateId = ""
                    this.$emit('reload')
                  })
            }
          })
    },
    // sortTable(args){
    //   this.$emit("sort", args)
    // }
  },
  computed: {
    visibleRates() {
      return this.rates.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    }
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 100,
      rateUsersVisible: false,
      rateUsersModalTitle: "",
      rateUsersModalTableData: [],
      sortState: {key: 'name', order: 'asc'},
      assignToEverybodyRateId: "",
      selectedRateForDetails: {},
      rateDetailsVisible: false
    }
  },
}

</script>