<template>
  <div>
    <el-dialog v-model="reloadResultsVisible"
               title="Результат перезагрузки имён"
               class="modal-95-70-60"
    >
      <el-row>
        <el-col :span="24">
          <el-table :data="reloadResults">
            <el-table-column label="Оператор" prop="operator" min-width="140"/>
            <el-table-column label="Получено имён" prop="parsedNames" min-width="140"/>
            <el-table-column label="Имён на момент загрузки" prop="existingNames" min-width="140"/>
            <el-table-column label="Добавлено" prop="newNames" min-width="140"/>
            <el-table-column label="Удалено" prop="removedNames" min-width="140"/>
          </el-table>
        </el-col>
      </el-row>
    </el-dialog>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-tabs v-model="visiblePanel" class="primary-heading-tabs">
          <el-tab-pane label="Имена" name="names">
            <TableSpinner :loading="loading">
              <int-names-table
                  :names="allNames"
                  :international-operators="internationalOperators"
                  :platform-operators="platformOperators"
                  @reload="refresh"
              />
            </TableSpinner>
          </el-tab-pane>
          <el-tab-pane label="Операторы" name="operators">
            <TableSpinner :loading="loading">
              <int-names-operators-table
                  :international-operators="internationalOperators"
                  :platform-operators="platformOperators"
                  @reload="refresh"
              />
            </TableSpinner>
          </el-tab-pane>
          <el-tab-pane label="Исключения" name="exclusions">
            <TableSpinner :loading="loading">
              <int-names-exclusions-table
                  :exclusions="exclusions"
                  :international-operators="internationalOperators"
                  :platform-operators="platformOperators"
                  @reload="refresh"
              />
            </TableSpinner>
          </el-tab-pane>

        </el-tabs>
        <el-button type="danger" style="margin-top: 40px; float:right" @click="forceReload" :loading="reloading">Перезагрузить имена</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>


import AgregatorsTable from "@/components/routing/agregators-table.component.vue";
import AgregatorForm from "@/components/routing/agregator-form.component.vue";
import AgregatorsPoolsTable from "../../components/routing/agregators-pools-table.component.vue";
import IntNamesOperatorsTable from "../../components/routing/int-names-operators-table.component.vue";
import IntNamesTable from "../../components/routing/int-names-table.component.vue";
import IntNamesExclusionsTable from "@/components/routing/int-names-exclusions-table.component.vue";

export default {
  name: "InternationalNames",
  title: "Международные имена",
  components: {
    IntNamesExclusionsTable,
    IntNamesTable,
    IntNamesOperatorsTable
  },
  methods: {
    async getOperators() {
      let resp = await this.axios.get("/admin/routing/getInternationalNamesOperators")
      this.internationalOperators = resp.data
    },
    async getNames() {
      let resp = await this.axios.get("/admin/routing/getInternationalNames")
      this.allNames = resp.data
    },
    async getExclusions() {
      let resp = await this.axios.get("/admin/routing/getInternationalNamesExclusions")
      this.exclusions = resp.data
    },
    refresh() {
      this.loading = true
      Promise.all([
        this.getNames(),
        this.getOperators(),
        this.getExclusions()
      ]).then(() => {
        this.loading = false
      })
    },
    forceReload(){
      this.reloading = true
      this.axios.post("/admin/routing/forceReloadInternationalNames").then(resp =>{
        if(resp.data.error) this.$gNotify("Ошибка загрузки", "error")
        else {
          this.reloadResults = resp.data
          this.reloadResultsVisible = true
        }
        this.reloading = false
        this.refresh()
      })
    }
  },
  computed: {},
  mounted() {
    this.axios.get("/resources/operators")
        .then(resp => {
          this.platformOperators = resp.data.filter(o => String(o.mcc) === "250")
          this.refresh()
        })


  },
  data() {
    return {
      loading: true,
      allNames: [],
      exclusions: [],
      internationalOperators: [],
      visiblePanel: "names",
      reloading: false,
      reloadResults: [],
      reloadResultsVisible: false,

      platformOperators: []
    }
  },
}

</script>