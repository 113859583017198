<template>
  <el-button
      :type="type || 'danger'"
      :text="!type"
      :size="regular ? 'medium' : 'small'"
      :class="regular ? '' : `text-button ${!tableButton ? 'table-text-button' : ''}`"
      plain
      :loading="loading"
      @click="showConfirm"
      :disabled="disabled || false"
  >
    <font-awesome-icon :icon="icon || 'times'" class="el-icon--left"></font-awesome-icon>
    <slot/>
  </el-button>

</template>

<script>

export default {
  name: "RemoveButton",
  props: ["tableButton", "type", "regular", "loading", "icon", "disabled", "confirmMessage"],
  methods: {
    showConfirm(){
      this.$confirm(
          this.confirmMessage || "Подтвердите удаление",
          {type:"danger", confirmButtonText: this.confirmMessage ? "Подтвердить" : "Удалить", cancelButtonText: "Отмена", confirmButtonClass: "force-danger-button"}
      )
      .then(() => {
        this.$emit("confirm")
      })
      .catch(e => {

      })
    }
  },
  data() {
    return {}
  },
}

</script>