<template>
  <el-dialog v-model="intVisible"
             :title="title"
             @close="$emit('close')"
             class="modal-95-70-60"
  >
    <el-row>
      <el-col :span="24">
        <el-form label-position="top" ref="poolForm" :rules="formRules" :model="form">
          <el-form-item label="" prop="id" style="display: none">
            <el-input v-model="form.id"/>
          </el-form-item>
          <el-form-item label="Название" prop="name">
            <el-input v-model="form.name"/>
          </el-form-item>

          <el-divider class="blue-divider" />

          <div v-for="(_a, k) in form.agregators" style="margin: 20px 0px; border: 1px solid #CCC; position:relative;padding: 15px">
            <span style="position: absolute; top: 5px; right: 5px; cursor: pointer" @click="removeAgregator(k)"><font-awesome-icon icon="times" /></span>

            <el-form-item label="Агрегатор">
              <el-select v-model="form.agregators[k].agregator_id" class="full-width">
                <el-option
                    v-for="agregator in agregators"
                    :value="agregator._id" :label="agregator.name"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Приоритет (%)" prop="priority">
              <el-input v-model="form.agregators[k].priority"/>
            </el-form-item>
          </div>
          <el-button size="small" type="primary" @click="addEmptyAgregator">Добавить агрегатора</el-button>
        </el-form>
      </el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="$emit('close')">Закрыть</el-button>
        <el-button type="success" @click="upsertPool" :loading="upserting">Сохранить</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>


export default {
  name: "AgregatorPoolForm",
  props: ["visible", "editingPool", "agregators"],
  watch: {
    visible(v){
      this.intVisible = v
    },
    async editingPool(newVal) {

      if (!newVal || !newVal._id) {
        this.form.id = ""
        this.form.name = ""
        this.form.agregators = []
      } else {
        this.form.id = String(newVal._id)
        this.form.name = newVal.name
        this.form.agregators.splice(0, this.form.agregators.length)
        this.form.agregators.push(...newVal.agregators)
      }
    }
  },
  methods: {
    upsertPool() {
      this.$refs.poolForm.validate()
          .then(valid => {
            if (!valid) return
            this.upserting = true
            this.axios.post("/admin/routing/upsertPool", {data: this.form})
                .then(resp => {
                  this.upserting = false
                  this.$emit("close")
                  this.$emit("reload")
                }).catch(console.error)
          })
    },
    addEmptyAgregator(){
      this.form.agregators.push({
        priority: 0,
        agregator_id: ""
      })
    },
    removeAgregator(index){
      this.form.agregators.splice(index, 1)
    }
  },
  computed: {
    title() {
      return this.editingPool && this.editingPool._id ? "Редактирование пула" : "Новый пул"
    },
  },
  data() {
    return {
      form: {
        id: "",
        name: "",
        agregators: []
      },
      formRules: {
        id: [{required: false}],
        name: [{required: true, trigger: "blur"}],
      },
      upserting: false,
      intVisible: false
    }
  }
}

</script>