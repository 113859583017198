<template>
  <el-dialog class="modal-95-70-60" title="Счетчики сообщений по шаблону"
             v-model="countersModalVisible">
    <el-table :data="currentCounters" style="width: 100%">
      <el-table-column label="Месяц" :formatter="r => bMonth(r.month)"/>
      <el-table-column label="Счетчик" prop="counter"/>
    </el-table>
  </el-dialog>
  <el-dialog class="modal-95-70-60" :title="addForm.id ? 'Редактировать шаблон' : 'Добавить шаблоны'"
             v-model="addFormVisible">
    <el-form label-position="top" :model="addForm">
      <span @click="rulesListVisible = !rulesListVisible"
            style="color: var(--el-color-primary); cursor: pointer;">Основные правила составления шаблонов&nbsp;<font-awesome-icon icon="chevron-down"/><br/><br/></span>
      <div v-show="rulesListVisible">
        <p class="wra">
          Текст шаблона сообщения может состоять либо исключительно из статичной части (например, <i>"Ваш заказ доставлен."</i> ), либо из статичной и изменяемой частей (например, <i>"Ваш заказ будет доставлен <b>01.01.2023 16:00</b>"</i> ).<br/>
          Изменяемую часть в шаблоне необходимо заменять следующими операндами:
        </p>
        <p><b>%w</b> - любой непрерывный (без пробелов) набор букв, цифр и спецсимволов;</p>
        <p><b>%d</b> - любой непрерывный (без пробелов) набор цифр и спецсимволы;</p>
        <p><b>%w{1,n}</b> - Ограниченная последовательность слов (состоящих из букв, цифр или спецсимволов), разделенных пробелом, где n - целое число, которое не может принимать значение 1 и должно быть в диапазоне от 2 до 15 (2 &lt;= n &lt;= 15);</p>
        <p><b>%d{1,n}</b> - Ограниченная последовательность чисел (состоящих из цифр или спецсимволов), разделенных пробелом, где n - целое число, которое не может принимать значение 1 и должно быть в диапазоне от 2 до 20 (2 &lt;= n &lt;=20).</p>
        <br/>
        <br/>
        <p><u>При составлении шаблонов необходимо придерживаться определенных правил:</u></p><br/>
        <p>- операнды должны обязательно отделяться от слов пробелами с обеих сторон;</p>
        <p>- две и более операнды не могут находиться рядом друг с другом (например, конструкция <i>"Ваш заказ <b>%d</b> <b>%w</b>"</i> должна быть заменена на <i>"Ваш заказ <b>%w{1,2}</b>"</i>);</p>
        <p>- шаблон должен без затруднений идентифицироваться с текстом конечного сообщения.</p>
        <el-divider />
      </div>
      <slot name="admin-add-form-components"/>
      <el-form-item label="Группа операторов">
        <el-select v-model="addForm.operatorGroup">
          <el-option v-for="o in operatorsOptions" :value="o.id" :label="o.name"/>
        </el-select>
      </el-form-item>
      <el-form-item label="Тип трафика">
        <el-select v-model="addForm.trafficType" class="full-width" :disabled="!addForm.operatorGroup">
          <el-option v-for="o in trafficTypeOptions" :value="o.value" :label="o.name"
                     :disabled="!o.availableOperators.includes(addForm.operatorGroup)"/>
        </el-select>
      </el-form-item>
      <el-form-item label="Подпись">
        <el-select v-model="addForm.sender" filterable :disabled="!addForm.operatorGroup">
          <el-option v-for="s in senderOptions" :value="s" :label="s" :disabled="!senderRegisteredOnOperator(s)"/>
        </el-select>
      </el-form-item>
      <el-form-item label="Шаблон (добавьте несколько разделяя одним или более символом новой строки)">
        <el-input type="textarea" :rows="5" v-model="addForm.texts"/>
      </el-form-item>
      <el-button type="success" @click="saveNewTemplates()" :loading="saving"
                 :disabled="!addForm.texts || !addForm.sender || !addForm.trafficType || !addForm.operatorGroup">
        {{ addForm.id ? 'Сохранить' : 'Добавить' }}
      </el-button>
    </el-form>
  </el-dialog>

  <TableSpinner :loading="loading">
    <el-form label-position="top" :model="filter" style="width: 100%" :size="small ? 'small': 'default'">
      <el-row :gutter="20">
        <el-col :xs="24" :md="6">

          <el-form-item label="Статус">
            <el-select v-model="filter.status" class="full-width">
              <el-option value="" label="Все"/>
              <el-option value="active" label="Активен"/>
              <el-option value="awaiting" label="На рассмотрении"/>
              <el-option value="rejected" label="Отклонён"/>
            </el-select>
          </el-form-item>
          <el-form-item label="Группа операторов">
            <el-select v-model="filter.operator" class="full-width">
              <el-option value="" label="Все"/>
              <el-option v-for="o in operatorsOptions" :value="o.id" :label="o.name"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="6">
          <el-form-item label="Тип трафика">
            <el-select v-model="filter.type" class="full-width">
              <el-option value="" label="Все"/>
              <el-option v-for="o in trafficTypeOptions" :value="o.value" :label="o.name"/>

            </el-select>
          </el-form-item>
          <slot name="admin-client-filter" :onClientFilterChange="v => filter.userId = v"/>
        </el-col>
        <el-col :xs="24" :md="6">
          <el-form-item label="Подпись">
            <el-select v-model="filter.sender" class="full-width" filterable>
              <el-option value="" label="Все"/>
              <el-option v-for="s in filterSenderOptions" :value="s" :label="s"/>
            </el-select>

          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="6">
          <el-form-item label="Шаблон">
            <el-input v-model="filter.text"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>


    <el-table :data="visibleTemplates" style="width: 100%">
      <slot name="admin-select"></slot>
      <slot name="admin-user-id-col"></slot>
      <el-table-column :formatter="addEditDate" label="Дата добавления" width="120"/>
      <el-table-column label="Статус" width="120">
        <template #default="scope">
          <span v-html="statusVerbose(scope.row.status)"></span>
        </template>
      </el-table-column>
      <el-table-column prop="oper_group" label="Группа операторов" width="150"/>
      <el-table-column :formatter="trafficType" label="Тип трафика" width="150"/>
      <el-table-column prop="sender_name" label="Подпись" width="150"/>
      <el-table-column label="Шаблон" min-width="300">
        <template #default="scope">
          <pre class="text-pre">{{ scope.row.template }}</pre>
        </template>
      </el-table-column>
      <el-table-column min-width="140" label="Счетчик">
        <template #header>
          <el-button type="success" size="small" @click="showAllCountersCumsum()">
            Счётчик: {{ allCountersCumsum }}
          </el-button>
        </template>
        <template #default="scope">
          <el-button type="success" size="small" @click="showAllCounters(scope.row)"
                     :disabled="!scope.row.counters.length">
            {{ lastMonthCounter(scope.row) }}
          </el-button>
        </template>
      </el-table-column>
      <slot name="admin-actions" :onEditRow="editRow"></slot>

    </el-table>
    <div class="solo-pagination-container">
      <el-button type="success" @click="showAddForm()">Добавить шаблоны</el-button>
      <el-dropdown>
        <el-button type="primary" style="margin-left: 10px">Скачать&nbsp;&nbsp;<font-awesome-icon icon="chevron-down"/>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu style="width: 200px">

            <el-dropdown-item
                v-for="month in static.months"
                @click="exportTable(month.value)"
            >{{ month.label }}
            </el-dropdown-item>

          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <el-pagination
          background
          class="wide-page-size"
          layout="sizes, prev, pager, next"
          :total="filteredTemplates.length"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :hide-on-single-page="true"
          :pager-count="3"
          :current-page="currentPage"
          @size-change="handlePageSizeChange"
          @current-change="handleCurrentPageChange"
      >
      </el-pagination>
      <p style="margin-left: 20px">({{ filteredTemplates.length }} шабл.)</p>
    </div>
  </TableSpinner>

</template>

<script>

import moment from "moment"
import iconv from "iconv-lite";

class NewTemplateForm {
  id = ""
  userId = ""
  operatorGroup = ""
  trafficType = "service"
  sender = ""
  status = "active"
  texts = ""
}

export default {
  name: "ServiceTemplatesTable",
  props: ["templates", "loading", "small", "senders", "embedded", "allowedSendersPerOperator"],
  components: {},
  watch: {
    "filteredTemplates": function (newV) {
      this.recalculateCumsum()
      this.$emit("filtered", newV || [])
    },
    "addForm.operatorGroup": function () {
      this.addForm.trafficType = ""
      this.addForm.sender = ""
    }
  },
  mounted() {
    // if(!this.embedded) this.senderOptions = this.getFilterSenderOptions()
    let now = moment()
    let months = []
    for (let i = 1; i <= 2; i++) {
      months.push({label: now.locale("ru").format("YYYY MMMM"), value: now.startOf("month").format("MM.YYYY")})
      now = moment().subtract(i, "months")
    }
    this.static.months = months

  },
  methods: {
    getFilteredTemplates() {
      return this.filteredTemplates
    },
    trafficType(row) {
      return TRAFFIC_TYPES[row.traffic_type] || "Не определен"
    },
    getVisibleIds() {
      return this.visibleTemplates.map(t => t.id)
    },
    handlePageSizeChange(newSize) {
      this.pageSize = newSize
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage
    },
    addEditDate(t) {
      if (!t.addEditDate) return ""
      return moment(t.addEditDate).format("DD.MM.YYYY HH:mm")
    },
    statusVerbose(status, plainString=false) {
      if (status === "awaiting") return plainString ? 'На рассмотрении' : "<span class='text-yellow'>На рассмотрении</span>"
      if (status === "active") return plainString ? 'Активен' : "<span class='text-green'>Активен</span>"
      if (status === "rejected") return plainString ? 'Отклонён' : "<span class='text-red'>Отклонён</span>"
      return ""
    },
    showAddForm() {
      this.addForm = new NewTemplateForm()
      this.$emit("onAddForm")
      this.addFormVisible = true
    },
    saveNewTemplates() {
      this.saving = true
      this.$emit("onSave", {
        addForm: this.addForm,
        onSuccess: () => {
          this.saving = false
          this.addFormVisible = false
        }
      })

    },
    async editRow(r) {
      this.addForm.id = r.id
      this.addForm.operatorGroup = r.opg_id
      await this.$nextTick()
      this.addForm.trafficType = r.traffic_type
      this.addForm.sender = r.sender_name
      this.addForm.texts = r.template
      this.$emit("onEdit", r)
      this.addFormVisible = true
    },
    setSenders(senders) {
      this.senderOptions = senders
    },
    senderRegisteredOnOperator(name) {
      if (!this.allowedSendersPerOperator) return true
      return this.allowedSendersPerOperator[this.addForm.operatorGroup] && this.allowedSendersPerOperator[this.addForm.operatorGroup].includes(name)
    },

    lastMonthCounter(row) {
      let lastCounter = (row.counters.sort((c1, c2) => moment(c2.month, "MM.YYYY").valueOf() - moment(c1.month, "MM.YYYY").valueOf()) || [])[0] || {counter: 0}
      if (!lastCounter.month) return "0"
      if (lastCounter.month !== moment().format("MM.YYYY")) return `${lastCounter.counter} (${lastCounter.month})`
      return lastCounter.counter
    },
    specificMonthCounter(row, month) {
      let lastCounter = row.counters.find((c1) => c1.month === month)
      return lastCounter ? String(lastCounter.counter) : "0"

    },
    showAllCounters(row) {
      this.currentCounters = row.counters.sort((c1, c2) => moment(c2.month, "MM.YYYY").valueOf() - moment(c1.month, "MM.YYYY").valueOf())
      this.countersModalVisible = true
    },
    showAllCountersCumsum() {
      let allVisibleTemplates = this.getFilteredTemplates()
      this.currentCounters = Object.values(allVisibleTemplates.reduce((acc, t) => {
        for (let counter of (t.counters || [])) {
          if (!acc[counter.month]) acc[counter.month] = {month: counter.month, counter: 0}
          acc[counter.month].counter += Number(counter.counter) || 0
        }
        return acc
      }, {})).sort((c1, c2) => moment(c2.month, "MM.YYYY").valueOf() - moment(c1.month, "MM.YYYY").valueOf())
      this.countersModalVisible = true
    },
    bMonth(v) {
      return moment(v, "MM.YYYY").format("MMMM YYYY")
    },
    recalculateCumsum() {
      this.allCountersCumsum = (this.filteredTemplates || []).reduce((acc, t) => {
        return acc + (Number(this.lastMonthCounter(t)) || 0)
      }, 0)
    },
    exportTable(month) {
      const serviceTemplatesTrafficTypes = {
        "service": "Сервисный",
        "authorisation": "Авторизационный",
        "informational": "Информационный",
        "transactional": "Транзакционный",
        "templated_add" : "Рекламный с шаблоном",
      }
      let csvContent = [
        iconv.encode(`Дата добавления;Статус;Оператор;Имя;Тип шаблона;Текст;Количество сообщений\n`, 'win1251'),
        ...this.filteredTemplates.map(a => {
          return iconv.encode([
            this.addEditDate(a),
            this.statusVerbose(a.status, true),
            a.oper_group,
            a.sender_name,
            serviceTemplatesTrafficTypes[a.traffic_type],
            '"' + a.template.replace(/"/g, '""') + '"',
            this.specificMonthCounter(a, month)
          ].join(";") + "\n", 'win1251')
        })
      ]
      let blob = new Blob(csvContent, {type: 'text/csv'});

      let a = document.createElement('a');
      a.download = `Сервисные шаблоны ${this.bMonth(month)}.csv`;
      a.href = URL.createObjectURL(blob);
      a.click();
    }
  },
  computed: {
    filteredTemplates() {
      let senderRegex = this.filter.sender ? new RegExp(this.filter.sender, "gi") : null
      let textRegex = this.filter.text ? new RegExp(escapeRegExp(this.filter.text), "gi") : null

      return this.templates.filter(t => {
        if (this.filter.operator && t.opg_id !== this.filter.operator) return false
        if (this.filter.userId && t.user_id !== this.filter.userId) return false
        if (this.filter.type && this.filter.type !== t.traffic_type) return false
        if (this.filter.status && this.filter.status !== t.status) return false
        if (senderRegex) {
          senderRegex.lastIndex = -1
          if (!senderRegex.test(t.sender_name)) return false
        }
        if (textRegex) {
          textRegex.lastIndex = -1
          if (!textRegex.test(t.template)) return false
        }
        return true
      })
    },
    visibleTemplates() {
      return this.filteredTemplates.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    },
    filterSenderOptions() {
      return Array.from(new Set(this.templates.map(t => t.sender_name || ""))).filter(v => v.trim()).sort((t1, t2) => t1.localeCompare(t2))
    }
  },
  data() {
    return {
      filter: {
        operator: "",
        type: "",
        sender: "",
        text: "",
        status: "",
        userId: ""
      },
      countersModalVisible: false,
      currentCounters: [],
      allCountersCumsum: 0,
      addFormVisible: false,
      addForm: new NewTemplateForm(),
      saving: false,
      pageSize: 10,
      currentPage: 1,
      senderOptions: [],
      operatorsOptions: [
        {id: "570e2be8b52438c11a38f99b", name: "РФ_МегаФон"},
        {id: "570e2c11b52438c11a38f99c", name: "РФ_МТС"},
        {id: "570e2c6cb52438c11a38f99d", name: "РФ_Билайн"},
        {id: "570e2cb6b52438c11a38f99e", name: "РФ_Теле2"},
        {id: "570e2f37b52438c11a38f9a0", name: "РФ_МОТИВ"}
      ],
      trafficTypeOptions: [
        {
          value: "service",
          name: "Сервисный",
          availableOperators: ['570e2c11b52438c11a38f99c', '570e2c6cb52438c11a38f99d', '570e2cb6b52438c11a38f99e', '570e2f37b52438c11a38f9a0']
        },
        {
          value: "authorisation",
          name: "Авторизационный",
          availableOperators: ['570e2be8b52438c11a38f99b', '570e2c11b52438c11a38f99c']
        },
        {value: "informational", name: "Информационный", availableOperators: ['570e2be8b52438c11a38f99b']},
        {value: "transactional", name: "Транзакционный", availableOperators: ['']},
        {value: "templated_add", name: "Рекламный с шаблоном", availableOperators: ['570e2be8b52438c11a38f99b']}
      ],
      static: {
        months: []
      },
      rulesListVisible: false
    }
  }
}

</script>
