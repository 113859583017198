

import {defineComponent} from 'vue'


export default defineComponent({
  name: "NotificationSettings",
  title: "Уведомления модерации",
  components: {},
  methods: {
    async getSettings() {
      this.loading = true
      let resp = await this.axios.get("/admin/moderation/settings")
      this.settings.monitoringEmails = resp.data.monitoringEmails.join(",")
      this.settings.moderationEmails = (resp.data.moderationEmails || []).join(",")
      this.settings.moderationNotificationThreshold = resp.data.moderationNotificationThreshold
      this.loading = false
    },
    async saveSettings(){
      this.saving = true
      let r = await this.axios.post("/admin/moderation/settings", this.settings)
      this.$gNotify("Настройки сохранены", "success")
      this.saving = false
      this.getSettings()
    }
  },
  computed: {

  },
  mounted() {
    this.getSettings()
  },
  data() {
    return {
      loading: false,
      saving: false,
      settings: {
        monitoringEmails: "",
        moderationEmails: "",
        moderationNotificationThreshold: 0
      }
    }
  },
})

