<template>

  <dialogs-scaffold>

    <template #archetype-selector>
      <dialog-archetype-select
          v-model="activeArchetype"
          :archetypes="supportedDialogArchetypes"
      />
    </template>

    <template #dialog-selector="{onDialogSelected}">
      <div v-if="$slots['ownership-select']">
        <slot name="ownership-select"/>
      </div>

      <dialogs-select
          v-model="selectedTicket"
          v-if="!!activeArchetype"
          :autoload="true"
          :load-dialogs-method="loadTickets"
          :title-formatter="ticketTitleFormatter"
          :ref="el => dialogSelector = el"
          @dialogClicked="onDialogSelected"
      >
        <template #new-dialog-bottom="{onDialogCreated}" v-if="!isAdminView">
          <new-ticket-modal v-if="activeArchetype !== 'archive'" @ticketCreated="onDialogCreated"/>
        </template>
        <template #dialog-unread-notification="{dialog}">
          <div
              style="width:8px;height:8px;background: var(--el-color-danger); border-radius: 4px"
              v-if="activeArchetype !== 'archive' && dialog.hasUnreadMessages"
          />
        </template>
      </dialogs-select>
    </template>

    <template #subscriber-card>
      <slot name="manage-ticket" :ticket="selectedTicket" :onTicketStatusChange="reload"/>
    </template>

    <template #message-feed>
      <dialog-message-feed
          :supports-media="false"
          :chat-id="selectedTicket._id"
          :chat-type="selectedTicket.type"
          :disabled="!selectedTicket._id || activeArchetype === 'archive'"
          :textual-send-button="true"
          :fetch-messages-method="fetchConversation"
          :send-message-method="sendMessage"
          :ref="el => messageFeed = el"
      />
    </template>

  </dialogs-scaffold>

</template>
<script>
import DialogMessageFeed from "@shared/components/dialogs/dialog-message-feed.component.vue";
import DialogsSelect from "@shared/components/dialogs/dialogs-select.component.vue";
import DialogGroupSelect from "@shared/components/dialogs/archetype-select.component.vue";
import DialogArchetypeSelect from "@shared/components/dialogs/archetype-select.component.vue";
import DialogsScaffold from "@shared/components/dialogs/dialogs-scaffold.component.vue";
import DialogConnectionSelect from "@shared/components/dialogs/connection-select.component.vue";

import NewTicketModal from "@shared/components/support/new-ticket-modal.component.vue";
import ManageTicket from "@shared/components/support/manage-ticket.component.vue";

export default {
  name: "SupportView",
  props: [
      "reloadFlag",
    "ticketTitleFormatter", "loadTicketsMethod",
      "fetchConversationMethod", "sendMessageMethod"
  ],
  inject: ["isAdminView"],
  provide() {
    return {
      messageAuthorHints: {
        my: "Вы",
        foreign: this.isAdminView ? "Клиент" : "Администратор"
      },
      hideMessageDeliveryStatus: true
    }
  },
  components: {
    ManageTicket,
    NewTicketModal,
    DialogConnectionSelect, DialogsScaffold, DialogArchetypeSelect,
    DialogGroupSelect, DialogsSelect, DialogMessageFeed
  },

  watch: {
    reloadFlag() {
      this.reload()
    },
    activeArchetype(v) {
      if (!!v)
        this.reload()
    },

    "selectedTicket._id": function (v) {
      if (this.messageFeed) {
        this.messageFeed.clear()
        if (v)
          this.$nextTick(() => {
            this.messageFeed.loadConversation()
          })
      }
    }
  },
  mounted() {
    this.$emitter.on("supportMessage", async (payload) => {
      payload = JSON.parse(payload)
      let forThisChat = payload.contactId === this.selectedTicket._id
      this.dialogSelector.onNewMessageReceived(payload, forThisChat)
      if (forThisChat)
        this.messageFeed.onNewMessageReceived(payload)
    })
  },
  beforeUnmount() {
    this.$emitter.off("supportMessage")
  },

  methods: {
    reload(){
      if(this.dialogSelector)
        this.dialogSelector.loadDialogs()
    },
    loadTickets(filterToken, offset) {
      return this.loadTicketsMethod(filterToken, offset, this.activeArchetype)
    },
    fetchConversation(sentOffset, receivedOffset, signal) {
      return this.fetchConversationMethod(sentOffset, receivedOffset, this.selectedTicket._id, signal)
    },
    sendMessage(messagePayload) {
      return this.sendMessageMethod({...messagePayload, ticketId: this.selectedTicket._id})
    }
  },
  computed: {},
  data() {
    return {
      activeArchetype: "",
      supportedDialogArchetypes: [
        {key: 'open', label: 'Открытые'},
        {key: 'archive', label: 'Архив'}
      ],

      dialogSelector: null,
      messageFeed: null,

      selectedTicket: {_id: "", extras: {}},

      loading: false,

    }
  }
}

</script>
