let {createStore} = require('vuex')
let axios = require('../shared/axios')
let UserPrototype = require('../shared/user')
const WsManager = require("../shared/ws");

const TOKEN_NAME = '__gl_auth_token__'

module.exports = createStore({
  state: {
    status: '',
    token: localStorage.getItem(TOKEN_NAME) || '',
    user: {},//JSON.parse(localStorage.getItem("__sg_user") || "{}") || {},
    countries: [],
    operators: [],
    operatorsRus: [],
    zones: [],
    operatorsGroups: [],
    operatorsGroupsRus: [],
    clients: [],
    refreshTimer: null,
    menuOpened: false,
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, payload) {
      state.status = 'success'
      state.token = payload.token
      localStorage.setItem(TOKEN_NAME, payload.token)
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = ''
      state.user.clear()
      state.menuOpened = false
    },
    setCountries(state, payload) {
      state.countries = payload.countries
    },
    setZones(state, payload) {
      state.zones = payload.zones
    },
    setOperatorsGroups(state, payload) {
      state.operatorsGroups = payload.operatorsGroups
        .filter(g => g.title.startsWith("РФ"))
        .sort((g1, g2) => g1.title.localeCompare(g2.title))
        .concat(
          payload.operatorsGroups.filter(g => !g.title.startsWith("РФ")).sort((g1, g2) => g1.title.localeCompare(g2.title))
        )
      state.operatorsGroupsRus = payload.operatorsGroups.filter(g => g.title.startsWith("РФ_"))
    },
    setOperators(state, payload) {
      state.operators = payload.operators
      state.operatorsRus = payload.operators.filter(o => o.mcc === "250")
    },
    setClients(state, payload) {
      state.clients = payload.clients
    },
    setUser(state) {
      state.user = new UserPrototype(
        JSON.parse(localStorage.getItem("__sg_user") || "{}") || {},
        JSON.parse(localStorage.getItem("__sg_user_privileges") || "[]") || {}
      )

    },
    changeMenuState(state) {
      state.menuOpened = !state.menuOpened
    }
  },
  actions: {
    loadCountries({commit}) {
      if (!this.getters.token) return
      axios.get("/resources/countries")
        .then(resp => {
          commit('setCountries', {countries: resp.data.sort((c1, c2) => c1.country_name.localeCompare(c2.country_name))})
        })
    },
    loadZones({commit}) {
      if (!this.getters.token) return
      axios.get("/resources/zones")
        .then(resp => {
          commit('setZones', {zones: resp.data.sort((c1, c2) => c1.name.localeCompare(c2.name))})
        })
    },
    loadOperatorsGroups({commit}) {
      if (!this.getters.token) return
      axios.get("/resources/operGroups")
        .then(resp => {
          commit('setOperatorsGroups', {operatorsGroups: resp.data})
        })
        .catch(console.error)

    },
    loadClients({commit}) {
      if (!this.getters.token) return
      axios.get("/admin/clients/getAll")
        .then(resp => {
          commit('setClients', {clients: resp.data.users})
        })
        .catch(console.error)

    },
    loadOperators({commit}) {
      if (!this.getters.token) return
      axios.get("/resources/operators")
        .then(resp => {
          commit('setOperators', {operators: resp.data})
        })
    },
    setAuthToken() {
      console.log("Setting auth token")
      axios.defaults.headers.common['Authorization'] = this.getters.token
    },
    setUser({commit}) {
      commit("setUser")
    },
    __afterLoginRefreshAction({commit}, resp) {
      const token = resp.data.token
      const user = resp.data.user
      commit('auth_success', {token})
      localStorage.setItem("__sg_user", JSON.stringify(user))
      localStorage.setItem("__sg_user_privileges", JSON.stringify(resp.data.userPrivileges || []))
      this.dispatch("__loadResources")
    },
    __loadResources({commit}) {
      this.dispatch('setAuthToken')
      this.dispatch('setUser')
      this.dispatch('loadCountries')
      this.dispatch('loadZones')
      this.dispatch('loadOperatorsGroups')
      this.dispatch('loadOperators')
      this.dispatch('loadClients')
    },
    connectWs({commit}, eventBus) {
      WsManager.linkStore(this)
      if (eventBus) WsManager.linkEventBus(eventBus)
      WsManager.connect()
    },
    refreshUser({commit}) {
      return new Promise((res, rej) => {
        axios.get("/auth/refreshUser")
          .then(resp => {
            this.dispatch("__afterLoginRefreshAction", resp)
            res()
          })
          .catch(rej)
      })
    },
    login({commit}, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios.post('/auth/login', user)
          .then(resp => {
            if (!resp.data.token || !resp.data.user) return reject()
            this.dispatch("__afterLoginRefreshAction", resp)
            resolve(resp)
          })
          .catch(err => {

            commit('auth_error')
            localStorage.removeItem(TOKEN_NAME)
            reject(err)
          })
      })
    },
    logout({commit}) {
      commit('logout')
      localStorage.removeItem(TOKEN_NAME)
      localStorage.removeItem("__sg_user")
      localStorage.removeItem("__sg_user_privileges")
      WsManager.disconnect()
      this.dispatch('setAuthToken')

    },
    toggleMenu({commit}) {
      if (window.innerWidth <= 1500)
        commit("changeMenuState")
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    token: state => state.token,
    bareToken: state => state.token.split(" ")[1],
    authStatus: state => state.status,
    user: state => state.user,
    userOwnPhone: state => state.user.info.phone,
    userOwnEmail: state => state.user.info.email,
    countries: state => state.countries,
    operatorsGroups: state => state.operatorsGroups,
    operatorsGroupsRus: state => state.operatorsGroupsRus,
    operators: state => state.operators,
    operatorsRus: state => state.operators,
    menuOpened: state => state.menuOpened,
    zones: state => state.zones,
    clients: state => state.clients
  }
})