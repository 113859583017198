<template>
  <div>
    <el-row style="margin-bottom: 20px">
      <el-col :span="24">
        <el-card class="box-card no-top-body-padding no-border">
          <el-form label-position="top" ref="searchForm" :model="search" size="small">
            <el-row :gutter="40">
              <el-col :xs="24" :md="8">
                <el-form-item label="Название" prop="token">
                  <el-input class="full-width" style="margin-bottom: 20px" placeholder="" clearable
                            v-model="search.token"/>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :md="8">
                <el-form-item label="Тип">
                  <el-select v-model="search.type" class="full-width" prop="type">
                    <el-option value="" label="Все"/>
                    <el-option value="0" label="Исходящие"/>
                    <el-option value="14" label="Звонки"/>
                    <el-option value="1" label="Входящие"/>
                    <el-option value="3" label="Оплата банковским переводом"/>
                    <el-option value="6" label="Робокасса"/>
                    <el-option value="8" label="Использование овердрафта"/>
                    <el-option value="13" label="Разовое списание"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :md="8">
                <el-form-item label="Активность" prop="isActive">
                  <el-select v-model="search.isActive" class="full-width">
                    <el-option value="" label="Все"/>
                    <el-option value="active" label="Активные"/>
                    <el-option-group label="Не активные">
                      <el-option value="all_inactive" label="Все неактивные"/>
                      <el-option value="delayed" label="Отложенная активация"/>
                      <el-option value="locked" label="Блокированые"/>
                      <el-option value="archived" label="Архив"/>
                    </el-option-group>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-button type="danger" @click="resetSearchForm">Сбросить</el-button>
        </el-card>
      </el-col>
    </el-row>
    <rate-form
        :visible="rateFormVisible"
        :editing-rate="editingRate"
        @reload="refresh"
        @close="rateFormVisible = false"
    />
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card">
          <el-button type="success" style="margin-bottom: 40px" @click="addRate">Добавить тариф</el-button>
          <TableSpinner :loading="loading">
            <admin-rates-table
                :rates="filteredRates"
                @editRate="editRate"
                @blockRate="blockRate"
                @archiveRate="archiveRate"
                @removeRate="removeRate"
                @sort="sortRates"
                @reload="refresh"
            />
          </TableSpinner>


        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import AdminRatesTable from "../../components/rating/admin-rates-table.component.vue";
import RateForm from "../../components/rating/rate-form.component.vue";
import moment from "moment";

export default {
  name: "AdminRates",
  title: "Тарифы",
  components: {
    RateForm,
    AdminRatesTable
  },
  watch: {
    "search.type": function(){
      setTimeout(() => {
        this.refreshFilteredRates()
      }, 1)

    },
    "search.isActive": function(){
      setTimeout(() => {
        this.refreshFilteredRates()
      }, 1)
    },
    "search.token": function(){
      clearTimeout(this.tokenInputDebounceTimer)
      this.tokenInputDebounceTimer = setTimeout(() => {
        console.log("DRAW CALLED")
        this.refreshFilteredRates()
      }, 300)

    }
  },
  methods: {
    async getAllRates() {
      let resp = await this.axios.get("/admin/finance/getAllRates")
      this.allRates = resp.data.map(r => {
        r.__activate_at = r.activateAt ? moment(r.activateAt, "DD.MM.YYYY").toDate().valueOf() : null
        return r
      }).sort((r1, r2) =>  r1.name.trim().localeCompare(r2.name.trim(), 'ru'))//.sort((r1, r2) => new Date(r2.date_created).valueOf() - new Date(r1.date_created).valueOf())
    },
    refresh() {
      this.loading = true
      Promise.all([
        this.getAllRates(),
      ]).then(() => {
        this.refreshFilteredRates()
        this.loading = false
      })
    },
    addRate() {
      this.editingRate = {}
      this.rateFormVisible = true
    },
    editRate(rate) {
      this.editingRate = rate
      this.rateFormVisible = true
    },
    archiveRate(rate, action) {
      this.axios.post("/admin/finance/archiveRate", {rateId: rate._id, action})
          .then(resp => {
            this.refresh()
          })
    },
    blockRate(rate, action) {
      this.axios.post("/admin/finance/lockRate", {rateId: rate._id, action})
          .then(resp => {
            this.refresh()
          })
    },
    removeRate(rate) {
      this.axios.delete("/admin/finance/removeRate", {params: {rateId: rate._id}})
          .then(resp => {
            this.refresh()
          })
    },
    resetSearchForm() {
      this.$refs.searchForm.resetFields()
      this.refreshFilteredRates()
    },
    refreshFilteredRates(){
      let now = new Date().valueOf()
      let rateDelayed = r => r.activateAt && r.__activate_at > now
      let rateActive = r => {
        return !(r.inArchive || r.locked || rateDelayed(r))
      }
      let searchRegex = new RegExp(escapeRegExp(this.search.token), "gi")
      this.filteredRates = this.allRates.filter(r => {
        searchRegex.lastIndex = 0
        if (this.search.token && !searchRegex.test(r.name) && !searchRegex.test(r.client_name)) return false
        if (this.search.type && String(r.type) !== this.search.type) return false
        if( this.search.isActive){
          if(this.search.isActive === "active" && !rateActive(r)) return false
          if(this.search.isActive === "all_inactive" && rateActive(r)) return false
          if(this.search.isActive === "delayed" && !rateDelayed(r)) return false
          if(this.search.isActive === "locked" && (!r.locked || r.inArchive )) return false
          if(this.search.isActive === "archived" && !r.inArchive) return false
        }
        return true
      })
      console.log("Update")
    },

  },

  computed: {

  },
  mounted() {
    this.refresh()
  },
  data() {
    return {
      loading: true,
      visiblePanel: "common",
      allRates: [],
      search: {
        token: "",
        type: "0",
        isActive: "active"
      },
      tokenInputDebounceTimer: null,
      rateFormVisible: false,
      editingRate: {},
      filteredRates: []
    }
  },
}

</script>