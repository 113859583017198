<template>

  <div style="padding: 15px 20px; border-bottom: 1px solid #eee;">

      <div style="display: flex; flex-direction: row">
        <el-input v-model="filterToken" placeholder="Поиск" style="flex-grow: 1" clearable/>
        <div v-if="$slots['new-dialog']">
        <slot name="new-dialog" :onDialogCreated="newDialogCreated"></slot>
        </div>
      </div>

      <el-progress
          v-show="dialogsLoading"
          :percentage="50"
          status="success"
          :show-text="false"
          :indeterminate="true"
          :duration="1"
          style="margin: 0px -20px;margin-top: 10px"
      />
  </div>


  <div id="dialog-triggers-container">
    <div
        :class="`dialog-trigger ${selectedDialog._id === dialog._id ? 'active': ''} ${dialog.hasUnreadMessages ? 'attention-dialog-trigger' : ''}`"
        v-for="dialog in dialogs"
        @click="selectDialog(dialog)"
    >
      <div class="default-flex-container full-width">
        <img v-if="avatarHref && typeof avatarHref === 'function'"
             :src="avatarHref(dialog)"
             style="width: 50px; height: 50px;border-radius: 50%; margin-right: 10px; display: flex"
        />
        <div class="default-flex-container"
             style="padding: 5px 0px;flex-direction: column; width:100%; align-items: initial">
          <span
              class="default-flex-container"
              style="font-size: 16px; font-weight: bold; margin: 0px;">
            <span class="dialog-text-preview">{{ title(dialog) }}</span>
            <span class="small-label" style="font-size: 12px">{{ lastMessageTime(dialog) }}</span>
          </span>

          <div class="small-label default-flex-container">
            <span class="dialog-text-preview" v-html="dialog.lastMessageText"></span>

            <div v-if="$slots['dialog-unread-notification']">
              <slot name="dialog-unread-notification" :dialog="dialog" />
            </div>

            <el-tag
                v-else
                type="danger" effect="dark" v-show="selectedDialog._id !== dialog._id && dialog.unreadCount > 0" style="height:20px; padding: 0px 5px;">
              {{ dialog.unreadCount }}
            </el-tag>
          </div>
        </div>
      </div>
    </div>

    <el-button
        type="success" @click="loadDialogs(true)"
        :loading="moreLoading" style="border-radius: 0px"
        v-show="dialogs.length >= 100"
    >Показать больше
    </el-button>

    <div v-if="$slots['new-dialog-bottom']" style="margin-top: auto">
      <slot name="new-dialog-bottom" :onDialogCreated="newDialogCreated"></slot>
    </div>

  </div>

</template>

<script>

import moment from "moment/moment";
import TableSpinner from "@shared/components/layout/table-spinner.component.vue";

export default {
  name: "DialogsSelect",
  components: {TableSpinner},
  props: [
      "modelValue",
    "loadDialogsMethod", "setMessagesReadMethod", "fetchDialogMethod", "avatarHref",
    "autoload", "titleFormatter"
  ],

  watch: {
    filterToken(v) {
      this.dialogsLoading = true
      if (!this.debounceTimer)
        this.debounceTimer = setTimeout(this.loadDialogs, 1500)
      else {
        clearTimeout(this.debounceTimer)
        this.debounceTimer = null
        this.debounceTimer = setTimeout(this.loadDialogs, 1500)
      }
    },
    "selectedDialog._id": function (v) {
      this.$emit("update:modelValue", this.selectedDialog)
    }
  },
  mounted() {
    if(this.autoload)
      this.loadDialogs()
  },
  methods: {
    title(dialog){
      return this.titleFormatter && typeof this.titleFormatter === "function" ? this.titleFormatter(dialog) : dialog.title
    },
    lastMessageTime(dt) {
      if (dt.isEmpty) return ""
      let asDate = moment(dt.lastMessageDate)
      if (asDate.format("DD.MM.YYYY") === moment().format("DD.MM.YYYY"))
        return asDate.format("HH:mm")
      else
        return asDate.format("DD.MM.YYYY HH:mm")
    },

    async onNewMessageReceived(msg, thisChatOpened){
      let di = this.dialogs.findIndex(d => d._id === msg.contactId || d._id === msg.chatId)
      if (di < 0) {
        if(!this.fetchDialogMethod || typeof this.fetchDialogMethod !== 'function') return
        let dResp = await this.fetchDialogMethod(msg.contactId)
        this.dialogs.unshift({...dResp.data, unreadCount: 1})
        di = 0
      }
      this.dialogs[di].lastMessageText = msg.text
      this.dialogs[di].lastMessageDate = msg.date_added
      if (msg.fromClient) {
        if (!thisChatOpened)
          this.incrementUnreadCount(di)
        this.dialogs[di].hasUnreadMessages = true
      }else
        this.dialogs[di].hasUnreadMessages = false
    },

    incrementUnreadCount(i) {
      if (i >= 0)
        this.dialogs[i].unreadCount++
    },

    loadDialogs(asMore = false) {
      this.dialogs = []
      this.dialogsLoading = true
      if (asMore)
        this.moreLoading = true


      this.loadDialogsMethod(this.filterToken, asMore ? this.dialogs.length : 0)
          .then(resp => {
            this.dialogsLoading = false
            this.moreLoading = false
            let newDialogs = resp.data.map(d => {
              d.unreadCount = 0
              return d
            })
            if (asMore) {
              this.dialogs = this.dialogs.concat(newDialogs.filter(d => !this.dialogs.find(ed => ed._id === d._id)))
            } else {
              this.dialogs = newDialogs
              if (this.debounceTimer === null) {
                if (newDialogs.length)
                  this.selectDialog(newDialogs[0])
                else
                  this.dropDialog()
              }
            }
            this.debounceTimer = null
          })
          .catch(e => {
            console.log(e)
            this.debounceTimer = null
            this.dialogsLoading = false
            this.moreLoading = false
          })
    },
    selectDialog(dialog) {
      this.$emit("dialogClicked")
      if (dialog._id === this.selectedDialog._id) return

      this.selectedDialog = dialog
      if(this.setMessagesReadMethod && typeof this.setMessagesReadMethod === "function")
        this.setMessagesReadMethod(dialog)
            .then(() => {
              this.dialogs[this.dialogs.findIndex(d => d._id === dialog._id)].unreadCount = 0
            })

    },
    dropDialog() {
      this.selectedDialog._id = ""
    },
    newDialogCreated(newDialog){
      if (!this.dialogs.find(d => d._id === newDialog._id))
        this.dialogs.unshift(newDialog)
      this.selectDialog(newDialog)
    },
  },
  data() {
    return {
      dialogsLoading: false,

      selectedDialog: {
        _id: "",
        title: "",
        unreadCount: 0,
        isEmpty: false
      },
      filterToken: "",

      dialogs: [],
      moreLoading: false,
      debounceTimer: null
    }
  }
}

</script>

<style scoped>

#dialog-triggers-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow-y: scroll;
}

.dialog-trigger {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer
}
.dialog-trigger.attention-dialog-trigger:not(.active){
  background: var(--el-color-warning-lighter);
}


.dialog-trigger.active {
  background: #edf2f7;
}

.dialog-trigger:not(.active):hover {
  background: #e9f6f9;
}


.dialog-text-preview {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
  display: flex;
  flex-grow: 1;
}
</style>