let {createWebHistory, createRouter} = require('vue-router')
let store = require('./store')
let AuthLayout = require('@shared/layouts/auth.layout').default
let CabLayout = require('@/layouts/cabinet.layout').default

const routes = [
  {
    path: "",
    meta: {redirectToCabIfAuthenticated: true},
    redirect: {path: "/login"}
  },
  {
    path: "/",
    meta: {redirectToCabIfAuthenticated: true},
    redirect: {path: "/login"}
  },
  {
    path: '/login',
    component: AuthLayout,
    name: "login",
    children: [
      {
        path: '',
        meta: {redirectToCabIfAuthenticated: true},
        component: require('@/views/login.view').default
      }
    ]
  },
  {
    path: '/resetPassword',
    component: AuthLayout,
    name: "resetPass",
    children: [
      {
        path: '',
        meta: {redirectToCabIfAuthenticated: true},
        component: require('@shared/views/resetPassword.view').default
      }
    ]
  },

  {
    path: "/cab/lk",
    component: CabLayout,
    children: [
      {
        path: "tg_bot",
        name: "tg_bot",
        component: require('@/views/lk/tg-bot.view.vue').default,
        meta: {
          requiresAuth: true,
        }
      }
    ]
  },
  {
    path: "/cab/clients",
    component: CabLayout,
    children: [
      {
        path: "all",
        name: "all",
        component: require('@/views/clients/all.view').default,
        meta: {
          requiresAuth: true,
        }
      }
    ]
  },
  {
    path: "/cab/system_reports",
    component: CabLayout,
    children: [
      // {
      //   path: "authorizations",
      //   name: "authorizations",
      //   component: require('@/views/reports/authorizations.view').default,
      //   meta: {
      //     requiresAuth: true,
      //   }
      // },
      // {
      //   path: "event_log",
      //   name: "event_log",
      //   component: require('@/views/reports/event-log.view').default,
      //   meta: {
      //     requiresAuth: true,
      //   }
      // }
    ]
  },
  {
    path: "/cab/reports",
    component: CabLayout,
    children: [
      {
        path: "sent_messages",
        name: "sent_messages",
        component: require('@/views/reports/sent-messages.view').default,
        meta: {
          requiresAuth: true,
        }
      },
      // {
      //   path: "calls",
      //   name: "calls",
      //   component: require('@/views/reports/sent-calls.view').default,
      //   meta: {
      //     requiresAuth: true,
      //   }
      // },
      // {
      //   path: "inbox",
      //   name: "reports_inbox",
      //   component: require('@/views/reports/inbox-report.view').default,
      //   meta: {
      //     requiresAuth: true,
      //   }
      // }
    ]
  },
  {
    path: "/cab/support",
    component: CabLayout,
    children: [
      {
        path: "sender_names_requests",
        name: "sender_names_requests",
        component: require('@/views/support/sender-names-requests.view').default,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "sent_emails",
        name: "sent_emails",
        component: require('@/views/support/email-report.view.vue').default,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "client_notifications",
        name: "client_notifications",
        component: require('@/views/support/client-notifications.view.vue').default,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "support_settings",
        name: "support_settings",
        component: require('@/views/support/support-settings.view.vue').default,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "client_requests",
        name: "client_requests",
        component: require('@/views/support/client-requests.view.vue').default,
        meta: {
          requiresAuth: true,
        }
      },
    ]
  },
  {
    path: "/cab/routing",
    component: CabLayout,
    children: [
      {
        path: "traffic_schemas",
        name: "traffic_schemas",
        component: require('@/views/routing/traffic-schemas.view').default,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "agregators",
        name: "agregators",
        component: require('@/views/routing/agregators.view').default,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "prefixes",
        name: "prefixes",
        component: require('@/views/routing/prefixes.view').default,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "operators",
        name: "operators",
        component: require('@/views/routing/operators.view').default,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "international_names",
        name: "international_names",
        component: require('@/views/routing/international-names.view').default,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "sender_substitutions",
        name: "sender_substitutions",
        component: require('@/views/routing/sender-substitutions.view').default,
        meta: {
          requiresAuth: true,
        }
      }

    ]
  },
  {
    path: "/cab/finance",
    component: CabLayout,
    children: [
      {
        path: "settings",
        name: "settings",
        component: require('@/views/finance/settings.view').default,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "balances",
        name: "balances",
        component: require('@/views/finance/balances-report.view').default,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "payment_gateways",
        name: "payment_gateways",
        component: require('@/views/finance/gateway-report.view').default,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "documents",
        name: "documents",
        component: require('@/views/finance/documents.view').default,
        meta: {
          requiresAuth: true,
        }
      },
    ]
  },
  {
    path: "/cab/rating",
    component: CabLayout,
    children: [
      {
        path: "rates",
        name: "rates",
        component: require('@/views/rating/rates.view').default,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "service_templates",
        name: "service_templates",
        component: require('@/views/rating/service-templates.view').default,
        meta: {
          requiresAuth: true,
        }
      },
    ]
  },
  {
    path: "/cab/moderation",
    component: CabLayout,
    children: [
      {
        path: "rules",
        name: "rules",
        component: require('@/views/moderation/rules.view').default,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "queue",
        name: "queue",
        component: require('@/views/moderation/queue.view').default,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "settings",
        name: "modSettings",
        component: require('@/views/moderation/notification-settings.view').default,
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: "blacklist",
        name: "blacklist",
        component: require('@shared/views/messages/blacklist.view').default,
        meta: {
          requiresAuth: true,
        }
      },
    ]
  },
  {
    path: "/cab/agency_network",
    component: CabLayout,
    children: [
      {
        path: "index",
        name: "index",
        component: require('@/views/agencyNet/index.view.vue').default,
        meta: {
          requiresAuth: true,
        }
      }
    ]
  },
  { path: '/:pathMatch(.*)*', redirect: {path: "/login"} }
]

let router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  if(
    to.matched.some(record => record.meta && record.meta.redirectToCabIfAuthenticated) &&
    store.getters.isLoggedIn &&
    !(to.query.token && to.path === "/login")
  ) return next('/cab/clients/all')
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) return next('/login')

  }
  next()


})

module.exports = router
