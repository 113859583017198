<template>
  <div>
    <el-dialog
        :title="formTitle"
        v-model="operatorFormVisible"
        class="modal-95-70-60"
    >
      <el-form label-position="top" ref="operatorForm" :model="operatorForm">
        <el-form-item prop="_id" style="display: none">
          <el-input v-model="operatorForm._id"/>
        </el-form-item>
        <el-form-item prop="name" label="Наименование">
          <el-input v-model="operatorForm.name"/>
        </el-form-item>
        <el-form-item prop="code" label="Код">
          <el-input v-model="operatorForm.code"/>
        </el-form-item>
        <el-form-item prop="mcc" label="MCC">
          <el-input v-model="operatorForm.mcc"/>
        </el-form-item>
        <el-form-item prop="mnc" label="MNC">
          <el-input v-model="operatorForm.mnc"/>
        </el-form-item>
        <el-form-item prop="mnc_smsc" label="MNC (SMSC)">
          <el-input v-model="operatorForm.mnc_smsc"/>
        </el-form-item>
        <el-form-item prop="group" label="Группа">
          <el-select v-model="operatorForm.group" class="full-width" filterable>
            <el-option value="" label=""/>
            <el-option v-for="g in groups" :value="g._id" :label="g.title"/>
          </el-select>
        </el-form-item>
        <el-form-item prop="type" label="Тип">
          <el-radio-group v-model="operatorForm.type">
            <el-radio label="classic">Обычный</el-radio>
            <el-radio label="vk">VK/OK</el-radio>
            <el-radio label="telegram">Telegram</el-radio>
            <el-radio label="telegramAuth">Telegram Auth Codes</el-radio>
            <el-radio label="whatsapp">Whatsapp</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="saveOperator()" :loading="saving">Сохранить</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-form label-position="top" ref="searchForm" :model="search">
      <el-row :gutter="40">
        <el-col :xs="24" :md="8">
          <el-form-item label="Поиск" prop="token">
            <el-input class="full-width" placeholder="" clearable
                      v-model="search.token"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="8">
          <el-form-item label="Группа">
            <el-select v-model="search.group" class="full-width" filterable>
              <el-option value="" label="Все"/>
              <el-option value="n/a" label="Не назначенные"/>
              <el-option v-for="g in groups" :value="g._id" :label="g.title"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="8">
          <el-form-item label="  ">
            <!--                <el-button type="danger" @click="resetSearchForm">Сбросить</el-button>-->
            <el-button type="success" @click="showAddEditForm(null)">Добавить оператора</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-table :data="visibleOperators">
      <el-table-column label="Название" prop="OrgName" min-width="200"/>
      <el-table-column label="Код" prop="OrgCode" min-width="100"/>
      <el-table-column label="MCC" prop="mcc" min-width="80"/>
      <el-table-column label="MNC" prop="MNC" min-width="80"/>
      <el-table-column label="MNC (SMSc)" prop="mnc_smsc" min-width="80"/>
      <el-table-column label="Группа" min-width="140">
        <template #default="scope">
          {{ operGroupName(scope.row) }}
        </template>
      </el-table-column>
      <el-table-column label="" min-width="120">
        <template #default="scope">
          <el-button text class="table-text-button" size="small" @click="showAddEditForm(scope.row)"
                     style="margin-right: 10px">
            Изменить
          </el-button>
          <remove-button @confirm="removeOperator(scope.row)">Удалить</remove-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="solo-pagination-container">
      <el-pagination background layout="sizes, prev, pager, next" :total="filteredOperators.length"
                     class="wide-page-size"
                     :page-sizes="[20, 50, 100]" :page-size="operPageSize" :pager-count="3" :hide-on-single-page="true"
                     @size-change="s => operPageSize = s" @current-change="p => operCurrentPage = p"></el-pagination>
    </div>

  </div>
</template>
<script>


import TimeSearch from "@shared/components/reports/time-search.component"
import moment from "moment"
import RemoveButton from "@shared/components/layout/removeButton.component.vue";

export default {
  name: "OperatorsTableAndForm",
  components: {
    RemoveButton,
    TimeSearch
  },
  props: ["operators", "groups"],
  methods: {
    resetSearchForm() {
      this.search.token = ""
      this.search.group = ""
    },
    operGroupName(operator) {
      let g = this.groups.find(g => g._id === operator.group)
      return g ? g.title : "N/A"
    },
    saveOperator() {
      this.saving = true
      this.axios.post("/admin/routing/saveOperator", {data: this.operatorForm})
          .then(resp => {
            this.saving = false
            this.$refs.operatorForm.resetFields()
            this.operatorFormVisible = false
            this.$emit("reload")
          })
    },
    removeOperator(operator) {
      this.axios.delete("/admin/routing/removeOperator", {params: {operatorId: operator._id}})
          .then(() => {
            this.$emit("reload")
          })
    },
    showAddEditForm(operator) {
      console.log(operator)
      if (!operator && this.$refs.operatorForm) this.$refs.operatorForm.resetFields()
      if (operator) {
        this.operatorForm._id = operator._id
        this.operatorForm.name = operator.OrgName
        this.operatorForm.code = operator.OrgCode
        this.operatorForm.mcc = operator.mcc
        this.operatorForm.mnc = operator.MNC
        this.operatorForm.mnc_smsc = operator.mnc_smsc
        this.operatorForm.group = operator.group
        this.operatorForm.type = operator.type || "classic"
      }
      this.operatorFormVisible = true
    }
  },
  computed: {
    filteredOperators() {
      let searchRegex = null
      if (this.search.token.trim()) searchRegex = new RegExp(this.search.token.trim(), "gi")
      return this.operators.filter(o => {
        if (this.search.group === 'n/a' && o.group !== "") return false
        if (this.search.group && this.search.group !== 'n/a' && this.search.group !== o.group) return false
        if (searchRegex) {
          if (!searchRegex.test(o.OrgName) && !searchRegex.test(o.mcc) && !searchRegex.test(o.MNC) && !searchRegex.test(o.mnc_sms) && !searchRegex.test(o.OrgCode)) return false
          searchRegex.lastIndex = 0
        }
        return true
      })
    },
    visibleOperators() {
      return this.filteredOperators.slice((this.operCurrentPage - 1) * this.operPageSize, this.operCurrentPage * this.operPageSize)
    },
    formTitle() {
      return this.operatorForm._id ? "Редактирование оператора" : "Новый оператор"
    }
  },

  data() {
    return {
      operPageSize: 50, operCurrentPage: 1,
      operatorFormVisible: false,
      saving: false,
      operatorForm: {
        _id: "",
        name: "",
        code: "",
        mcc: "",
        mnc: "",
        mnc_smsc: "",
        group: "",
        type: "classic"
      },
      search: {
        token: "",
        group: ""
      }
    }
  }
}

</script>