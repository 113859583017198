<template>

  <el-row>
    <el-col :span="24">
      <el-row>
        <el-col :span="24">
          <div id="balance-container">
            <div style="border-left: 3px solid #ffcc01;display: flex; align-items: center;">
              <div style="padding-left:20px">
                <font-awesome-icon icon="info-circle" style="color: #ffcc01;"></font-awesome-icon>
              </div>
              <div style=" color: #FFF;">
              <span style="margin-left: 20px; font-size:13px; color: white">Пользователь: {{
                  $store.getters.user.systemId(true)
                }}</span><br/>

              </div>
            </div>
          </div>
          <el-divider class="dark-divider"></el-divider>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-menu
              active-text-color="#f53a33"
              background-color="#31373a"
              class="main-gold-menu"
              text-color="#fff"
              ref="main-nav-menu"
              :default-active="activeItem"
              mode="vertical"
              :collapse="false"
              @open="handleOpen"
          >


            <el-sub-menu index="1" data-test="--menu-lk">
              <template #title>
                <font-awesome-icon icon="home" class="menu-primary-icon"></font-awesome-icon>
                <span class="menu-heading-with-icon">Личный кабинет</span>
              </template>
              <router-link to="/cab/lk/tg_bot" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="1-1">Бот Telegram</el-menu-item>
              </router-link>
            </el-sub-menu>
            <el-sub-menu index="2">
              <template #title>
                <font-awesome-icon icon="user" class="menu-primary-icon"></font-awesome-icon>
                <span class="menu-heading-with-icon">Клиенты</span>
              </template>
              <router-link to="/cab/clients/all" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="2-1">Мои клиенты</el-menu-item>
              </router-link>
            </el-sub-menu>

            <el-sub-menu index="3">
              <template #title>
                <font-awesome-icon icon="chart-bar" class="menu-primary-icon"></font-awesome-icon>
                <span class="menu-heading-with-icon">Отчеты</span>
              </template>
              <router-link to="/cab/reports/sent_messages" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="3-1">Отправленные сообщения</el-menu-item>
              </router-link>
              <!--                          <router-link to="/cab/reports/calls" class="no-style-link" @click="$store.dispatch('toggleMenu')">-->
              <!--                            <el-menu-item index="3-2">Детализация звонков</el-menu-item>-->
              <!--                          </router-link>-->
              <!--                          <router-link to="/cab/reports/inbox" class="no-style-link" @click="$store.dispatch('toggleMenu')">-->
              <!--                            <el-menu-item index="3-3">Входящие сообщения</el-menu-item>-->
              <!--              </router-link>-->
            </el-sub-menu>

            <!--            <el-sub-menu index="4">-->
            <!--              <template #title>-->
            <!--                <font-awesome-icon icon="edit" class="menu-primary-icon"></font-awesome-icon>-->
            <!--                <span class="menu-heading-with-icon">Системные отчеты</span>-->
            <!--              </template>-->
            <!--              <router-link to="/cab/system_reports/authorizations" class="no-style-link"-->
            <!--                           @click="$store.dispatch('toggleMenu')">-->
            <!--                <el-menu-item index="4-1">Отчет по авторизациям</el-menu-item>-->
            <!--              </router-link>-->
            <!--              <router-link to="/cab/system_reports/event_log" class="no-style-link"-->
            <!--                           @click="$store.dispatch('toggleMenu')">-->
            <!--                <el-menu-item index="4-2">Журнал событий</el-menu-item>-->
            <!--              </router-link>-->
            <!--            </el-sub-menu>-->

            <el-sub-menu index="5">
              <template #title>
                <font-awesome-icon icon="credit-card" class="menu-primary-icon"></font-awesome-icon>
                <span class="menu-heading-with-icon">Финансы</span>
              </template>
              <router-link to="/cab/finance/settings" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="5-1">Настройки</el-menu-item>
              </router-link>
              <router-link to="/cab/finance/balances" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="5-2">Остатки на счетах</el-menu-item>
              </router-link>
              <router-link to="/cab/finance/payment_gateways" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="5-3">Платежные шлюзы</el-menu-item>
              </router-link>

              <router-link to="/cab/finance/documents" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="5-4">Документы</el-menu-item>
              </router-link>

            </el-sub-menu>

            <el-sub-menu index="6">
              <template #title>
                <font-awesome-icon icon="life-ring" class="menu-primary-icon"></font-awesome-icon>
                <span class="menu-heading-with-icon">Поддержка</span>
              </template>
              <router-link to="/cab/support/sender_names_requests" class="no-style-link"
                           @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="6-1">Имена отправителя</el-menu-item>
              </router-link>
              <router-link to="/cab/support/sent_emails" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="6-2">Отправленные е-мейлы</el-menu-item>
              </router-link>
              <router-link to="/cab/support/client_notifications" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="6-5">Уведомления клиентам</el-menu-item>
              </router-link>
              <router-link to="/cab/support/support_settings" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="6-6">Настройки</el-menu-item>
              </router-link>
<!--              <router-link to="/cab/support/admin_requests" class="no-style-link" @click="$store.dispatch('toggleMenu')">-->
<!--                <el-menu-item index="6-3">Административные запросы</el-menu-item>-->
<!--              </router-link>-->
              <router-link to="/cab/support/client_requests" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="6-4">Запросы от клиентов</el-menu-item>
              </router-link>
            </el-sub-menu>
            <el-sub-menu index="7">
              <template #title>
                <font-awesome-icon icon="sliders-h" class="menu-primary-icon"></font-awesome-icon>
                <span class="menu-heading-with-icon">Маршрутизация</span>
              </template>
              <router-link to="/cab/routing/traffic_schemas" class="no-style-link"
                           @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="7-1">Схема трафика</el-menu-item>
              </router-link>
              <router-link to="/cab/routing/agregators" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="7-2">Агрегаторы</el-menu-item>
              </router-link>
              <router-link to="/cab/routing/prefixes" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="7-3">Префиксы</el-menu-item>
              </router-link>
              <router-link to="/cab/routing/operators" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="7-4">Операторы</el-menu-item>
              </router-link>
              <router-link to="/cab/routing/international_names" class="no-style-link"
                           @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="7-5">Международные имена</el-menu-item>
              </router-link>
              <router-link to="/cab/routing/sender_substitutions" class="no-style-link"
                           @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="7-6">Списки подмены отправителя</el-menu-item>
              </router-link>
            </el-sub-menu>
            <el-sub-menu index="8">
              <template #title>
                <font-awesome-icon icon="mobile" class="menu-primary-icon"></font-awesome-icon>
                <span class="menu-heading-with-icon">Тарификация</span>
              </template>
              <router-link to="/cab/rating/rates" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="8-1">Тарифы</el-menu-item>
              </router-link>
              <router-link to="/cab/rating/zones" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="8-2">Зоны</el-menu-item>
              </router-link>
              <router-link to="/cab/rating/service_templates" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="8-3">Сервисные шаблоны</el-menu-item>
              </router-link>

            </el-sub-menu>

            <el-sub-menu index="9">
              <template #title>
                <font-awesome-icon icon="stop-circle" class="menu-primary-icon"></font-awesome-icon>
                <span class="menu-heading-with-icon">Модерация</span>
              </template>
              <router-link to="/cab/moderation/rules" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="9-1">Правила</el-menu-item>
              </router-link>
              <router-link to="/cab/moderation/queue" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="9-2">Очередь сообщений</el-menu-item>
              </router-link>
              <router-link to="/cab/moderation/settings" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="9-3">Настройки</el-menu-item>
              </router-link>
              <router-link to="/cab/moderation/blacklist" class="no-style-link" @click="$store.dispatch('toggleMenu')">
                <el-menu-item index="9-4">Глобальный стоп-лист</el-menu-item>
              </router-link>

            </el-sub-menu>

            <el-menu-item index="11" @click="$router.push({path: '/cab/agency_network/index'})"
                          style="padding-left: 23px">
              <font-awesome-icon icon="sitemap" class="menu-primary-icon"></font-awesome-icon>
              <span class="menu-heading-with-icon">Агентская сеть</span>
            </el-menu-item>


            <el-menu-item index="10" @click="$store.dispatch('logout') && $router.push({path: '/login'})"
                          id="logout-item" style="padding-left: 23px">
              <font-awesome-icon icon="sign-out-alt" class="menu-primary-icon"></font-awesome-icon>
              <span class="menu-heading-with-icon">Выйти</span>
            </el-menu-item>

          </el-menu>
        </el-col>
      </el-row>
    </el-col>
  </el-row>

</template>


<script>

const NUM_MAIN_MENU_ELEMENTS = 8

const MENU_MAPPINGS = {
  'lk': {
    'tg_bot': '1-1',
  },
  'clients': {
    'all': '2-1',
  },
  'reports': {
    'sent_messages': '3-1',
    'inbox': '3-3'
  },
  'system_reports': {
    'authorizations': '4-1',
    'event_log': '4-2'
  },
  'finance': {
    'settings': '5-1',
    'balances': '5-2',
    'payment_gateways': '5-3',
    'documents': '5-4',
  },
  'support': {
    'sender_names_requests': '6-1',
    'sent_emails': '6-2',
    // 'admin_requests': '6-3',
    'client_requests': '6-4',
    'client_notifications': '6-5',
    'support_settings': '6-6',
  },
  'routing': {
    'traffic_schemas': '7-1',
    'agregators': '7-2',
    'prfixes': '7-3',
    'operators': '7-4',
    'international_names': '7-5',
    'sender_substitutions': '7-6'
  },
  'rating': {
    'rates': '8-1',
    'zones': '8-2',
    'service_templates': '8-3',
  },
  'moderation': {
    'rules': '9-1',
    'queue': '9-2',
    'settings': '9-3',
    'blacklist': '9-4'
  },
  'agency_net': {
    'index': '11'
  }
}

export default {
  name: 'Menu',
  watch: {
    $route() {
      this.updateActiive()
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      for (let i = 0; i < NUM_MAIN_MENU_ELEMENTS; i++)
        if (String(i + 1) !== key)
          this.$refs['main-nav-menu'].close(String(i + 1))
    },
    updateActiive() {
      let path = window.location.pathname
      let prime = path.split("/")[2]
      let sec = path.split("/")[3]
      this.activeItem = (MENU_MAPPINGS[prime] || {})[sec]
    }
  },
  created() {
    this.updateActiive()
  },
  data() {
    return {
      activeItem: '1'
    }
  }
}

</script>

<style>

.main-gold-menu {
  padding: 20px !important;
}

.main-gold-menu:not(.el-menu--collapse) {
  min-width: 320px;
}

.el-menu.main-gold-menu {
  border: none !important;
}

.el-sub-menu__title,
#logout-item {
  height: 46px !important;
  font-size: 16px !important;
}

.el-sub-menu .el-menu .el-menu-item {
  height: 30px
}

.main-gold-menu .el-sub-menu {
  border-left: 3px solid transparent;
}

.main-gold-menu .el-sub-menu.is-opened {
  border-left: 3px solid var(--el-menu-active-color);
}

.main-gold-menu .menu-heading-with-icon {
  margin-left: 20px
}

.dark-divider {
  border-top: 1px solid #3f4649;
}

.menu-number-badge {
  margin-left: 5px;
  height: auto !important;
  line-height: initial !important;
  padding: 5px;

}

.el-sub-menu .el-menu-item {
  padding-left: 56px !important;
}
</style>