<template>
  <div>
    <el-dialog v-model="addContractModalVisible"
               title="Загрузить договор"
               class="modal-95-70-60"
    >
      <el-row :gutter="20">
        <el-col :md="24" :xs="24">
          <el-form label-position="top" :model="newContractForm" ref="newContractForm">
            <el-form-item label="Контрагент" prop="userId">
              <client-select v-model="newContractForm.userId"  :hide-inactive="true"/>
<!--              <el-select v-model="newContractForm.userId" class="full-width" filterable>-->
<!--                <el-option-->
<!--                    v-for="client in $store.getters.clients"-->
<!--                    :label="client.system_id" :value="client.system_id"/>-->
<!--              </el-select>-->
            </el-form-item>
            <el-form-item label="Наименование" prop="name">
              <el-input v-model="newContractForm.name"/>
            </el-form-item>
            <el-form-item label="Статус" prop="status">
              <el-select v-model="newContractForm.status" class="full-width">
                <el-option label="Завершен" value="ended"/>
                <el-option label="Действует" value="active"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Вид договора" prop="type">
              <el-select v-model="newContractForm.type" class="full-width">
                <el-option label="Копия" value="copy"/>
                <el-option label="Оригинал" value="original"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Выберите файл" prop="file">
              <el-upload
                  :auto-upload="false" style="margin-top:15px"
                  v-model:file-list="newContractFiles"
              >
                <template #trigger>
                  <el-button type="primary" size="small">Загрузить файлы</el-button>
                </template>
              </el-upload>
            </el-form-item>
          </el-form>
          <el-button type="success" @click="createNewContract" style="margin-top: 30px" :loading="contractCreating" :disabled="!newContractFiles.length">
            Создать контракт
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog v-model="addActModalVisible"
               title="Загрузить закрывающий документ"
               class="modal-95-70-60"
    >
      <el-row :gutter="20">
        <el-col :md="24" :xs="24">
          <el-form label-position="top" :model="newActForm" ref="newActForm">
            <el-form-item label="Контрагент" prop="userId">
              <client-select v-model="newActForm.userId" :hide-inactive="true" />
            </el-form-item>
            <el-form-item label="Наименование" prop="name">
              <el-input v-model="newActForm.name"/>
            </el-form-item>
            <el-form-item label="За период" prop="pariod">
              <el-select v-model="newActForm.period" class="full-width">
                <el-option
                    v-for="month in static.months"
                    :key="month.value"
                    :label="month.label"
                    :value="month.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="Тип" prop="type">
              <el-select v-model="newActForm.type" class="full-width">
                <el-option label="Комплект" value="0"/>
                <el-option label="Акт" value="1"/>
                <el-option label="С/ф" value="2"/>
                <el-option label="УПД" value="3"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Номер акта" prop="actNumber">
              <el-input v-model="newActForm.actNumber"/>
            </el-form-item>
            <el-form-item label="Сумма акта" prop="actValue">
              <el-input v-model="newActForm.actValue"/>
            </el-form-item>
            <el-form-item label="Отправить сразу" prop="immediateSend">
              <el-switch v-model="newActForm.immediateSend"/>
            </el-form-item>
            <el-form-item label="Выберите файл" prop="file">
              <el-upload
                  :auto-upload="false" style="margin-top:15px"
                  v-model:file-list="newActFiles"
              >
                <template #trigger>
                  <el-button type="primary" size="small">Загрузить файлы</el-button>
                </template>
              </el-upload>
            </el-form-item>
          </el-form>
          <el-button type="success" @click="createNewAct" style="margin-top: 30px" :loading="actCreating" :disabled="!newActFiles.length">
            Загрузить документ
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>


    <el-row :gutter="20">
      <el-col :span="24">
        <el-tabs v-model="visiblePanel" class="primary-heading-tabs">
          <el-tab-pane label="Закрывающие документы" name="acts">
            <el-form label-position="top" :model="actsSearchForm" ref="actsSearchForm">
              <el-row :gutter="20">
                <el-col :md="12" :xs="24">
                  <TimeSearch
                      :month="true"
                      :free-date="true"
                      ref="actsSearchTime"
                  />
                  <el-form-item label="Пользователь" prop="userId">
                    <client-select v-model="actsSearchForm.userId" />
<!--                    <el-select v-model="actsSearchForm.userId" class="full-width" filterable>-->
<!--                      <el-option label="Все" value=""/>-->
<!--                      <el-option-->
<!--                          v-for="client in $store.getters.clients"-->
<!--                          :label="client.system_id" :value="client.system_id"/>-->
<!--                    </el-select>-->
                  </el-form-item>
                </el-col>
                <el-col :md="12" :xs="24">

                  <el-form-item label="Статус" prop="status">
                    <el-select v-model="actsSearchForm.status" class="full-width">
                      <el-option label="Все" value=""/>
                      <el-option label="Отправлено по e-mail" value="email"/>
                      <el-option label="Отправлено по e-mail + ЭДО" value="email_edo"/>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Номер документа" prop="docNumber">
                    <el-input v-model="actsSearchForm.docNumber"/>
                  </el-form-item>
                  <el-button type="success" style="margin-top:30px" @click="filterActs">Отфильтровать</el-button>
                  <el-button type="danger" style="margin-top:30px" @click="resetActsSearchForm">Сбросить</el-button>
                </el-col>
              </el-row>
            </el-form>

            <el-divider class="blue-divider"/>

            <TableSpinner :loading="loading">

              <el-table :data="visibleActs">
                <el-table-column label="Пользователь" prop="user_id" min-width="140"/>
                <el-table-column label="Номер акта" prop="act_number" min-width="140"/>
                <el-table-column :formatter="r => formatDate(r.date)" label="Дата" min-width="180"/>
                <el-table-column label="Сумма акта" prop="act_value" min-width="140"/>
                <el-table-column label="Тип контрагента" :formatter="docUserType" min-width="140"/>
                <el-table-column label="Компания" :formatter="docUserCompany" min-width="160"/>
                <el-table-column :formatter="actStatus" label="Статус" min-width="140"/>
                <el-table-column label=" " width="120">
                  <template #default="scope">
                    <a :href="`${axios.defaults.baseURL}/finance/downloadDocument?documentId=${scope.row._id}&token=${$store.getters.bareToken}`"
                       target="_blank">
                      <el-button text class="text-button" size="small" style="min-height: auto; padding: 0px">
                        <font-awesome-icon icon="external-link-alt" class="el-icon--left"></font-awesome-icon>
                        Скачать
                      </el-button>
                    </a>
                    <el-button text class="text-button table-text-button" size="small" :loading="documentSending"
                               @click="sendDocument(scope.row)">
                      <font-awesome-icon icon="envelope" class="el-icon--left"></font-awesome-icon>
                      Отправить
                    </el-button>
                    <remove-button @confirm="removeDocument(scope.row)" style="margin: 0px" :loading="documentSending">
                      Удалить
                    </remove-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="solo-pagination-container">

                <el-button type="success" @click="showActUploadForm" size="small" style="margin-right: 20px">Загрузить закрывающий документ
                </el-button>
                <el-button text class="text-button table-text-button" @click="exportActs">
                  <font-awesome-icon icon="external-link-alt"/>&nbsp;Экспортировать
                </el-button>

                <el-pagination background layout="sizes, prev, pager, next" :total="filteredActs.length"
                               class="wide-page-size" :page-sizes="[20, 50, 100]" :page-size="actsPageSize"
                               :pager-count="3" :hide-on-single-page="true" @size-change="ps => actsPageSize= ps"
                               @current-change="cp => actsPage = cp"
                >
                </el-pagination>
              </div>
              <el-divider class="blue-divider"/>
              <div style="width: 100px; display: flex; justify-content: space-between; width:100%">


                <el-button style="margin-left: 0px; margin-top: 20px" type="primary"
                           @click="generatePreviousMonthDocuments" size="small" :loading="actsActionsDisabled">
                  Сгенерировать документы за прошлый
                  месяц
                </el-button>
                <el-button style="margin-left: 0px; margin-top: 20px" type="warning"
                           @click="sendPreviousMonthDocuments" size="small" :loading="actsActionsDisabled">Отправить
                  документы за прошлый месяц
                </el-button>
              </div>
            </TableSpinner>
          </el-tab-pane>


          <el-tab-pane label="Договора" name="contracts">

            <el-form label-position="top">
              <el-form-item label="Свободный поиск" prop="token">
                <el-input class="full-width" style="margin-bottom: 20px" placeholder="" clearable
                          v-model="contractsSearchToken"/>
              </el-form-item>
            </el-form>

            <TableSpinner :loading="loading">
              <el-table :data="visibleContracts">
                <el-table-column label="Пользователь" prop="user_id" min-width="140"/>
                <el-table-column label="Тип контрагента" :formatter="docUserType" min-width="140"/>
                <el-table-column label="Наименование" prop="title" min-width="140"/>
                <el-table-column :formatter="r => formatDate(r.date)" label="Дата создания" min-width="180"/>
                <el-table-column :formatter="docStatus" label="Статус" min-width="140"/>
                <el-table-column :formatter="docType" label="Вид документа" min-width="120"/>
                <el-table-column label=" " width="100">
                  <template #default="scope">
                    <a :href="`${axios.defaults.baseURL}/finance/downloadDocument?documentId=${scope.row._id}&token=${$store.getters.bareToken}`"
                       target="_blank">
                      <el-button text class="text-button" size="small" style="min-height: auto; padding: 0px">
                        <font-awesome-icon icon="external-link-alt" class="el-icon--left"></font-awesome-icon>
                        Скачать
                      </el-button>
                    </a>
                    <remove-button @confirm="removeDocument(scope.row)" :loading="documentSending">Удалить
                    </remove-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="solo-pagination-container">
                <el-button type="success" @click="showContractUploadForm">Загрузить договор</el-button>
                <el-pagination background layout="sizes, prev, pager, next" :total="filteredContracts.length"
                               class="wide-page-size" :page-sizes="[20, 50, 100]" :page-size="contractsPageSize"
                               :pager-count="3" :hide-on-single-page="true" @size-change="ps => contractsPageSize= ps"
                               @current-change="cp => contractsPage = cp"
                >
                </el-pagination>
              </div>
            </TableSpinner>
          </el-tab-pane>

        </el-tabs>

      </el-col>
    </el-row>
  </div>
</template>

<script>


import TableSpinner from "../../../shared/components/layout/table-spinner.component.vue";
import moment from "moment/moment";
import RemoveButton from "../../../shared/components/layout/removeButton.component.vue";
import {filter} from "compression";
import TimeSearch from "../../../shared/components/reports/time-search.component.vue";
import ClientSelect from "@/components/layout/client-select.component.vue";

export default {
  name: "AllDocuments",
  title: "Документы",
  components: {
    ClientSelect,
    TimeSearch,
    RemoveButton,
    TableSpinner

  },
  mounted() {
    this.reloadDocuments()
    this.$emitter.on("actsGenerated", (payload) => {
      this.actsActionsDisabled = false
      this.$gNotify("Акты сгенерированы", "success")
      this.reloadDocuments()
    })
    let now = moment()
    let months = []
    for (let i = 1; i <= 24; i++) {
      months.push({label: now.locale("ru").format("YYYY MMMM"), value: now.endOf("month").format("DD.MM.YYYY")})
      now = moment().subtract(i, "months")
    }
    this.static.months = months
    this.newActForm.period = months[1].value
  },
  beforeUnmount() {
    this.$emitter.off("actsGenerated")
  },
  methods: {
    filter() {
      return filter
    },
    reloadDocuments() {
      this.loading = true
      this.axios.get("/admin/documents/allDocuments")
          .then(resp => {
            console.time("Sort")
            this.documents = resp.data.sort((d1, d2) => new Date(d2.date).valueOf() - new Date(d1.date).valueOf())
            console.timeEnd("Sort")
            this.filterActs()
            this.loading = false
          })
    },
    docStatus(row) {
      return String(row.status) === '0' ? 'Завершен' : 'Действует'
    },
    docType(row) {
      return String(row.original) === '0' ? 'Копия' : 'Оригинал'
    },
    docUserType(row) {
      return row.user.info.user_type === 'individual' ? 'Юр. лицо' : 'Физ. лицо'
    },
    docUserCompany(row) {
      return row.user.info.user_type === "physic" ? `${row.user.info.lastname} ${row.user.info.firstname} ${row.user.info.patronymic}` : row.user.info.company_name
    },
    actStatus(row) {
      return row.delivery_status === "email" ? "Отправлено по e-mail" : row.delivery_status === "email_edo" ? "Отправлено по e-mail, отправка в ЭДО в течение 5 дней" : "N/A"
    },

    removeDocument(document) {
      this.documentSending = true
      this.axios.delete("/admin/documents/removeDocument", {params: {documentId: document._id}})
          .then(resp => {
            this.$gNotify("Документ удален", "success")
            this.documents.splice(this.documents.findIndex(d => d._id === document._id), 1)
            this.filterActs()
            this.documentSending = false
          })
    },
    sendDocument(document) {
      this.documentSending = true
      this.axios.post("/admin/documents/sendOneAct", {documentId: document._id})
          .then(resp => {
            this.$gNotify("Документ отправлен на почту", "success")
            this.documentSending = false
            this.documents[this.documents.findIndex(d => d._id === document._id)].delivery_status = "email"
          })
    },

    generatePreviousMonthDocuments() {
      this.$confirm("Подтвердите запуск генерации документов за прошлый месяц")
          .then(c => {
            if (c) {
              this.actsActionsDisabled = true
              this.axios.post("/admin/documents/generateLastMonthActs")
                  .then(resp => {
                    this.$gNotify("Генерация запущена", "success")
                    // this.actsActionsDisabled = false
                  })
            }
          })

    },

    sendPreviousMonthDocuments() {
      this.$confirm("Подтвердите отправку документов")
          .then(c => {
            if (c) {
              this.actsActionsDisabled = true
              this.axios.post("/admin/documents/sendPrevMonthActs")
                  .then(resp => {
                    this.$gNotify("Отправка запущена", "success")
                    this.actsActionsDisabled = false
                  })
            }
          })
    },

    async showContractUploadForm() {
      this.addContractModalVisible = true
      if (this.$refs.newContractForm) {
        this.$refs.newContractForm.resetFields()
        this.newContractFiles = []
      }
    },
    async createNewContract() {
      this.contractCreating = true
      let postData = this.newContractForm
      postData.file = {
        content: await this.$fileToBase64(this.newContractFiles[0].raw),
        name: this.newContractFiles[0].name,
        mime: this.newContractFiles[0].raw.type
      }
      this.axios.post("/admin/documents/createContract", postData)
          .then(resp => {
            this.contractCreating = false
            this.addContractModalVisible = false
            this.newContractForm.userId = ""
            this.$gNotify("Договор загружен для " + postData.userId, "success")
            this.reloadDocuments()
          })
    },
    async createNewAct() {
      this.actCreating = true
      let postData = this.newActForm
      postData.actValue = postData.actValue.replace(/,/g, '.').replace(/\s/g, '')
      postData.file = {
        content: await this.$fileToBase64(this.newActFiles[0].raw),
        name: this.newActFiles[0].name,
        mime: this.newActFiles[0].raw.type
      }
      this.axios.post("/admin/documents/createAct", postData)
          .then(resp => {
            this.actCreating = false
            this.addActModalVisible = false
            this.newActForm.userId = ""
            this.$gNotify("Документ загружен для " + postData.userId, "success")
            this.reloadDocuments()
          })
    },
    filterActs() {
      // TODO: By time
      console.time("Filter")
      let timeFilter = this.$refs.actsSearchTime.getDateTimeFilter()
      if (timeFilter.from) {
        timeFilter.from = timeFilter.from.toDate().valueOf()
        timeFilter.to = timeFilter.to.toDate().valueOf()
      }
      this.filteredActs = this.acts.filter(a => {
        if (this.actsSearchForm.userId && a.user_id !== this.actsSearchForm.userId) return false
        if (this.actsSearchForm.status && a.delivery_status !== this.actsSearchForm.status) return false
        if (this.actsSearchForm.docNumber && a.act_number !== this.actsSearchForm.docNumber) return false
        if (timeFilter.from)
          if (timeFilter.from > a.__act_date || timeFilter.to < a.__act_date) return false

        return true
      })
      console.timeEnd("Filter")
    },
    resetActsSearchForm() {
      this.$refs.actsSearchForm.resetFields()
      this.$refs.actsSearchTime.reset()
      this.filterActs()
    },
    showActUploadForm() {
      this.addActModalVisible = true
      if (this.$refs.newActForm) {
        this.$refs.newActForm.resetFields()
        this.newActForm.userId = ""
        this.newActFiles = []
      }
    },
    exportActs() {
      let csvContent = "data:text/csv;charset=utf-8," +
          "Пользователь;Номер акта;Дата;Сумма акта;Тип контрагента;Компания;Статус\n" +
          this.filteredActs.map(a => {
            return [
              a.user_id, a.act_number, this.formatDate(a.date), a.act_value,
              this.docUserType(a), '"' + this.docUserCompany(a) + '"', '"' + this.actStatus(a) + '"'
            ].join(";")
          }).join("\n")
      let encodedUri = encodeURI(csvContent);
      let link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Акты.csv");
      document.body.appendChild(link);
      link.click();
    }
  },
  computed: {
    acts() {
      return this.documents.filter(d => String(d.type) === '0').sort((d1, d2) => moment(d2.date).valueOf() - moment(d1.date).valueOf())
    },
    contracts() {
      return this.documents.filter(d => String(d.type) === '1')
    },
    filteredContracts() {
      let searchRegex = new RegExp(this.contractsSearchToken, "gi")
      let _c = this.contracts
      if (this.contractsSearchToken) _c = _c.filter(c => {
        searchRegex.lastIndex = 0
        if (!searchRegex.test(c.title) && !searchRegex.test(c.user_id)) return false
        return true
      })
      return _c
    },

    visibleContracts() {
      return this.filteredContracts.slice((this.contractsPage - 1) * this.contractsPageSize, this.contractsPage * this.contractsPageSize)
    },
    visibleActs() {
      return this.filteredActs.slice((this.actsPage - 1) * this.actsPageSize, this.actsPage * this.actsPageSize)
    },
    // actsSearchParams(){
    //   return JSON.stringify()
    // }
  },
  data() {
    return {
      visiblePanel: "acts",
      loading: false,
      documents: [],
      filteredActs: [],
      contractsPageSize: 10,
      contractsPage: 1,
      actsPageSize: 10,
      actsPage: 1,
      contractsSearchToken: "",
      actsSearchForm: {
        userId: "",
        status: "",
        docNumber: ""
      },
      addContractModalVisible: false,
      newContractForm: {
        userId: "",
        name: "",
        status: "ended",
        type: "copy",
        file: ""
      },
      addActModalVisible: false,
      newActForm: {
        userId: "",
        name: "",
        type: "3",
        actNumber: "",
        actValue: "",
        period: "",
        file: "",
        immediateSend: false
      },
      newContractFiles: [],
      newActFiles: [],
      contractCreating: false,
      actCreating: false,

      documentSending: false,
      actsActionsDisabled: false,

      static: {
        months: []
      }
    }
  },
}

</script>