<template>
  <el-dialog v-model="intVisible"
             :title="title"
             @close="$emit('close')"
             class="modal-95-70-60"
  >
    <el-row>
      <el-col :span="24">
        <el-form label-position="top" ref="operatorForm" :rules="formRules" :model="form">
          <el-form-item label="" prop="id" style="display: none">
            <el-input v-model="form.id"/>
          </el-form-item>
          <el-form-item label="Тип привязки" prop="bindType" >
            <el-select v-model="form.bindType" class="full-width" filterable @change="form.platformOperatorId = ''">
              <el-option value="group" label="Группа операторов"/>
              <el-option value="operator" label="Оператор"/>
            </el-select>
          </el-form-item>
          <el-form-item label="Платформенный оператор" prop="platformOperatorId">
            <el-select v-model="form.platformOperatorId" class="full-width" filterable v-show="form.bindType === 'group'">
              <el-option
                  v-for="operator in $store.getters.operatorsGroups.filter(g => g.title.trim().startsWith('РФ'))"
                  :value="operator._id"
                  :label="operator.title"
              />

            </el-select>
            <el-select v-model="form.platformOperatorId" class="full-width" filterable
                       v-show="form.bindType === 'operator'">
              <el-option
                  v-for="operator in platformOperators"
                  :value="operator._id"
                  :label="operator.OrgName"
              >
                <span style="float: left">{{ operator.OrgName }}</span>
                <span
                    style="float: right; color: var(--el-text-color-secondary); font-size: 13px;"
                >{{ groupNameByOperator(operator) }}</span>
              </el-option>

            </el-select>
          </el-form-item>
          <el-form-item label="Внешний источник имён" prop="externalSource">
            <el-input v-model="form.externalSource"/>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="$emit('close')">Закрыть</el-button>
        <el-button type="success" @click="upsertOperator" :loading="upserting">Сохранить</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>


export default {
  name: "IntNameOperatorForm",
  props: ["visible", "editingOperator", "platformOperators"],
  watch: {
    visible(v){
      this.intVisible = v
    },
    async editingOperator(newVal) {
      if (!newVal || !newVal._id) {

        this.form.id = ""
        this.form.bindType = ""
        this.form.platformOperatorId = ""
        this.form.externalSource = ""
      } else {
        this.form.id = String(newVal._id)
        this.form.bindType = newVal.bindType
        this.form.platformOperatorId = newVal.platformOperatorId
        this.form.externalSource = newVal.externalSource

      }
    },

  },
  methods: {
    groupNameByOperator(operator){
      return (this.$store.getters.operatorsGroups.find(g => g._id === operator.group) || {title: "N/A"}).title
    },
    upsertOperator() {
      this.$refs.operatorForm.validate()
          .then(valid => {
            if (!valid) return
            this.upserting = true
            this.axios.post("/admin/routing/upsertInternationalNamesOperator", {data: this.form})
                .then(resp => {
                  this.upserting = false
                  this.$emit("close")
                  this.$emit("reload")
                }).catch(console.error)
          })

    }
  },
  computed: {
    title() {
      return this.editingOperator && this.editingOperator._id ? "Редактирование оператора" : "Новый оператор"
    },
  },
  data() {
    return {
      form: {
        id: "",
        bindType: "group",
        platformOperatorId: "",
        externalSource: ""
      },
      formRules: {
        id: [{required: false}],
        // name: [{required: true, trigger: "blur"}],
        platformOperatorId: [{required: true, trigger: "blur"}],
        externalSource: [{required: false, trigger: "blur"}],
      },
      upserting: false,
      intVisible: false
    }
  },
}

</script>