<template>


  <el-row :gutter="20">
    <el-col :span="24">
      <TableSpinner :loading="loading" :rows="7" style="margin-top: 20px; margin-bottom: 50px">
        <el-tabs v-model="visibleActiveNames" class="inside-tabs">
          <el-tab-pane label="СМС" name="sms">

            <el-table :data="activeSmsNames" style="width: 100%">
              <el-table-column prop="date" :formatter="dateTime" label="Дата" min-width="150"/>
              <el-table-column label="Подпись" prop="name" min-width="150"/>
              <el-table-column label="По умолчанию" min-width="120">
                <template #default="scope">
                  <el-button type="success" size="small"
                             class="circle primary"
                             v-if="scope.row.default === 1" disabled></el-button>
                  <el-button type="default" size="small"
                             class="circle disabled"
                             v-if="scope.row.default !== 1"
                             @click="setDefaultSender(scope.row._id)"></el-button>
                </template>
              </el-table-column>
              <el-table-column label="" width="180">
                <template #default="scope">
                  <el-button text class="text-button table-text-button" size="small"
                             @click="$emit('onManage', scope.row)"
                             v-if="isAdminView || (scope.row.operatorSettings && !scope.row.forCalls && !$store.getters.user.isPhysic())"
                  >
                    <font-awesome-icon icon="edit"></font-awesome-icon>&nbsp;Управление
                  </el-button>
                  <RemoveButton v-if="scope.row.forCalls "
                                @confirm="remove(scope.row)">Удалить
                  </RemoveButton>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>

          <el-tab-pane label="Номера для звонков" name="calls">
            <el-table :data="activeCallsPhones" style="width: 100%">
              <el-table-column prop="date" :formatter="dateTime" label="Дата" min-width="150"/>
              <el-table-column label="Номер" prop="name" min-width="150"/>
              <el-table-column label="" width="150">
                <template #default="scope">
                  <RemoveButton @confirm="remove(scope.row)">Удалить</RemoveButton>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </TableSpinner>
    </el-col>
  </el-row>


  <el-row :gutter="20">
    <el-col :span="24">
      <span class="text-bold" style="margin-bottom: 20px">Поданые заявки</span>
      <TableSpinner :loading="loading" style="margin-top: 20px">

        <el-table
            :data="nonActiveRequestsForTable"
            style="width: 100%" border stripe
        >
          <el-table-column prop="date" :formatter="dateTime" label="Дата" width="100"/>
          <el-table-column label="Подпись" prop="name" min-width="150"/>
          <el-table-column label="Тип" :formatter="requestType" min-width="130"/>
          <el-table-column label="Статус заявки" min-width="150">
            <template #default="scope">
              {{ getRequestStatus(scope.row) }}
            </template>
          </el-table-column>
          <el-table-column label="Комментарий" min-width="150">
            <template #default="scope">
              {{ scope.row.reason || '' }}
            </template>
          </el-table-column>
          <el-table-column label="" width="180">
            <template #default="scope">
              <el-button text class="text-button table-text-button" size="small"
                         @click="$emit('onManage', scope.row)"
                         v-if="isAdminView || (scope.row.operatorSettings && !scope.row.forCalls && !$store.getters.user.isPhysic())"
              >
                <font-awesome-icon icon="edit"></font-awesome-icon>&nbsp;Управление
              </el-button>
              <RemoveButton v-if="scope.row.forCalls || $store.getters.user.isPhysic()"
                            @confirm="remove(scope.row)">Удалить
              </RemoveButton>
            </template>
          </el-table-column>
        </el-table>
        <div class="solo-pagination-container">
          <el-pagination
              background
              class="wide-page-size"
              layout="sizes, prev, pager, next"
              :total="allNonActiveRequests.length"
              :page-sizes="[10, 20, 50]"
              :pager-count="3"
              :hide-on-single-page="true"
              :page-size="nonActiveRequestsPageSize"
              @size-change="handlePageSizeChange"
              @current-change="handleCurrentPageChange"
          >
          </el-pagination>
        </div>
      </TableSpinner>
    </el-col>
  </el-row>

</template>

<script>


import moment from "moment"
import RemoveButton from "@shared/components/layout/removeButton.component";
import uuid from "uuid";
import ActionsDropdown from "../../../shared/components/layout/actionsDropdown.component.vue";
import ActionButton from "../../../shared/components/layout/actionButton.component.vue";

export default {
  name: "ClientSenderNamesTable",
  components: {
    ActionButton,
    ActionsDropdown,
    RemoveButton,
  },
  props: [
    "loading",
    "senders",
    "isAdminView"
  ],
  computed: {
    allNonActiveRequests() {
      return this.senders.filter(r => r.active !== 1)
    },
    nonActiveRequestsForTable() {
      return this.allNonActiveRequests.slice(
          (this.nonActiveRequestsCurrentPage - 1) * this.nonActiveRequestsPageSize,
          this.nonActiveRequestsCurrentPage * this.nonActiveRequestsPageSize
      )
    },
    activeSmsNames() {
      return this.senders.filter(r => r.active === 1 && !r.forCalls)
    },
    activeCallsPhones() {
      return this.senders.filter(r => r.active === 1 && r.forCalls)
    }
  },
  methods: {
    handlePageSizeChange(newSize) {
      this.nonActiveRequestsPageSize = newSize
    },
    handleCurrentPageChange(newPage) {
      this.nonActiveRequestsCurrentPage = newPage
    },
    requestType(row) {
      return row.forCalls ? "Для звонков" : "Для СМС"
    },
    getRequestStatus(row) {
      return row.active === 0 ? ((row.removedByUser) ? 'Отменено пользователем' : 'Отклонено модератором') : 'В рассмотрении'
    },
    dateTime(row) {
      return moment(row.date).format("DD.MM.YYYY HH:mm")
    },

    remove(row) {
      if(this.isAdminView) return this.$emit("onRemove", row._id)
      this.axios.delete("/sender_names/removeOneByUserRequest", {params: {requestId: row._id}})
          .then(resp => {
            this.$gNotify("Подпись удалена", "success")

            this.$emit("refresh")

          })
    },
    setDefaultSender(senderId) {
      if(this.isAdminView) return this.$emit("onSetDefault", senderId)
      this.axios.post("/sender_names/setMyDefaultSender", {senderId})
          .then(resp => {
            this.$emit("refresh")
          })
    },

  },
  data() {
    return {
      loading: false,
      sendingRequest: false,
      totalNonActiveRequests: 0,
      nonActiveRequestsCurrentPage: 1,
      nonActiveRequestsPageSize: 10,
      visibleActiveNames: "sms",
    }
  }
}

</script>
