<template>
  <div>
    <sender-names-manage-modal
        ref="manageModal"
        @refresh="refresh"
    />

    <el-row style="margin-bottom: 20px">
      <el-col :span="24">
        <el-card class="box-card">
          <el-form label-position="top" ref="searchForm" :model="search">
            <el-row :gutter="40">
              <el-col :xs="24" :md="6">
                <el-form-item label="Свободный поиск" prop="token">
                  <el-input class="full-width" placeholder="" clearable
                            v-model="search.token"/>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :md="6">
                <el-form-item label="Тип">
                  <el-select v-model="search.type" class="full-width" prop="type">
                    <el-option value="" label="Все"/>
                    <el-option value="sms" label="Для смс"/>
                    <el-option value="calls" label="Для звонков"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :md="6">
                <el-form-item label="Активность" prop="activity">
                  <el-select v-model="search.activity" class="full-width">
                    <el-option value="" label="Все"/>
                    <el-option value="unprocessed" label="Не обработанные"/>
                    <el-option value="active" label="Подтвержденные"/>
                    <el-option value="rejected" label="Отклоненные"/>
                    <el-option value="removed" label="Удаленные"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :md="6">
                <el-form-item label=" ">
                  <el-button type="success" @click="filterRequests">Отфильтровать</el-button>
                  <el-button type="danger" @click="resetSearchForm">Сбросить</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>

        </el-card>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-card class="box-card">
          <el-button type="success" @click="showAddNameDialog()">Добавить имя</el-button>
          <TableSpinner :loading="loading" :rows="5">
            <el-table :data="visibleRequests">
              <el-table-column label="Дата" :formatter="r => formatDateTime(r.date)" min-width="140"/>
              <el-table-column label="Контрагент" prop="user_id" min-width="140"/>
              <el-table-column label="Имя" prop="name" min-width="140"/>
              <el-table-column label="Юр.лицо" min-width="140">
                <template #default="scope">
                  {{
                    scope.row.userData.company_name ? scope.row.userData.company_name : scope.row.userData.initials
                  }}<br/>
                  {{ scope.row.userData.inn }}
                </template>
              </el-table-column>
              <el-table-column label="Тип" width="120">
                <template #default="scope">
                  {{ scope.row.forCalls ? 'Звонки' : 'СМС' }}
                </template>
              </el-table-column>
              <el-table-column label=" " min-width="150">
                <template #default="scope">
                  <el-button text class="table-text-button" size="small" @click="manageRequest(scope.row)">
                    Управление
                  </el-button>
                  <remove-button @confirm="removeName(scope.row)">Удалить</remove-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="solo-pagination-container">
              <el-pagination
                  background
                  layout="sizes, prev, pager, next"
                  :total="filteredRequests.length"
                  class="wide-page-size"
                  :page-sizes="[20, 50, 100]"
                  :page-size="pageSize"
                  :pager-count="3"
                  :hide-on-single-page="true"
                  @size-change="handlePageSizeChange"
                  @current-change="handleCurrentPageChange"
              >
              </el-pagination>
            </div>
          </TableSpinner>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import TableSpinner from "../../../shared/components/layout/table-spinner.component.vue";
import RemoveButton from "../../../shared/components/layout/removeButton.component.vue";
import SenderNamesManageModal from "@/components/support/sender-name-manage-modal.component.vue";
import verifySenderNameUsageMixin from "@shared/mixins/verify-sender-name-usage.mixin";

const OPERATOR_NAMES = {
  mts: "МТС",
  megafon: "Мегафон",
  beeline: "Билайн",
  tele2: "Теле2",
}

export default {
  name: "SenderNamesRequests",
  components: {SenderNamesManageModal, RemoveButton, TableSpinner},
  mixins: [verifySenderNameUsageMixin],
  methods: {
    refresh() {
      this.loading = true
      this.axios.get("/admin/support/allSenderNamesRequests")
          .then(async resp => {
            this.allRequests = resp.data
            this.filterRequests()
            await this.$nextTick()
            this.loading = false
          })
    },
    resetSearchForm() {
      this.search.token = ""
      this.search.type = ""
      this.search.activity = "unprocessed"
      this.search.removed = "notRemoved"
    },
    manageRequest(request) {
      this.$refs.manageModal.openEdit(request)
    },
    handlePageSizeChange(newSize) {
      this.pageSize = newSize
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage
    },
    filterRequests() {
      let searchRegex = new RegExp(this.search.token, "gi")
      this.filteredRequests = this.allRequests.filter(r => {
        if (this.search.activity) {
          if (this.search.activity === "unprocessed" && String(r.active) !== "-1") return false
          if (this.search.activity === "active" && String(r.active) !== "1") return false
          if (this.search.activity === "rejected" && String(r.active) !== "0") return false
          if (this.search.removed === "removed" && !r.removed) return false
        }
        searchRegex.lastIndex = 0
        if (this.search.token && !searchRegex.test(r.name) && !searchRegex.test(r.user_id)) return false
        if (this.search.type && ((this.search.type === "calls" && !r.forCalls) || (this.search.type === "sms" && r.forCalls))) return false
        return true
      })
    },
    removeName(name) {
      this.verifySenderNameUsage(name._id, () => {
        this.axios.delete("/admin/support/removeSenderName", {params: {requestId: name._id}})
            .then(resp => {
              this.$gNotify("Имя удалено", "success")
              this.refresh()
            })
      })
    },
    showAddNameDialog(){
      this.$refs.manageModal.openAdd()
    }
  },
  mounted() {
    this.refresh()
  },
  computed: {
    visibleRequests() {
      return this.filteredRequests.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    }
  },
  data() {
    return {
      removingOne: false,
      loading: false,
      search: {
        token: "",
        activity: "unprocessed",
        // removed: "notRemoved",
        type: ""
      },
      allRequests: [],
      filteredRequests: [],
      currentPage: 1,
      pageSize: 100,

    }
  },
}

</script>