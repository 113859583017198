import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_individual_sender_substitution_rules_table = _resolveComponent("individual-sender-substitution-rules-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.addEditModalVisible,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.addEditModalVisible) = $event)),
      title: _ctx.title,
      class: "modal-95-70-60"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 24 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form, {
                  modelValue: _ctx.editingRule,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editingRule) = $event)),
                  "label-position": 'top'
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: _ctx.editingRule._id,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editingRule._id) = $event)),
                      style: {"display":"none"}
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_el_form_item, {
                      label: "Для пользователя",
                      prop: "forUser"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: _ctx.editingRule.forUser,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.editingRule.forUser) = $event)),
                          class: "full-width"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.managers, (user) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                value: user.system_id,
                                label: user.system_id
                              }, null, 8, ["value", "label"]))
                            }), 256))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, {
                      label: "Отправитель",
                      prop: "original_name"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: _ctx.editingRule.original_name,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editingRule.original_name) = $event)),
                          class: "full-width"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_option, {
                              value: "",
                              label: "Любой отправитель"
                            }),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.senders, (name) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                value: name.name,
                                label: name.name
                              }, null, 8, ["value", "label"]))
                            }), 256))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, { label: "Подменять на" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.editingRule.change_with,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editingRule.change_with) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, {
                      label: "Группа операторов",
                      prop: "operator_group"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          class: "full-width",
                          filterable: "",
                          modelValue: _ctx.editingRule.operator_group,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editingRule.operator_group) = $event))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.getters.operatorsGroups, (o) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                value: o._id,
                                label: o.title
                              }, null, 8, ["value", "label"]))
                            }), 256))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, {
                      label: " Влияет на тарификацию ",
                      prop: "affects_rating"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: _ctx.editingRule.affects_rating,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.editingRule.affects_rating) = $event)),
                          class: "full-width"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_option, {
                              value: "0",
                              label: "Нет"
                            }),
                            _createVNode(_component_el_option, {
                              value: "1",
                              label: "Да"
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "success",
          onClick: _ctx.saveRule,
          loading: _ctx.saving
        }, {
          default: _withCtx(() => [
            _createTextVNode("Сохранить")
          ]),
          _: 1
        }, 8, ["onClick", "loading"])
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 24 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "success",
              onClick: _ctx.showAddModal,
              style: {"margin-bottom":"20px"}
            }, {
              default: _withCtx(() => [
                _createTextVNode("Добавить")
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_individual_sender_substitution_rules_table, {
              loading: _ctx.loading,
              "individual-rules": _ctx.rules,
              "show-actions": true,
              onOnEdit: _ctx.showEditModal,
              onOnDelete: _ctx.removeSchema
            }, null, 8, ["loading", "individual-rules", "onOnEdit", "onOnDelete"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}