<template>

  <el-form-item :label="label" class="">
    <el-input v-model="internalPhones" type="textarea" :rows="rows || 3"></el-input>
    <p style="margin-top: 0px; margin-bottom: 5px; line-height: normal" class="el-form-item__label">(через
      разделитель ; , пробел или
      построчно.)</p>
<!--    <el-checkbox v-model="addSeven" class="break-label" v-show="showAddSeven"-->
<!--                 label="Дописывать +7 к номеру телефона (если он не начинается на +7)"/>-->
  </el-form-item>

</template>


<script>


export default {
  name: 'PhonesListInput',
  props: ["phones", "label", "rows", "showAddSeven"],
  watch: {
    phones(val){
      this.internalPhones = (val || []).join("\n")
    },
    internalPhones(){
      this.$emit("values", {values: this.getPhones()})
    }
  },
  computed: {

  },
  methods: {
    clear(){
      this.internalPhones = ""
    },
    getPhones(){
      let values = this.internalPhones.trim()
          .replace(/(\r\n|\n|\r|;|\s)/gm, ",")
          .split(",")
          .map(patchPhone)
          .filter(v => v)
      if(this.addSeven) values = values.map(v => {
        if(v.length === 10 && v.startsWith("9")) v = "7" + v
        return v
      })
      return values
    }
  },
  data(){
    return {
      internalPhones: "",
      addSeven: true
    }
  }
}

</script>

<style>

</style>