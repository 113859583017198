<template>
  <el-progress
      v-show="conversationLoading"
      :percentage="50"
      status="success"
      :show-text="false"
      :indeterminate="true"
      :duration="2"
  />
  <div class="message-feed-1" id="msgFeed" @scroll="handleFeedScroll">
    <dialog-message
        :text="msg.text || ''"
        :from-client="msg.fromClient"
        :media="msg.media"
        :type="msg.__type"
        :buttons="msg.buttons || []"
        :show-timestamp="true"
        :time-received="msg.__time_received"
        :status="msg.__status"
        :message-id="msg.messageId"
        :prev-message-author-different="i+1 < conversation.length && conversation[i+1].fromClient !== msg.fromClient"
        v-for="(msg, i) in conversation"
    />

  </div>

  <div id="dialog-actions-container" v-show="!disabled">

    <div class="upload-thumbs-container" v-show="uploadedFiles.length">
      <div
          class="upload-thumbnail"
          v-for="file in uploadedFiles"
      >
        <el-tooltip
            effect="dark"
            :content="file.name"
            placement="top"
            v-if="file && file.raw"
        >
          <img class="thumb-image" :src="fileToUrl(file)" v-if="file.raw.type.startsWith('image')"/>
          <div class="thumb-image thumb-wildcard" v-if=" !file.raw.type.startsWith('image')">
            <font-awesome-icon icon="file"/>
          </div>

        </el-tooltip>
        <div class="thumb-remove-trigger">
          <font-awesome-icon icon="times" @click="removeAttachment(file)"/>
        </div>
        <!--        <img class="loading-preview" v-show="file.percentage !== 100"/>-->
      </div>
    </div>
    <div id="dialog-actions">
      <el-upload
          v-if="supportsMedia"
          v-model:file-list="uploadedFiles"
          :auto-upload="false"
          :show-file-list="false"
          :on-change="handleFileUpload"
          :on-success="addUploadThumbnail"
      >
        <svg
            style="width: 35px; height: 31px;cursor:pointer" @click="sendMessage"
        >
          <image href="@shared/assets/static_img/attachment.svg" style="width: 31px; height: 31px;"/>
        </svg>

      </el-upload>
      <el-input
          type="textarea" :autosize="{ minRows: 1, maxRows: 6 }" placeholder="Введите текст сообщения"
          v-model="messageText"
          class="dialog-message-input" @keydown="handleKeydownInTextarea"
      ></el-input>
      <div
          v-if="textualSendButton"
          style="display: flex;flex-direction: column; align-self: flex-end">
        <el-button type="success" plain @click="sendMessage" :loading="sendingMessage">
          Отправить
        </el-button>
        <span
            class="el-form-item__label"
            style="margin: 0px; font-size: 10px; height: 20px !important; line-height: 20px;text-align: center; padding: 0px"
        >
          Shift + Enter
        </span>
      </div>
      <svg
          v-else
          style="width: 35px; height: 31px;cursor:pointer" @click="sendMessage"
      >
        <image href="@shared/assets/static_img/send.svg" style="width: 31px; height: 31px;"/>
      </svg>
    </div>
  </div>
</template>

<script>
import DialogMessage from "./dialog-message.component.vue";
import moment from "moment";

export default {
  name: "DialogMessageFeed",
  components: {DialogMessage},
  props: [
      "disabled", "fetchMessagesMethod", "sendMessageMethod", "messageMediaHref", "supportsMedia", "textualSendButton",
  ],
  provide(){
    return {
      textAsHtml: true
    }
  },
  methods: {
    clear() {
      this.conversationLoading = false
      this.abortController.abort()
      this.conversation = []
      this.sentOffset = ""
      this.receivedOffset = ""
      this.wholeConversationLoaded = false

    },
    onNewMessageReceived(msg) {
      this.addSingleMessageToFeed(msg)
    },
    handleFeedScroll(e) {
      if (e.target && Math.abs(e.target.scrollTop) + e.target.offsetHeight >= e.target.scrollHeight)
        this.loadConversation(true)
    },
    handleKeydownInTextarea(e) {
      if (e.keyCode === 13 && e.shiftKey) {
        this.sendMessage()
      }
    },
    loadConversation(asMore = false) {
      if (this.conversationLoading) return;
      if (asMore && this.wholeConversationLoaded) return

      this.conversationLoading = true
      this.abortController = new AbortController()
      this.fetchMessagesMethod(this.sentOffset, this.receivedOffset, this.abortController.signal)
          .then(async resp => {

            if (resp.data.receivedOffset === "" && resp.data.sentOffset === "") this.wholeConversationLoaded = true
            this.sentOffset = resp.data.sentOffset || this.sentOffset
            this.receivedOffset = resp.data.receivedOffset || this.receivedOffset
            // console.log(this.conversation.filter(m => m.__type !== "separator").concat(resp.data.messages))
            this.conversation = this.conversation.filter(m => m.__type !== "separator").concat(resp.data.messages).reduce((acc, msg, i, da) => {

              // console.log(msg.text, msg.__day_key, da[i+1].__day_key, i)
              if (i > 0 && i + 1 < da.length && msg.__day_key !== da[i + 1].__day_key) {
                acc.push(msg)
                acc.push({__type: "separator", text: msg.__day_key})
              } else
                acc.push(msg)

              if (
                  (i === 0 && da[i + 1] && msg.__day_key !== da[i + 1].__day_key) ||
                  (i === 0 && (da.length === 1 || (da[da.length - 1].__day_key !== moment().format("DD.MM.YYYY") && da[da.length - 1].__day_key === msg.__day_key)))
              )
                acc.push({__type: "separator", text: msg.__day_key})

              if (i > 0 && i + 1 === da.length)
                acc.push({__type: "separator", text: da[i].__day_key})

              return acc
            }, [])
            this.$nextTick().then(() => this.conversationLoading = false)
          })
          .catch(e => {
            if (e.message !== "canceled")
              this.conversationLoading = false
          })
    },

    addSingleMessageToFeed(msg) {
      let index = this.conversation.findIndex(m => m._id === msg._id)
      if (index > -1) this.conversation[index] = msg
      else {
        if (this.conversation[0] && this.conversation[0].__day_key !== msg.__day_key)
          this.conversation.unshift(({__type: "separator", text: msg.__day_key}))
        this.conversation.unshift(msg)
      }

    },
    async sendMessage() {
      if (!this.messageText.trim() && !this.uploadedFiles.length) return
      if(this.sendingMessage) return
      this.sendingMessage = true
      let payload = {
        text: this.messageText.trim()
      }
      if (this.uploadedFiles.length) {
        let f = this.uploadedFiles[0]
        payload.file = {name: f.name, mime: f.raw.type, baseString: await this.$fileToBase64(f.raw)}
      }
      this.sendMessageMethod(payload)
          .then(resp => {
            this.sendingMessage = false
            this.messageText = ""
            this.uploadedFiles = []
          })
          .catch(e => {
            this.sendingMessage = false
          })
    },

    handleFileUpload(file, list) {
      this.uploadedFiles = [file]
    },
    addUploadThumbnail(arg, file, list) {
      let index = list.findIndex(f => f.uid === file.uid)
      list[index].__uploaded_uid = arg
    },
    removeAttachment(file) {
      let index = this.uploadedFiles.findIndex(f => f.uid === file.uid)
      this.uploadedFiles.splice(index, 1)
    },
  },
  computed: {},
  data() {
    return {
      conversationLoading: false,
      messageText: "",
      conversation: [],
      sentOffset: "",
      receivedOffset: "",
      sendingMessage: false,
      uploadedFiles: [],
      wholeConversationLoaded: false,

      abortController: new AbortController()
    }
  }
}

</script>

<style>

#dialog-actions-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#dialog-actions {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
}

.message-feed-1 {

  padding: 20px;
  background-color: #edf2f7;
  overflow-y: scroll;
  flex: 1 1 0;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: column-reverse;
}

.dialog-message-input {
  min-height: 42px;
  display: flex;
  align-self: flex-start;
}

.dialog-message-input .el-textarea__inner {
  min-height: 42px !important;
  //height: 40px !important;
}


</style>