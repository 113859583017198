<template>
  <div>

    <form style="display: none" method="post" id="exportForm"
          :action="`${axios.defaults.baseURL}/admin/finance/downloadGatewayReport`">
      <input name="params" type="hidden" id="exportParams"/>
      <input name="__sg_client_token" type="hidden" :value="$store.getters.token"/>
    </form>
    <el-dialog v-model="detailsModalVisible" title="Детальный отчет" class="modal-95-70-60">
      <el-table :data="transactionsRows">
        <el-table-column label="Дата и время" prop="dateTime" width="180"/>
        <el-table-column label="Плательщик" prop="user" min-width="180"/>
        <el-table-column label="Итого" prop="sum" width="120"/>
        <el-table-column label="Комментарий" prop="comment" width="400"/>
      </el-table>
    </el-dialog>

    <el-row :gutter="20">
      <el-col :span="24">
        <el-tabs v-model="activeTab" class="primary-heading-tabs">
          <el-tab-pane name="report" label="Отчет">
            <el-row :gutter="30" >
              <el-col :xs="24" :md="12">
                <TimeSearch
                    ref="timeSearch"
                    :month="true"
                    :full-width-select="true"
                    :show-last-month="true"
                />

                <el-button type="success" plain @click="getReport" :loading="reportLoading">
                  Применить
                </el-button>
              </el-col>

            </el-row>

            <TableSpinner :loading="reportLoading" v-if="reportInitiated">
              <el-divider />
              <div v-for="(rows, type) in oneTableRows">
                <h4 style="display: flex;justify-content: space-between; margin-top:0px;align-items: center">
                  <span>{{ availableGateways[type][0] }}</span>

                  <el-button text class="text-button" @click="exportRobocassReport" v-if="type === 'robocass'"
                             style="padding: 0px; min-height: auto;height: auto !important">
                    <font-awesome-icon icon="external-link-alt"/>&nbsp;Экспортировать
                  </el-button>
                </h4>

                <el-table
                    :data="rows"
                    style="width: 100%; margin-bottom: 50px; margin-top: 10px"
                    :summary-method="getSummary(type)"
                    show-summary
                >
                  <el-table-column prop="date" label="Дата" width="200"/>
                  <el-table-column label="Дебет" min-width="200" prop="totalUp"
                                   :formatter="r => parseFloat(r.totalUp).toFixed(2)"/>

                  <el-table-column label="" soratble width="150">
                    <template #default="scope">
                      <el-button text class="table-text-button" @click="showTransactions(scope.row.transactions)">
                        Подробнее
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-divider/>
              </div>

            </TableSpinner>
          </el-tab-pane>
          <el-tab-pane name="reportSettings" label="Е-мейлы для отчета по робокассе">
            <el-form label-position="top">
              <el-form-item label="Список е-мейлов через запятую">
                <el-input v-model="reportDeliveryEmails" class="full-width"/>
              </el-form-item>
              <el-form-item label="">
                <el-button type="success" plain @click="saveReportDeliveryEmails" :loading="emailsSaving">
                  Сохранить
                </el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>


import TimeSearch from "@shared/components/reports/time-search.component"
import {read as readXlsx, utils} from "xlsx"
import iconv from "iconv-lite"

import moment from "moment"
import ClientView from "../../components/clients/client-view.component.vue";


const availableGateways = {
  'bank': ["Банковские платежи", "0"],
  'test': ["Переводы средств для тестирования", "7"],
  'robocass': ["Робокасса", "3"],
  "umoney": ["ЮMoney", "9"]
}

function getEmptyRows() {
  return Object.keys(availableGateways).reduce((acc, t) => {
    acc[t] = []
    return acc
  }, {})
}

export default {
  name: "PaymentGatewayReport",
  title: "Платежные шлюзы",
  components: {
    ClientView,
    TimeSearch
  },
  mounted() {
    this.axios.get("/admin/finance/robocassReportDeliveryEmails")
        .then(resp => {
          this.reportDeliveryEmails = resp.data || ""
        })
  },
  methods: {
    __getReportParams() {
      let dateFilter = this.$refs.timeSearch.getDateTimeFilter()
      return {
        from: dateFilter.from.format("DD.MM.YYYY"),
        to: dateFilter.to.format("DD.MM.YYYY")
      }
    },
    getReport() {
      this.reportInitiated = true
      this.reportLoading = true


      this.axios.get("/admin/finance/gatewayReport", {params: this.__getReportParams()})
          .then(resp => {
            this.oneTableRows = Object.keys(availableGateways).reduce((acc, t) => {
              let thisGatewayTransactions = (resp.data.transactions || []).filter(r => String(r.payment_system) === availableGateways[t][1])
              let tableRows = Object.values(thisGatewayTransactions.reduce((rowsAcc, transaction) => {
                if (!rowsAcc[transaction.day]) rowsAcc[transaction.day] = {
                  'date': moment(transaction.createdAt).format("DD.MM.YYYY"),
                  'totalUp': 0,
                  'transactions': []
                }
                rowsAcc[transaction.day].totalUp += Number(transaction.summ)
                rowsAcc[transaction.day].transactions.push({
                  dateTime: moment(transaction.createdAt).format("DD.MM.YYYY HH:mm:ss"),
                  sum: parseFloat(transaction.summ).toFixed(2),
                  user: transaction.target,
                  comment: transaction.description
                })
                return rowsAcc
              }, {}))
              acc[t] = tableRows
              return acc
            }, {})
            this.reportLoading = false
          })
          .catch(() => {
          })
    },

    exportRobocassReport() {
      document.getElementById("exportParams").value = JSON.stringify(this.__getReportParams())
      document.getElementById("exportForm").submit()
    },
    showTransactions(transactions) {
      this.transactionsRows = transactions || []
      this.detailsModalVisible = true

    },
    getSummary(type) {
      return () => {
        return ["Итого", Number(this.oneTableRows[type].reduce((acc, v) => acc + Number(v.totalUp), 0)).toFixed(2), ""]
      }
    },
    saveReportDeliveryEmails() {
      this.emailsSaving = true
      this.axios.post("/admin/finance/saveRobocassReportDeliveryEmails", {emails: this.reportDeliveryEmails})
          .then(() => {
            this.$gNotify("Емейлы сохранены", "success")
            this.emailsSaving = false
          })
    }
  },
  computed: {},
  data() {
    return {
      reportInitiated: false,
      reportLoading: false,
      reportRows: {},
      transactionsRows: [],
      detailsModalVisible: false,
      oneTableRows: getEmptyRows(),
      reportDeliveryEmails: "",
      emailsSaving: false,
      activeTab: "report",
      availableGateways
    }
  }
}

</script>