

export interface SubstitutionRule {
    _id: string,
    global_rule_type?: string,
    original_name: string,
    change_with: string,
    operator_type?: string,
    operator_group?: string,
    lookup_profi_name?: boolean,
    profi_lookup_operator_group?: string,
    provider_id?: string,
    agregator_pool: string,
    forUser?: string,
    affects_rating?: string,
    global: boolean
}

export class GlobalSubstitutionRule implements SubstitutionRule {
    _id = ""
    agregator_pool = ""
    change_with = ""
    global_rule_type = "classic"
    operator_type = "group"
    profi_lookup_operator_group = ""
    lookup_profi_name = false
    operator_group = ""
    original_name = ""
    provider_id = ""
    global = true
}

export class IndividualSubstitutionRule implements SubstitutionRule {
    _id = ""
    agregator_pool = "any"
    change_with = ""
    operator_group = ""
    original_name = ""
    forUser=""
    affects_rating="0"
    global = false
}

