

import ActionsDropdown from "../../../shared/components/layout/actionsDropdown.component.vue";
import TableSpinner from "../../../shared/components/layout/table-spinner.component.vue";
import {IndividualSubstitutionRule, SubstitutionRule} from "../../schemas/routing";
import {defineComponent} from "vue";
import IndividualSenderSubstitutionRulesTable
  from "../routing/individual-sender-substitution-rules-table.component.vue";

export default defineComponent({
  name: 'ClientIndividualSubstitutionRules',
  components: {IndividualSenderSubstitutionRulesTable, TableSpinner, ActionsDropdown},
  props: ["systemId", "managers", "senders"],
  watch: {
    systemId(newVal) {
      console.log("call")
      console.log(newVal)
      if (newVal) {
        this.refresh()
      }
    }
  },
  mounted() {
    this.axios.get("/admin/routing/allPools")
        .then(resp => {
          this.agregators = resp.data
        })
  },
  methods: {
    refresh() {
      this.loading = true
      this.axios.get("/admin/routing/allSenderSubstitutions", {params: {systemId: this.systemId, individualOnly: true}})
          .then(resp => {
            this.rules = resp.data
            this.loading = false
          })
    },
    showAddModal() {
      this.editingRule = new IndividualSubstitutionRule()
      this.addEditModalVisible = true
    },
    showEditModal(r: IndividualSubstitutionRule) {
      this.editingRule = r
      this.addEditModalVisible = true
    },
    saveRule() {
      this.saving = true
      this.axios.post("/admin/routing/saveIndividualSenderSubstitutionRule", {rule: this.editingRule})
          .then(resp => {
            this.refresh()
            this.addEditModalVisible = false
            this.saving = false
          })
    },
    removeSchema(rule: SubstitutionRule) {
      this.axios.delete("/admin/routing/removeIndividualSenderSubstitutionRule", {params: {ruleId: rule._id}})
          .then(resp => {
            this.refresh()
          })
    },

  },
  computed: {
    title() {
      return this.editingRule._id ? "Редактирование правила" : "Новое правило"
    }
  },
  data() {
    return {
      loading: false,
      saving: false,
      addEditModalVisible: false,
      editingRule: new IndividualSubstitutionRule(),
      rules: [] as SubstitutionRule[],
      agregators: []
    }
  }
})

