<template>
  <div>
    <agregator-form
        :visible="addEditAgregatorFormVisible"
        :editing-agregator="editingAgregator"
        @close="addEditAgregatorFormVisible = false"
        @reload="$emit('reload')"
    ></agregator-form>
    <el-row>
      <el-col :span="24">
        <el-table :data="agregators">
          <el-table-column label="Название" prop="name" min-width="140"/>
          <el-table-column label="ID" prop="_id" min-width="140"/>
          <el-table-column label="Тип" :formatter="agregatorType" min-width="140"/>
          <el-table-column label="URL" :formatter="row => row.type === 'mock' ? '' : row.ip + ':' + row.port"  min-width="170"/>
          <el-table-column label="Логин" prop="login"  min-width="150"/>
          <el-table-column label="Режим" prop="mode"  min-width="150"/>
          <el-table-column label="Скорость (смс/сек)" prop="speed"  min-width="120"/>
          <el-table-column label=" " width="100">
            <template #default="scope">
              <ActionsDropdown>
                <ActionButton icon="pencil-alt" @click="onEdit(scope.row)">Изменить</ActionButton>
                <ActionButton type="remove" @confirm="removeOne(scope.row)" :loading="removingOne">Удалить
                </ActionButton>
              </ActionsDropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-button type="success" style="margin-top: 40px" @click="onAdd">Добавить</el-button>
  </div>
</template>

<script>

import AgregatorForm from "./agregator-form.component.vue";

const AGREGATOR_TYPES = {
 smpp: "SMPP",
 vk: "VK/OK",
 telegram: "Telegram",
 mock: "Имитация"
}

export default {
  name: "AgregatorsTable",
  props: ['agregators'],
  components: {AgregatorForm},
  methods: {
    agregatorType(row){
      return AGREGATOR_TYPES[row.type || "smpp"] + (!row.type ? "(DEFAULT)" : "")
    },
    onEdit(agregator){
      console.log(agregator)
      this.editingAgregator = agregator
      this.addEditAgregatorFormVisible = true
    },
    onAdd(){
      this.editingAgregator = {}
      this.addEditAgregatorFormVisible = true
    },
    removeOne(agregator){
      this.removingOne = true
      this.axios.delete("/admin/routing/removeAgregator", {params: {agregatorId: agregator._id}})
          .then(resp => {
            this.removingOne = false
            this.$emit('reload')
          })
          .catch()
    }
  },
  computed: {},
  mounted(){
    console.log("AAA")
  },
  data() {
    return {
      removingOne: false,
      addEditAgregatorFormVisible: false,
      editingAgregator: {}
    }
  },
}

</script>