<template>
  <div>

    <el-dialog
        v-model="addEditModalVisible"
        :title="title"
        class="modal-95-70-60"
    >
      <el-row>
        <el-col :span="24">
          <el-form v-model="editingSchema.name" :label-position="'top'">
            <el-input v-model="editingSchema._id" style="display: none"></el-input>
            <el-form-item label="Название" prop="name">
              <el-input v-model="editingSchema.name"></el-input>
            </el-form-item>
            <el-form-item label="Приоритет" prop="priority">
              <el-input v-model="editingSchema.priority"></el-input>
            </el-form-item>
            <el-form-item label="Отправитель" prop="sender">
              <el-select v-model="editingSchema.sender" class="full-width">
                <el-option value="" label="Любой отправитель" />
                <el-option
                    v-for="name in senders"
                    :value="name.name"
                    :label="name.name"
                />
              </el-select>
            </el-form-item>

            <el-form-item label=" Для пользователя " prop="for_user">
              <el-select v-model="editingSchema.for_user" class="full-width">
                <el-option
                    v-for="user in managers"
                    :value="user.system_id"
                    :label="user.system_id"
                />
              </el-select>
            </el-form-item>

            <el-row :gutter="20">
              <el-col :md="12" :xs="24">
                <el-form-item label="Тип маршрута" prop="route_type">
                  <el-select v-model="editingSchema.route_type" class="full-width">
                    <el-option label="Оператор" value="operator"/>
                    <el-option label="Группа операторов" value="operatorGroup"/>
                    <el-option label="Страна" value="country"/>
<!--                    <el-option label="Зона" value="zone"/>-->
                  </el-select>
                </el-form-item>

              </el-col>
              <el-col :md="12" :xs="24">
                <el-form-item label="Маршрут" prop="route">
                  <el-select v-show="editingSchema.route_type === 'operator'"
                             filterable
                             v-model="editingSchema.route"
                             class="full-width"
                  >
                    <el-option
                        v-for="o in $store.getters.operators"
                        :value="o._id"
                        :label="o.OrgName"
                    />
                  </el-select>
                  <el-select v-show="editingSchema.route_type === 'operatorGroup'" class="full-width" filterable
                             v-model="editingSchema.route">
                    <el-option
                        v-for="o in $store.getters.operatorsGroups"
                        :value="o._id"
                        :label="o.title"
                    />
                  </el-select>
                  <el-select v-show="editingSchema.route_type === 'country'" class="full-width" filterable
                             v-model="editingSchema.route">
                    <el-option
                        v-for="o in $store.getters.countries"
                        :value="String(o.country_code)"
                        :label="o.country_name"
                    />
                  </el-select>
                  <el-select v-show="editingSchema.route_type === 'zone'" class="full-width"
                             v-model="editingSchema.route">
                    <el-option
                        v-for="o in $store.getters.zones"
                        :value="o._id"
                        :label="o.name"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="Пул агрегаторов" prop="agregator_pool">
              <el-select v-model="editingSchema.agregator_pool" class="full-width">
                <el-option
                    v-for="ag in agregators"
                    :value="ag._id"
                    :label="ag.name"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="Комментарий" prop="comment">
              <el-input v-model="editingSchema.comment" type="textarea"></el-input>
            </el-form-item>

          </el-form>
        </el-col>
      </el-row>
      <el-button type="success" @click="saveSchema" :loading="saving">Сохранить</el-button>

    </el-dialog>
    <el-row>
      <el-col :span="24">
        <el-button type="success" @click="showAssignModal" style="margin-bottom: 20px">Назначить</el-button>
        <TableSpinner :loading="loading">
          <el-table :data="schemasSorted">
            <el-table-column label="Для пользователя" prop="for_user"/>
            <el-table-column label="Название" prop="name"/>
            <el-table-column label="Отправитель" :formatter="row => row.sender || '[Любой]'"/>
            <el-table-column label="Маршрут" :formatter="routeName"/>
            <el-table-column label="Пул агрегаторов" :formatter="agregatorPoolName"/>
            <el-table-column label="Приоритет" prop="priority"/>
            <el-table-column label="Комментарий" prop="comment"/>
            <el-table-column label="">
              <template #default="scope">
                <ActionsDropdown>
                  <ActionButton icon="pencil-alt" @click="editSchema(scope.row)">Изменить</ActionButton>
                  <ActionButton type="remove" @confirm="removeSchema(scope.row)">Удалить</ActionButton>
                </ActionsDropdown>
              </template>
            </el-table-column>
          </el-table>
        </TableSpinner>
      </el-col>
    </el-row>
  </div>

</template>


<script>

import ActionsDropdown from "../../../shared/components/layout/actionsDropdown.component.vue";
import TableSpinner from "../../../shared/components/layout/table-spinner.component.vue";

export default {
  name: 'ClientTrafficSchemas',
  components: {TableSpinner, ActionsDropdown},
  props: ["systemId", "managers", "senders"],
  watch: {
    systemId(newVal) {
      console.log("call")
      console.log(newVal)
      if (newVal) {
        this.refresh()
      }
    }
  },
  mounted(){
    this.axios.get("/admin/routing/allPools")
        .then(resp => {
          this.agregators = resp.data
        })
  },
  methods: {
    refresh() {
      this.loading = true
      this.axios.get("/admin/routing/getIndividualTrafficSchemas", {params: {systemId: this.systemId}})
          .then(resp => {
            this.schemas = resp.data
            this.loading = false
          })
    },
    routeName(row) {
        if (row.route_type === 'operator') return "[Опер.] " + (this.$store.getters.operators.find(o => o._id === row.route) || {OrgName: "N/A"}).OrgName
        if (row.route_type === 'operatorGroup') return "[Груп.] " + (this.$store.getters.operatorsGroups.find(o => o._id === row.route) || {title: "N/A"}).title
        if (row.route_type === 'country') return "[Стра.] " + (this.$store.getters.countries.find(z => String(z.country_code) === row.route) || {country_name: "N/A"}).country_name
        if (row.route_type === 'zone') return "[Зона] " + this.$store.getters.zones.find(z => z._id === row.route).name
      return "N/A"
    },
    agregatorPoolName(row) {
      return (this.agregators.find(p => p._id === row.agregator_pool) || {name: "N/A"}).name
    },
    saveSchema() {
      this.saving = true
      this.axios.post("/admin/routing/saveIndividualTrafficSchema", {schema: this.editingSchema})
          .then(resp => {
            this.refresh()
            this.addEditModalVisible = false
            this.saving = false
          })
    },
    removeSchema(schema) {
      this.removingUserRate = true
      this.axios.delete("/admin/routing/schema", {params: {schemaId: schema._id}})
          .then(resp => {
            this.refresh()
          })
    },
    editSchema(schema) {
      this.editingSchema = schema
      this.addEditModalVisible = true
    },
    showAssignModal() {
      this.editingSchema = this.schemaInitialData()
      this.editingSchema.for_user = this.managers[0].system_id
      this.addEditModalVisible = true
    },
    schemaInitialData(){
      return  {_id: "", name: "", priority: "", sender: "",for_user: "",agregator_pool: "", route: "", comment: "", route_type: "operatorGroup"}
    }
  },
  computed: {
    title() {
      return this.editingSchema._id ? "Редактирование схемы" : "Новая схема"
    },
    schemasSorted(){
      return this.schemas.sort((s1, s2) => {
        let p = Number(s1.priority) - Number(s2.priority)
        if(p === 0){
          let u = Number(s1.for_user.split(".")[1] || 0) - Number(s2.for_user.split(".")[1] || 0)
          if(u === 0){
            return s1.sender.localeCompare(s2.sender)
          }else return u
        }else return p
      })
    }
  },
  data() {
    return {
      loading: false,
      saving: false,
      addEditModalVisible: false,
      editingSchema: this.schemaInitialData(),
      schemas: [],
      agregators: []
    }
  }
}

</script>

<style>

</style>