
export enum ModerationActionsT {
    Moderation = "Модерация",
    Monitoring = "Мониторинг",
    Block = "Блокировка"
}

export enum ModerationRuleLookupPlaceT {
    Text = "В тексте",
    Sender = "В имени отправителя",
    Everywhere = "Везде"
}


export enum ModerationActions {
    Moderation = "0",
    Monitoring = "1",
    Block = "2"
}

export enum ModerationRuleLookupPlace {
    Text = "0",
    Sender = "1",
    Everywhere = "2"
}
