<template>
  <el-dropdown-item command="">
    <RemoveButton
        :loading="loading"
        v-if="type === 'remove'"
        :table-button="true"
        @confirm="$emit('confirm')"
        :disabled="disabled || false"
    >
      <slot/>
    </RemoveButton>
    <el-button
        :type="type || 'default'"
        :text="!type"
        v-if="type !== 'remove' && type !== 'link'"
        size="small"
        class="text-button"
        plain
        @click="$emit('gclick')"
        :loading="loading"
        :disabled="disabled || false"
    >
      <font-awesome-icon :icon="icon" class="el-icon--left"></font-awesome-icon>
      <slot />
    </el-button>
    <a class="el-button el-button--small" v-if="type === 'link'" :href="to" :target="target || '_blank'"><font-awesome-icon :icon="icon" class="el-icon--left"></font-awesome-icon>
      <slot /></a>
  </el-dropdown-item>
</template>

<script>

import RemoveButton from "@shared/components/layout/removeButton.component";
export default {
  name: "ActionButton",
  components: {RemoveButton},
  props: ["icon", "type", "loading", 'to','target', 'disabled'],
  methods: {},
  data() {
    return {}
  },
}

</script>