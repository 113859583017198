<template>

  <div class="ticket-container" v-show="ticket && ticket._id">
    <div style="display: flex; flex-direction: column; align-items: flex-start">
      <p style="margin: 0px">{{ ticket.title }}</p>
      <span class="el-form-item__label" style="margin-top: 0px">Создан:&nbsp;{{ ticket.extras.createdAtB }}</span>
    </div>

    <slot name="admin-actions" :ticket="ticket" />

    <div style="display: flex; flex-direction: row;  align-items: center; justify-content: flex-end">
      <remove-button
          type="danger"
          :table-button="true"
          :regular="false"
          :loading="closing"
          :confirm-message="'Вы уверены, что хотите закрыть тикет?'"
          v-if="!ticket.extras.closed"
          @confirm="closeTicket"
      >Закрыть тикет
      </remove-button>
      <remove-button
          type="warning"
          :table-button="true"
          :regular="false"
          :loading="closing"
          icon="check"
          :confirm-message="'Вы уверены, что хотите открыть тикет заново?'"
          v-if="ticket.extras.closed && !isAdminView"
          @confirm="reopenTicket"
      >Переоткрыть
      </remove-button>
    </div>
  </div>

</template>

<script>


import RemoveButton from "@shared/components/layout/removeButton.component.vue";

export default {
  name: "ManageTicket",
  components: {RemoveButton},
  inject: ['isAdminView'],
  props: ['ticket', 'closeTicketMethod', 'reopenTicketMethod'],
  watch: {
    'ticket._id': function(){
      this.$emit("onTicketLoaded", this.ticket)
    }
  },
  methods: {
    closeTicket() {
      this.closing = true
      this.closeTicketMethod(this.ticket)
          .then(resp => {
            this.closing = false
            this.$gNotify("Запрос успешно закрыт", "success")
            this.$emit("onTicketStatusChange")
          })
    },
    reopenTicket() {
      this.closing = true
      this.reopenTicketMethod(this.ticket)
          .then(resp => {
            this.closing = false
            this.$gNotify("Запрос открыт заново", "success")
            this.$emit("onTicketStatusChange")
          })
    }
  },

  data() {
    return {
      t: "",
      closing: false
    }
  }
}

</script>

<style scoped>

.ticket-container {
  padding: 15px;
  border-bottom: 1px solid #CCC;
  display: flex;
  justify-content: space-between;
}

</style>