<template>
  <el-row :gutter="60">
    <el-col :md="10" :xs="24" v-if="embedded">
      <slot name="admin-settings"></slot>
    </el-col>
    <el-col :md="embedded ? 14 : 24" :xs="24">
      <slot name="admin-edit-info"></slot>
      <div class="el-table--fit el-table--border el-table--scrollable-x el-table--enable-row-hover el-table">
        <div class="el-table__body-wrapper is-scrolling-left">
          <table class="el-table__body" cellspacing="0" cellpadding="0" border="0" style="width: 100%">
            <tbody>
            <tr class="el-table__row">
              <td class=" el-table__cell  text-bold">ID</td>
              <td class=" el-table__cell ">{{ system_id }}</td>
            </tr>
            <tr class="el-table__row" v-if="agent_code && !embedded">
              <td class=" el-table__cell  text-bold">Код привлекателя</td>
              <td class=" el-table__cell ">{{ agent_code }}</td>
            </tr>
            <tr class="el-table__row">
              <td class=" el-table__cell  text-bold">Создан</td>
              <td class=" el-table__cell ">{{ formatDate(registered) }}</td>
            </tr>

            </tbody>
          </table>
        </div>
      </div>

      <h3 style="margin-top: 40px">Счета</h3>
      <div class="el-table--fit el-table--border el-table--scrollable-x el-table--enable-row-hover el-table">
        <div class="el-table__body-wrapper is-scrolling-left">
          <table class="el-table__body" cellspacing="0" cellpadding="0" border="0" style="width: 100%">
            <tbody>
            <tr class="el-table__row">
              <td class=" el-table__cell  text-bold">Баланс</td>
              <td class=" el-table__cell ">{{ formatNumber(balance.val) }}</td>
            </tr>
            <tr class="el-table__row" v-show="balance.overdraft > 0">
              <td class=" el-table__cell  text-bold">Лимит овердрафта</td>
              <td class=" el-table__cell ">{{ formatNumber(balance.overdraft) }} <span v-if="embedded && hasTimedOverdraft">до {{timedOverdraftExp}}</span></td>
            </tr>
            <!--                <tr class="el-table__row">-->
            <!--                  <td class=" el-table__cell ">Список e-mail адресов</td>-->
            <!--                  <td class=" el-table__cell ">-->
            <!--                    {{(info.financial_documents_delivery_emails || []).join(",") || ""}}-->
            <!--                  </td>-->
            <!--                </tr>-->
            <!--                <tr class="el-table__row">-->
            <!--                  <td class=" el-table__cell ">Получать в ЭДО</td>-->
            <!--                  <td class=" el-table__cell ">-->
            <!--                    {{ (info.send_edo === "1"? "Да" : 'Нет')}}-->
            <!--                  </td>-->
            <!--                </tr>-->
            <!--                <tr class="el-table__row" v-show="info.send_edo === '1'">-->
            <!--                  <td class=" el-table__cell ">Тип ЭДО</td>-->
            <!--                  <td class=" el-table__cell ">-->
            <!--                    {{ info.financial_documents_delivery_edo_type === 'sbis' ? 'СБИС' : info.financial_documents_delivery_edo_name || ""}}-->
            <!--                  </td>-->
            <!--                </tr>-->
            <!--                <tr class="el-table__row">-->
            <!--                  <td class=" el-table__cell ">Где пополнять баланс</td>-->
            <!--                  <td class=" el-table__cell ">{{balanceTopupPlace}}</td>-->
            <!--                </tr>-->
            </tbody>
          </table>
        </div>
      </div>
      <h3 style="margin-top: 40px">
        <!--            <el-button type="success" size="small"><font-awesome-icon icon="minus"/></el-button>-->
        Бухгалтерские документы
        <el-button text
                   class="text-button no-left-padding-small-screen" size="small" plain @click="editDocumentDelivery"
                   v-show="!documentDeliveryMethodEditing"
                   data-test="editDocumentDelivery"
        >
          <font-awesome-icon icon="pencil-alt" class="el-icon--left"></font-awesome-icon>
          Редактировать
        </el-button>
        <div v-show="documentDeliveryMethodEditing" style="display: inline">
          <el-button text
                     type="success" class="text-button no-left-padding-small-screen" size="small" plain
                     @click="saveDocumentDelivery" data-test="saveDocumentsDelivery"
          >
            <font-awesome-icon icon="check" class="el-icon--left"></font-awesome-icon>
            Сохранить
          </el-button>
          <el-button text
                     type="danger" class="text-button" size="small" plain @click="documentDeliveryMethodEditing = false"
                     data-test="cancelDocumentsDelivery"
          >
            <font-awesome-icon icon="times" class="el-icon--left"></font-awesome-icon>
            Отмена
          </el-button>
        </div>
      </h3>

      <div class="el-table--fit el-table--border el-table--scrollable-x el-table--enable-row-hover el-table">
        <div class="el-table__body-wrapper is-scrolling-left">
          <table class="el-table__body" cellspacing="0" cellpadding="0" border="0" style="width: 100%">
            <tbody>
            <tr class="el-table__row">
              <td class=" el-table__cell  text-bold" style="padding-right: 40px !important">Список e-mail адресов для
                получения
                закрывающих документов
              </td>
              <td class=" el-table__cell " v-show="!documentDeliveryMethodEditing">
                {{ (info.financial_documents_delivery_emails || []).join(",") || "" }}
              </td>
              <td class=" el-table__cell "
                  v-show="documentDeliveryMethodEditing">
                <el-input v-model="documentsDeliveryEmails"/>
              </td>
            </tr>
            <!--          <tr class="el-table__row">-->
            <!--            <td class=" el-table__cell  text-bold">Получать в ЭДО</td>-->
            <!--            <td class=" el-table__cell " v-show="!documentDeliveryMethodEditing">-->
            <!--              {{ (info.send_edo === "1" ? "Да" : 'Нет') }}-->
            <!--            </td>-->
            <!--            <td class=" el-table__cell " v-show="documentDeliveryMethodEditing"-->
            <!--                style="padding: 0px">-->
            <!--              <el-checkbox v-model="sendByEdo" data-test="sendInEdoCheckbox"/>-->
            <!--            </td>-->
            <!--          </tr>-->
            <!--          <tr class="el-table__row">-->
            <!--            <td class=" el-table__cell  text-bold">Тип ЭДО</td>-->
            <!--            <td class=" el-table__cell " v-show="!documentDeliveryMethodEditing">-->
            <!--              {{-->
            <!--                info.financial_documents_delivery_edo_type === 'sbis' ? 'СБИС' : info.financial_documents_delivery_edo_name || ""-->
            <!--              }}-->
            <!--            </td>-->
            <!--            <td class=" el-table__cell " v-show="documentDeliveryMethodEditing">-->
            <!--              <el-select v-model="edoType" data-test="edoTypeSelect">-->
            <!--                <el-option label="СБИС" value="sbis"/>-->
            <!--                <el-option label="Другие ЭДО" value="other"/>-->
            <!--              </el-select>-->
            <!--            </td>-->
            <!--          </tr>-->
            <!--          <tr class="el-table__row" v-show="documentDeliveryMethodEditing && edoType === 'other'">-->
            <!--            <td class=" el-table__cell  text-bold">Наименование системы ЭДО</td>-->
            <!--            <td class=" el-table__cell ">-->
            <!--              <el-input v-model="customEdoName" data-test="otherEdoName"/>-->
            <!--            </td>-->
            <!--          </tr>-->
            </tbody>
          </table>
        </div>
      </div>

      <h3 style="margin-top: 40px">Договор</h3>
      <div class="el-table--fit el-table--border el-table--scrollable-x el-table--enable-row-hover el-table">
        <div class="el-table__body-wrapper is-scrolling-left">
          <table class="el-table__body" cellspacing="0" cellpadding="0" border="0" style="width: 100%">
            <tbody>
            <tr class="el-table__row">
              <td class=" el-table__cell  text-bold">Вид договора</td>
              <td class=" el-table__cell " v-show="!editing">
                {{ String((info.contract || {}).type) === '0' ? 'Оферта' : '2-х сторонний' }}
              </td>
              <td class=" el-table__cell " v-show="editing">
                <el-select v-model="editingInfo.contract.type" class="full-width">
                  <el-option label="Оферта" value="0"/>
                  <el-option label="2-х сторонний" value="1"/>
                </el-select>
              </td>
            </tr>
            <tr class="el-table__row">
              <td class=" el-table__cell  text-bold">Вид оплаты</td>
              <td class=" el-table__cell " v-show="!editing">
                {{ String((info.contract || {}).payment_type) === '0' ? 'Авансовый' : 'Кредитный' }}
              </td>
              <td class=" el-table__cell " v-show="editing">
                <el-select v-model="editingInfo.contract.payment_type" class="full-width">
                  <el-option label="Авансовый" value="0"/>
                  <el-option label="Кредитный" value="1"/>
                </el-select>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <h3 style="margin-top: 40px">Контакт</h3>
      <div class="el-table--fit el-table--border el-table--scrollable-x el-table--enable-row-hover el-table">
        <div class="el-table__body-wrapper is-scrolling-left">
          <table class="el-table__body" cellspacing="0" cellpadding="0" border="0" style="width: 100%">
            <tbody>
            <tr class="el-table__row">
              <td class=" el-table__cell  text-bold">Фамилия</td>
              <td class=" el-table__cell" v-show="!editing">{{ info.lastname || "" }}</td>
              <td class=" el-table__cell " v-show="editing">
                <el-input v-model="editingInfo.lastname" class="full-width"/>
              </td>
            </tr>
            <tr class="el-table__row">
              <td class=" el-table__cell  text-bold">Имя</td>
              <td class=" el-table__cell " v-show="!editing">{{ info.firstname || "" }}</td>
              <td class=" el-table__cell " v-show="editing">
                <el-input v-model="editingInfo.firstname" class="full-width"/>
              </td>
            </tr>
            <tr class="el-table__row">
              <td class=" el-table__cell  text-bold">Отчество</td>
              <td class=" el-table__cell " v-show="!editing">{{ info.patronymic || "" }}</td>
              <td class=" el-table__cell " v-show="editing">
                <el-input v-model="editingInfo.patronymic" class="full-width"/>
              </td>
            </tr>
            <tr class="el-table__row">
              <td class=" el-table__cell  text-bold">E-mail</td>
              <td class=" el-table__cell " v-show="!editing">{{ info.email || "" }}</td>
              <td class=" el-table__cell " v-show="editing">
                <el-input v-model="editingInfo.email" class="full-width"/>
              </td>
            </tr>
            <tr class="el-table__row">
              <td class=" el-table__cell  text-bold">Мобильный телефон</td>
              <td class=" el-table__cell " v-show="!editing">{{ info.phone || "" }}</td>
              <td class=" el-table__cell " v-show="editing">
                <el-input v-model="editingInfo.phone" class="full-width"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-show="info.user_type === 'physic'">
        <h3 style="margin-top: 40px">Пасспорт</h3>
        <div class="el-table--fit el-table--border el-table--scrollable-x el-table--enable-row-hover el-table">
          <div class="el-table__body-wrapper is-scrolling-left">
            <table class="el-table__body" cellspacing="0" cellpadding="0" border="0" style="width: 100%">
              <tbody>
              <tr class="el-table__row">
                <td class=" el-table__cell  text-bold">Серия</td>
                <td class=" el-table__cell " v-show="!editing">{{ info.serial || "" }}</td>
                <td class=" el-table__cell " v-show="editing">
                  <el-input v-model="editingInfo.serial" class="full-width"/>
                </td>
              </tr>
              <tr class="el-table__row">
                <td class=" el-table__cell  text-bold">Номер</td>
                <td class=" el-table__cell " v-show="!editing">{{ info.number || "" }}</td>
                <td class=" el-table__cell " v-show="editing">
                  <el-input v-model="editingInfo.number" class="full-width"/>
                </td>
              </tr>
              <tr class="el-table__row">
                <td class=" el-table__cell  text-bold">Выдан</td>
                <td class=" el-table__cell " v-show="!editing">{{ info.issued || "" }}</td>
                <td class=" el-table__cell " v-show="editing">
                  <el-input v-model="editingInfo.issued" class="full-width"/>
                </td>
              </tr>
              <tr class="el-table__row">
                <td class=" el-table__cell  text-bold">Дата выдачи</td>
                <td class=" el-table__cell " v-show="!editing">{{ info.issued_date || "" }}</td>
                <td class=" el-table__cell " v-show="editing">
                  <el-input v-model="editingInfo.issued_date" class="full-width"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-show="info.user_type !== 'physic'">
        <h3 style="margin-top: 40px">Юридическое лицо</h3>
        <p v-if="embedded">Статус на момент регистрации в
          системе: <b>{{ company_statuses[info.company_status] || info.company_status }}</b>
        </p>
        <p v-if="embedded">
          Дата регистрации компании: <b>{{ formatDate(info.company_registration_date, "DD.MM.YYYY") }}</b>
        </p>
        <div class="el-table--fit el-table--border el-table--scrollable-x el-table--enable-row-hover el-table">
          <div class="el-table__body-wrapper is-scrolling-left">
            <table class="el-table__body" cellspacing="0" cellpadding="0" border="0" style="width: 100%">
              <tbody>
              <tr class="el-table__row">
                <td class=" el-table__cell  text-bold">Форма налогообложения</td>
                <td class=" el-table__cell " v-show="!editing">{{ info.tax_form || "" }}</td>
                <td class=" el-table__cell " v-show="editing">
                  <el-select v-model="editingInfo.tax_form" class="full-width">
                    <el-option label="Традиционная с НДС" value="Традиционная с НДС"/>
                    <el-option label="Упрощенная без НДС" value="Упрощенная без НДС"/>
                    <el-option label="Иностранный клиент" value="Иностранный клиент"/>
                  </el-select>
                </td>
              </tr>
              <tr class="el-table__row">
                <td class=" el-table__cell  text-bold">Название компании</td>
                <td class=" el-table__cell " v-show="!editing">{{ info.company_name || "" }}</td>
                <td class=" el-table__cell " v-show="editing">
                  <el-input v-model="editingInfo.company_name" class="full-width"/>
                </td>
              </tr>
              <tr class="el-table__row">
                <td class=" el-table__cell  text-bold">Должность</td>
                <td class=" el-table__cell " v-show="!editing">{{ info.position || "" }}</td>
                <td class=" el-table__cell " v-show="editing">
                  <el-select v-model="editingInfo.position" class="full-width">
                    <el-option label="Генеральный директор" value="Генеральный директор"/>
                    <el-option label="Директор" value="Директор"/>
                    <el-option label="Президент" value="Президент"/>
                  </el-select>
                </td>
              </tr>
              <tr class="el-table__row">
                <td class=" el-table__cell  text-bold">ФИО в именит. падеже</td>
                <td class=" el-table__cell " v-show="!editing">{{ info.gendir || "" }}</td>
                <td class=" el-table__cell " v-show="editing">
                  <el-input v-model="editingInfo.gendir" class="full-width"/>
                </td>
              </tr>
              <tr class="el-table__row">
                <td class=" el-table__cell  text-bold">Действует на основании</td>
                <td class=" el-table__cell " v-show="!editing">{{ info.motivation || "" }}</td>
                <td class=" el-table__cell " v-show="editing">
                  <el-select v-model="editingInfo.motivation" class="full-width">
                    <el-option label="Устава" value="Устава"/>
                    <el-option label="Доверенности" value="Доверенности"/>
                  </el-select>
                </td>
              </tr>
              <tr class="el-table__row">
                <td class=" el-table__cell  text-bold">Доверенность №</td>
                <td class=" el-table__cell " v-show="!editing">{{ info.proxy_number || "" }}</td>
                <td class=" el-table__cell " v-show="editing">
                  <el-input v-model="editingInfo.proxy_number" class="full-width"/>
                </td>
              </tr>
              <tr class="el-table__row">
                <td class=" el-table__cell  text-bold">От</td>
                <td class=" el-table__cell " v-show="!editing">{{ info.proxy_date }}</td>
                <td class=" el-table__cell " v-show="editing">
                  <el-input v-model="editingInfo.proxy_date" class="full-width"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <h3 style="margin-top: 40px">Карточка юридического лица</h3>
        <div class="el-table--fit el-table--border el-table--scrollable-x el-table--enable-row-hover el-table">
          <div class="el-table__body-wrapper is-scrolling-left">
            <table class="el-table__body" cellspacing="0" cellpadding="0" border="0" style="width: 100%">
              <tbody>
              <tr class="el-table__row">
                <td class=" el-table__cell  text-bold">ИНН</td>
                <td class=" el-table__cell " v-show="!editing">{{ info.inn || "" }}</td>
                <td class=" el-table__cell " v-show="editing">
                  <el-input v-model="editingInfo.inn" class="full-width"/>
                </td>
              </tr>
              <tr class="el-table__row">
                <td class=" el-table__cell  text-bold">КПП</td>
                <td class=" el-table__cell " v-show="!editing">{{ info.kpp || "" }}</td>
                <td class=" el-table__cell " v-show="editing">
                  <el-input v-model="editingInfo.kpp" class="full-width"/>
                </td>
              </tr>
              <tr class="el-table__row">
                <td class=" el-table__cell  text-bold">р/с</td>
                <td class=" el-table__cell " v-show="!editing">{{ info.rs || "" }}</td>
                <td class=" el-table__cell " v-show="editing">
                  <el-input v-model="editingInfo.rs" class="full-width"/>
                </td>
              </tr>
              <tr class="el-table__row">
                <td class=" el-table__cell  text-bold">БИК</td>
                <td class=" el-table__cell " v-show="!editing">{{ info.bik || "" }}</td>
                <td class=" el-table__cell " v-show="editing">
                  <el-input v-model="editingInfo.bik" class="full-width"/>
                </td>
              </tr>
              <tr class="el-table__row">
                <td class=" el-table__cell  text-bold">Название банка</td>
                <td class=" el-table__cell " v-show="!editing">{{ info.bank_name || "" }}</td>
                <td class=" el-table__cell " v-show="editing">
                  <el-input v-model="editingInfo.bank_name" class="full-width"/>
                </td>
              </tr>
              <tr class="el-table__row">
                <td class=" el-table__cell text-bold">к/c</td>
                <td class=" el-table__cell " v-show="!editing">{{ info.ks || "" }}</td>
                <td class=" el-table__cell " v-show="editing">
                  <el-input v-model="editingInfo.ks" class="full-width"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <h3 style="margin-top: 40px">Юридический адрес</h3>
      <div class="el-table--fit el-table--border el-table--scrollable-x el-table--enable-row-hover el-table">
        <div class="el-table__body-wrapper is-scrolling-left">
          <table class="el-table__body" cellspacing="0" cellpadding="0" border="0" style="width: 100%">
            <tbody>
            <tr class="el-table__row">
              <td class=" el-table__cell  text-bold">Адрес</td>
              <td class=" el-table__cell " v-show="!editing">{{ info.individual_adress || "" }}</td>
              <td class=" el-table__cell " v-show="editing">
                <el-input v-model="editingInfo.individual_adress" class="full-width"/>
              </td>
            </tr>
            <tr class="el-table__row">
              <td class=" el-table__cell  text-bold">Телефон</td>
              <td class=" el-table__cell " v-show="!editing">{{ info.individual_phone || "" }}</td>
              <td class=" el-table__cell " v-show="editing">
                <el-input v-model="editingInfo.individual_phone" class="full-width"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <h3 style="margin-top: 40px">Почтовый адрес для корреспонденции</h3>
      <div class="el-table--fit el-table--border el-table--scrollable-x el-table--enable-row-hover el-table">
        <div class="el-table__body-wrapper is-scrolling-left">
          <table class="el-table__body" cellspacing="0" cellpadding="0" border="0" style="width: 100%">
            <tbody>
            <tr class="el-table__row">
              <td class=" el-table__cell  text-bold">Адрес</td>
              <td class=" el-table__cell " v-show="!editing">{{ info.mail_adress || "" }}</td>
              <td class=" el-table__cell " v-show="editing">
                <el-input v-model="editingInfo.mail_adress" class="full-width"/>
              </td>
            </tr>
            <tr class="el-table__row">
              <td class=" el-table__cell  text-bold">Телефон</td>
              <td class=" el-table__cell " v-show="!editing">{{ info.mail_phone || "" }}</td>
              <td class=" el-table__cell " v-show="editing">
                <el-input v-model="editingInfo.mail_phone" class="full-width"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>


import moment from "moment";

export default {
  name: "UserCardComponent",
  title: "Карточка пользователя",
  components: {},
  props: ["infoIn", "systemId", "embedded", "editing"],
  mounted() {
    if (!this.systemId) this.getInfo()
    else this.system_id = this.systemId
  },
  watch: {
    infoIn() {
      this.infoIn.balance.val = this.infoIn.balance.balance
      this.fillInfo({
        system_id: this.systemId,
        __data: {info: this.infoIn.info,},
        balance: {val: this.infoIn.balance.balance, overdraft: this.infoIn.balance.overdraft},
        overdraftInfo: this.infoIn.balance.overdraftInfo,
        registered: this.infoIn.registered
      })
    },
    editing(v) {
      if (v) {
        for (let [pKey, v] of Object.entries(this.editingInfo)) {
          if(pKey === "contract") continue
            this.editingInfo[pKey] = this.info[pKey]
        }
        this.editingInfo.contract.payment_type = this.info.contract.payment_type
        this.editingInfo.contract.type = this.info.contract.type
      }
    }
  },
  computed: {
    balanceTopupPlace() {
      if (this.info.autopay_place === 'here' || !this.info.autopay_place) return 'Здесь'
      if (this.info.autopay_place === 'gamma') return 'Гамма'
      if (this.info.autopay_place === 'old_gold') return 'Старый Голд'
      if (this.info.autopay_place === 'new_gold') return 'Новый Голд'
    },
    hasTimedOverdraft(){
      return this.overdraftInfo.till && !moment(this.overdraftInfo.till).isBefore(moment())
    },
    timedOverdraftExp(){
      return moment(this.overdraftInfo.till).format("DD.MM.YYYY")
    }
  },
  methods: {
    getInfo() {
      this.axios.get("/user/getMyInfo")
          .then(resp => {
            this.fillInfo(resp.data)
          })
    },
    fillInfo(data) {

      this.system_id = data.system_id
      this.agent_code = data.agent_code
      this.info = data.__data.info
      this.registered = data.__data.registered
      this.balance = data.balance
      this.documentsDeliveryEmails = (data.__data.info.financial_documents_delivery_emails || []).join(",") || ""
      if(data.overdraftInfo && data.overdraftInfo.till)
        this.overdraftInfo = data.overdraftInfo
      else
        this.overdraftInfo = {till : ""}
      // this.edoType = data.__data.info.financial_documents_delivery_edo_type
      // this.customEdoName = data.__data.info.financial_documents_delivery_edo_name
    },
    formatDate(val) {
      return !val ? "" : moment(val).format("DD.MM.YYYY HH:mm:ss")
    },
    editDocumentDelivery() {
      this.documentDeliveryMethodEditing = true
    },
    saveDocumentDelivery() {
      this.axios.post(this.embedded ? "/admin/clients/updateDocumentsDeliveryInfo" : "/user/updateDocumentsDeliveryInfo", {
        deliveryEmails: (this.documentsDeliveryEmails || "").split(",").map(v => v.trim()).filter(v => v),
        // edoType: this.edoType,
        // customEdoName: this.customEdoName,
        systemId: this.system_id
      }).then(resp => {
        this.documentDeliveryMethodEditing = false
        if (!this.embedded)
          this.getInfo()
        else
          this.$emit("reloadUser")

      })
    },
    getEditingInfo(){
      return this.editingInfo
    }
  },
  data() {
    return {
      documentDeliveryMethodEditing: false,
      balance: {
        val: 0,
        overdraft: 0,

      },
      overdraftInfo: {till : ""},
      system_id: "",
      agent_code: "",
      registered: "",
      info: {},
      editingInfo: {
        contract: {
          type: "0",
          payment_type: "0"
        },
        firstname: "",
        lastname: "",
        patronymic: "",
        phone: "",
        email: "",

        serial: "",
        number: "",
        issued: "",
        issued_date: "",


        tax_form: "",
        position: "",
        gendir: "",
        company_name: "",
        motivation: "",
        proxy_number: "",
        proxy_date: "",

        inn: "",
        kpp: "",
        rs: "",
        bik: "",
        bank_name: "",
        ks: "",

        individual_phone: "",
        individual_adress: "",

        mail_phone: "",
        mail_adress: "",

      },

      documentsDeliveryEmails: "",
      // edoType: "",
      // customEdoName: ""
      company_statuses : {
        'ACTIVE': "Действующая",
        "LIQUIDATING": "Ликвидируется",
        "LIQUIDATED": "Ликвидирована",
        "REORGANIZING": "В процессе слияния с последующей ликвидацией"
      }
    }
  }
}

</script>