const {ElMessageBox} = require("element-plus");
let verifySenderNameUsageMixin = {
  methods: {
    verifySenderNameUsage: function (nameId, callback) {
      this.axios.get("/admin/support/verifySenderNameUsage", {params: {requestId: nameId}})
        .then(resp => {
          let {serviceTemplates, trafficSchemas, substitutionRules, blockRules, usedRates, conversionRulesRates} = resp.data
          ElMessageBox.confirm(
            `
                <p><span>Сервисные шаблоны:</span> <span><b>${serviceTemplates}</b></span></p>
                <p><span>Тарифы профи:</span> <span><b> ${usedRates.profi.join("&nbsp;&nbsp;|&nbsp;&nbsp;")}</b></span></p>
                <p><span>Международные тарифы:</span> <span><b> ${usedRates.international.join("&nbsp;&nbsp;|&nbsp;&nbsp;")}</b></span></p>
                <p><span>Тарифы с конверсией текста:</span> <span><b> ${conversionRulesRates.join("&nbsp;&nbsp;|&nbsp;&nbsp;")}</b></span></p>
                <p><span>Индивид. схемы трафика:</span> <span><b> ${trafficSchemas.individual}</b></span></p>
                <p><span>Индивид. подмены:</span> <span><b> ${substitutionRules.individual}</b></span></p>
                <p><span>Глобальные схемы трафика:</span> <span><b> ${trafficSchemas.global}</b></span></p>
                <p><span>Глобальные подмены:</span> <span><b> ${substitutionRules.global}</b></span></p>
                <p><span>Блокировки направлений:</span> <span><b> ${blockRules}</b></span></p>
                `,
            'Использование подписи',
            {
              confirmButtonText: 'Подтвердить удаление',
              cancelButtonText: 'Отмена',
              type: 'danger',
              dangerouslyUseHTMLString: true,
              customStyle: "max-width: 600px;",
              customClass: "sendername-usage-p"
            }
          )
            .then(() => {
              callback()
            })
            .catch(() => {

            })
        })

    }
  }
}

module.exports = verifySenderNameUsageMixin