

import ServiceTemplatesTable from "@shared/components/lk/service-templates-table.component.vue";
import {defineComponent} from "vue";
import RemoveButton from "@shared/components/layout/removeButton.component.vue";
import moment from "moment";

class NewTemplateForm {
  public id = ""
  public operatorGroup = ""
  public trafficType = "service"
  public sender = ""
  public texts = ""
}

interface ServiceTemplate {
  id: string,
  opg_id: string,
  sender_name: string,
  template: string,
  oper_group: string,
  traffic_type: string,
  addEditDate: string
}

export default defineComponent({
  name: "ClientServiceTemplates",
  props: ["systemId", "senders"],
  components: {
    RemoveButton,
    ServiceTemplatesTable
  },
  watch: {
    systemId(v) {
      if (v) this.loadTemplates()
    },
    showTuplesOnly(){
      this.selection = []
      this.selectAll = []
    },
    selectAll(v){
      if(v && v.length){
        this.selection = (this.$refs.mTable as any).getVisibleIds()
      }else{
        this.selection = []
      }
    }
  },
  methods: {
    loadTemplates() {
      this.templatesLoading = true
      this.axios.get("/admin/clients/serviceTemplates", {params: {userId: this.systemId}})
          .then(resp => {
            this.templates = resp.data as ServiceTemplate[]
            this.templatesLoading = false
          })
    },
    showAddForm() {
      this.addForm = new NewTemplateForm()
      this.addFormVisible = true
    },
    saveNewTemplates() {
      this.saving = true
      this.axios.post("/admin/clients/addServiceTemplates", {systemId: this.systemId, ...this.addForm})
          .then(resp => {
            this.saving = false
            if(resp.data.error){
              this.$gNotify("Такой шаблон для этой подписи уже назначен", "error")
            }else{
              this.addFormVisible = false
              this.$gNotify(`Добавлено: ${resp.data.added} | Дубли: ${resp.data.skipped}`, "success")
              this.loadTemplates()
            }

          })
    },
    editRow(r) {
      this.addForm.operatorGroup = r.opg_id
      this.addForm.trafficType = r.traffic_type
      this.addForm.sender = r.sender_name
      this.addForm.texts = r.template
      this.addForm.id = r.id
      this.addFormVisible = true
    },
    removeSelected(){
      this.removing=true
      this.axios.post("/admin/clients/removeServiceTemplates", {systemId: this.systemId, templates: this.selection})
          .then(resp => {
            this.removing = false
            this.selection = []
            this.selectAll = []
            this.loadTemplates()
            this.$gNotify("Шаблоны удалены", "success")
          })
    },
    lastMonthCounter(row){
      let lastCounter = (row.counters.sort((c1, c2) => moment(c2.month, "MM.YYYY").valueOf() - moment(c1.month, "MM.YYYY").valueOf()) || [])[0] || {counter: 0}
      if(!lastCounter.month) return "N/A"
      if(lastCounter.month !== moment().format("MM.YYYY")) return `${lastCounter.counter} (${lastCounter.month})`
      return lastCounter.counter
    },
    showAllCounters(row){
      this.currentCounters = row.counters.sort((c1, c2) => moment(c2.month, "MM.YYYY").valueOf() - moment(c1.month, "MM.YYYY").valueOf())
      this.countersModalVisible = true
    },
    showAllCountersCumsum(){
      let allVisibleTemplates = (this.$refs.mTable as any).getFilteredTemplates()
      this.currentCounters = Object.values(allVisibleTemplates.reduce((acc, t) => {
        for(let counter of (t.counters || [])) {
          if (!acc[counter.month]) acc[counter.month] = {month: counter.month, counter: 0}
          acc[counter.month].counter += Number(counter.counter) || 0
        }
        return acc
      }, {})).sort((c1, c2) => moment((c2 as any).month, "MM.YYYY").valueOf() - moment((c1 as any).month, "MM.YYYY").valueOf()) as []
      this.countersModalVisible = true
    },
    bMonth(v){
      return moment(v, "MM.YYYY").format("MMMM YYYY")
    },
    recalculateCumsum(templates){
      this.allCountersCumsum = (templates || []).reduce((acc, t) => {
        return acc + (Number(this.lastMonthCounter(t)) || 0)
      }, 0)
    }
  },
  computed: {
    visibleTemplates():ServiceTemplate[] {
      if (!this.showTuplesOnly) return this.templates
      let duplicates = {}
      for (let t of this.templates) {
        if (!duplicates[t.opg_id]) duplicates[t.opg_id] = {}
        let k = `${t.template}_${t.sender_name || ""}_${t.traffic_type || ""}`
        if (!duplicates[t.opg_id][k]) duplicates[t.opg_id][k] = []
        duplicates[t.opg_id][k].push(t)
      }
      return Object.values(duplicates).map(d => Object.values(d as object).filter(vv => vv.length > 1).flat()).filter(v => v.length).flat() as ServiceTemplate[]
    },

  },
  data() {
    return {
      selectAll: [],
      selection: [] as string[],
      templates: [] as ServiceTemplate[],
      showTuplesOnly: false,
      addFormVisible: false,
      addForm: new NewTemplateForm(),
      saving: false,
      removing: false,
      templatesLoading: false,
      countersModalVisible: false,
      currentCounters : [],
      allCountersCumsum: 0
    }
  }
})

