

import {defineComponent} from 'vue'
import RemoveButton from "@shared/components/layout/removeButton.component.vue";
import {
  ModerationActions,
  ModerationActionsT,
  ModerationRuleLookupPlace,
  ModerationRuleLookupPlaceT
} from "@shared/enums/moderation";

class ModerationRule {
  _id = ""
  word = ""
  where = ModerationRuleLookupPlace.Text

  constructor(da: ModerationActions = ModerationActions.Monitoring) {
    this._id = String(Math.random())
  }

  fromJson(v) {
    this._id = v._id
    this.word = v.word
    this.where = v.where || this.where
    return this
  }
}

class ModerationTemplate {
  _id = ""
  name = ""
  isActive = false
  defaultAction = ModerationActions.Monitoring
  rules = new Array<ModerationRule>()
  expanded = true

  constructor() {
    this._id = String(Math.random())
    // this.rules.push(new ModerationRule(this.defaultAction))
  }

  fromJson(v): ModerationTemplate {
    this._id = v._id
    this.name = v.name
    this.defaultAction = v.defaultAction || this.defaultAction
    this.rules = v.rules.map(r => new ModerationRule().fromJson(r))
    this.isActive = v.isActive
    this.expanded = false
    return this
  }


  addRule() {
    this.rules.unshift(new ModerationRule(this.defaultAction))
  }

  removeRule(ruleId) {
    this.rules.splice(this.rules.findIndex(r => r._id === ruleId), 1)
  }
}

export default defineComponent({
  name: "ModerationRules",
  title: "Шаблоны модерации",
  components: {RemoveButton},
  methods: {
    async getAllRules() {
      this.loading = true
      let resp = await this.axios.get("/admin/moderation/templates")
      this.allTemplates = resp.data.map(t => new ModerationTemplate().fromJson(t))
      this.loading = false
    },
    addTemplate() {
      this.allTemplates.unshift(new ModerationTemplate())
    },

    async saveTemplate(template: ModerationTemplate) {
      this.saving = true
      let r = await this.axios.post("/admin/moderation/template", {template})
      template._id = r.data
      this.$gNotify("Шаблон сохранён", "success")
      this.saving = false
    },
    async removeTemplate(templateId: string) {
      if(!templateId.includes('.')) {
        this.removingOne = true
        await this.axios.delete("/admin/moderation/template", {params: {templateId}})
        this.$gNotify("Шаблон удалён", "success")
        this.removingOne = false
      }
      this.allTemplates.splice(this.allTemplates.findIndex(r => r._id === templateId), 1)
    },
    isDuplicate(rule: ModerationRule, tid: string){
      return !!this.allTemplates.find(t => !!t.rules.find(r => r.word === rule.word && r._id !== rule._id))
    }
  },
  computed: {

  },
  mounted() {
    this.getAllRules()
  },
  setup() {
    return {ModerationActions, ModerationRuleLookupPlace, ModerationActionsT, ModerationRuleLookupPlaceT}
  },
  data() {
    return {
      loading: false,
      allTemplates: [] as ModerationTemplate[],
      removingOne: false,
      saving: false
    }
  },
})

