<template>
  <TableSpinner :no-min-height="true" :loading="loading" :rows="2">
  <div
      style="min-width: 300px; border-bottom: 1px solid #CCC; display: flex;align-items: center;justify-content: center">

    <span class="full-width align-center connection-selection-container connection-selection-padding"
          v-if="!connections.length">
      Нет активных подключений
    </span>

      <el-dropdown trigger="click" v-else class="full-width">
      <span class="el-dropdown-link full-width selected-connection-name connection-selection-padding">

        <span
            style="font-size: 12px; color: var(--el-text-color-placeholder);margin-right: 15px">{{ textualType[0] }}</span>
        <span style="flex-grow: 1">{{ selectedConnection.name || "Выберите " + textualType[1] }}&nbsp;</span>
        <font-awesome-icon icon="chevron-down" style="font-size: 14px"/>
      </span>
        <template #dropdown>
          <el-dropdown-menu style="width: 250px">
            <el-dropdown-item
                v-for="conn in connections"
                @click="selectedConnection = conn"
            >{{ conn.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
  </div>
  </TableSpinner>

</template>

<script>

import TableSpinner from "@shared/components/layout/table-spinner.component.vue";

export default {
  name: "DialogConnectionSelect",
  props: ["connections", "loading", "modelValue"],
  emits: ['update:modelValue'],
  components: {TableSpinner},
  watch: {
    connections(conns) {
      if (conns.length)
        this.selectedConnection = conns[0]
    },
    selectedConnection(v) {
      this.$emit("update:modelValue", v)
    }
  },
  mounted() {
  },
  methods: {},
  computed: {
    textualType() {
      switch (this.selectedConnection.type) {
        case "tgBot":
          return ["Бот", "бота"]
        case "tgPersonal":
          return ["Канал", "канал"]
      }
    }
  },
  data() {
    return {
      selectedConnection: {name: "", _id: "", type: ""}
    }
  }
}

</script>

<style scoped>
.connection-selection-padding {
  padding: 25px 15px;
}

.connection-selection-container {

  color: var(--el-color-danger);
}

.selected-connection-name {
  font-size: 19px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>