<template>

  <div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start" id="header-container">
    <div @click="$store.dispatch('toggleMenu')" id="menu-trigger">
      <font-awesome-icon icon="bars" class="control-button"
      ></font-awesome-icon>
    </div>
    <div style="flex-grow: 1; align-items: center">
    <img id="main-logo" src="@shared/assets/static_img/logo.png" style=" margin: 10px 0px; height: 30px"/>
    </div>
    <div @click="$store.dispatch('toggleMenu')" id="menu-trigger-header">
      <font-awesome-icon icon="bars" class="control-button"
      ></font-awesome-icon>
    </div>

    <div style="height: 100%;display: flex; align-items: center; justify-content: center; cursor: pointer" @click="logout()" data-test="logoutButton" id="logout-button" >
      <img src="@shared/assets/static_img/signout.svg" style="height: 25px;margin-left: 20px" />
    </div>
  </div>

</template>


<script>

const timeoutInMS = Math.round(1.5 * 60 * 60 * 1000)
const lastActionDelay = 60 * 60 * 1000
export default {
  name: 'Header',
  methods: {
    logout(){
      this.removeEventListeners()
      this.$store.dispatch("logout")
      clearInterval(this.balanceTimer)
      this.$router.push({path: "/login"})
    },

    handleInactive(){
      console.log("Token expired")
      console.log(`Last action at: ${this.lastActionTime} | Now : ${Date.now()}`)
      if(Date.now() - this.lastActionTime < lastActionDelay){
        console.log("refreshing token")
       this.$store.dispatch("refreshUser")
        .then(() => {
          this.restartLogoutTimer()
        })
      }else{
        console.log("Logout")
        clearTimeout(this.logoutTimer)
        this.logout()
      }
    },
    removeEventListeners(){
      document.removeEventListener("keypress", this.setLastActivityTime, false);
      document.removeEventListener("mousemove", this.setLastActivityTime, false);
      document.removeEventListener("mousedown", this.setLastActivityTime, false);
      document.removeEventListener("touchmove", this.setLastActivityTime, false);
    },
    setLastActivityTime(){
      this.lastActionTime = Date.now()
    },
    restartLogoutTimer(){
      this.removeEventListeners()
      clearTimeout(this.logoutTimer)
      this.logoutTimer = setTimeout(this.handleInactive, timeoutInMS);

      document.addEventListener("keypress", this.setLastActivityTime, false);
      document.addEventListener("mousemove", this.setLastActivityTime, false);
      document.addEventListener("mousedown", this.setLastActivityTime, false);
      document.addEventListener("touchmove", this.setLastActivityTime, false);


    }
  },
  beforeUnmount() {
    this.removeEventListeners()
    clearTimeout(this.logoutTimer)
  },
  mounted(){
    this.axios.get("/tokenPing")
    .then()
    .catch(e => {
      if(JSON.parse(JSON.stringify(e)).status === 401)
        this.logout()
    })
    this.restartLogoutTimer();
  },
  data(){
    return {
      logoutTimer : null,
      lastActionTime: 0
    }
  }
}

</script>

<style>
.control-button{
  cursor: pointer;
  font-size: 25px;
  color: #31373a;
  margin-right: 20px
}
#menu-trigger-header{
  display: none
}
</style>