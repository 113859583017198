<template>
  <el-container class="full-height">
    <el-header style="background-color: #343434; text-align: center;padding: 20px 0px; height: auto">
      <div>
          <img id="main-logo" src="/static_img/logo.png"/>
      </div>
    </el-header>
    <el-container>
      <el-container class="full-height">
        <el-main>
          <router-view></router-view>
        </el-main>
        <el-footer>
          <p class="align-center">SmsGold © 2014 - {{ year }}</p>
        </el-footer>
      </el-container>
    </el-container>
  </el-container>
</template>


<script>

import moment from "moment/moment";

export default {
  name: 'AuthLayout',
  data(){
    return {
      year: moment().format("YYYY")
    }
  }
}

</script>