<template>

    <div
        :class="type === 'separator' ? 'day-separator' : `bot-message ${fromClient ? 'bot-message-left' : 'bot-message-right'}` + ` ${className || ''}`"
        v-if="text || media"
    >
      <p class="bot-message-author-hint"  v-if="type !== 'separator' && authorHint && prevMessageAuthorDifferent !== false">
        {{ authorHint }}
      </p>
      <p class="day-separator" v-if="type === 'separator'">{{ text }}</p>
      <div class="bot-message-text"  v-if="type !== 'separator'">

        <img class="message-image" :src="attachmentHref" v-if="msgHasImage"/>
        <audio controls v-if="msgHasAudio">
          <source :src="attachmentHref" type="audio/ogg" v-if="msgHasOggAudio">
          <source :src="attachmentHref" type="audio/mpeg" v-if="msgHasMpegAudio">
          Your browser does not support the audio tag.
        </audio>
        <video class="message-image" controls v-if="msgHasVideo">
          <source :src="attachmentHref" type="video/ogg" v-if="msgHasOggVideo">
          <source :src="attachmentHref" type="video/mp4" v-if="msgHasMp4Video">
          Your browser does not support the audio tag.
        </video>
        <a v-if="msgHasDocument" :href="attachmentHref" target="_blank">
          <font-awesome-icon icon="file" style="margin-right: 10px"/>
          <span>{{ media.name }}</span>
        </a>

        <p
            v-if="textAsHtml"
            v-html="(textWithRandomisers || '').replace(/\n/g, '<br/>')"
            @click="e => $emit('textClicked', e)"
            v-show="!!text" style="margin: 0px 5px"
        ></p>
        <p
            v-if="!textAsHtml"
            @click="e => $emit('textClicked', e)"
            v-show="!!text" style="margin: 0px 5px"
        >{{text}}</p>


        <div v-if="buttons && buttons.length" class="message-buttons-container">
          <div class="message-button" v-for="button in buttons.filter(b => b && b.text && b.url)" >
            <a class="message-button-link" target="_blank" :href="formatUrl(button.url)"
               v-if="button.url">{{ button.text }}</a>
            <span v-else>{{ button.text }}</span>
          </div>
        </div>
        <p class="bot-message-timestamp"  v-if="type !== 'separator' && showTimestamp !== false">
          {{ timeReceived }}
          <span v-if="!fromClient && !hideMessageDeliveryStatus">
          <font-awesome-icon v-if="status === 'awaiting'" icon="clock"/>
          <font-awesome-icon v-if="status === 'success'" icon="check"/>
          <font-awesome-icon v-if="status === 'failure'" icon="times" style="color: red"/>
        </span>
        </p>
      </div>


    </div>

</template>

<script>

export default {
  name: "DialogMessage",
  components: {},
  inject: ["messageMediaHrefAppendix", "messageAuthorHints", "hideMessageDeliveryStatus", "textAsHtml"],
  props: [
      "text", "fromClient", "media", "type", "buttons",
    "showTimestamp", "timeReceived", "status", "inspectFileBaseString", "className", "messageId", "randomisers",
    "prevMessageAuthorDifferent"
  ],
  computed: {
    authorHint(){
      if(!this.messageAuthorHints) return ""
      return this.messageAuthorHints[this.fromClient ? "foreign" : "my"]
    },
    msgHasImage() {
      return this.media && ((this.media.mime && this.media.mime.startsWith('image')) || this.media.type === 'photo')
    },
    msgHasAudio() {
      return this.media && ((this.media.mime && this.media.mime.startsWith('audio')) || this.media.type === 'audio')
    },
    msgHasOggAudio() {
      return this.media && this.media.mime && this.media.mime.startsWith('audio/ogg')
    },
    msgHasMpegAudio() {
      return this.media && this.media.mime && this.media.mime.startsWith('audio/mpeg')
    },
    msgHasVideo() {
      return this.media && ((this.media.mime && this.media.mime.startsWith('video')) || this.media.type === "video")
    },
    msgHasOggVideo() {
      return this.media && this.media.mime && this.media.mime.startsWith('video/ogg')
    },
    msgHasMp4Video() {
      return this.media && this.media.mime && this.media.mime.startsWith('video/mp4')
    },
    msgHasDocument() {
      return this.media && (this.media.type === 'document' || (!this.msgHasImage && !this.msgHasVideo && !this.msgHasAudio)) && this.media.name
    },

    attachmentHref(){
      if(this.inspectFileBaseString && this.media && this.media.baseString){
        return this.msgHasDocument ? "" : this.media.baseString
      }
      let href = `${this.axios.defaults.baseURL}/dialogs/dialogAttachment?token=${this.$store.getters.bareToken}`
      href += `&key=${encodeURIComponent(this.media.key)}`
      href += `&architype=${this.media.architype}`
      if(this.media.name)
        href += `&asName=${encodeURIComponent(this.media.name)}`
      if(this.messageId)
        href += `&messageId=${encodeURIComponent(this.messageId)}`
      if(this.messageMediaHrefAppendix && typeof this.messageMediaHrefAppendix === "function")
        href = this.messageMediaHrefAppendix(href)

      return href
    },
    textWithRandomisers(){
      let retval = String(this.text)
      let padding = 0
      return (this.randomisers || []).reduce((acc, rand, currentIndex) => {
        let randDisplayToken = rand[0].replace(/[\[\]]+/g, "").split("|")[0]
        let randDisplayTokenHtml = `<span class="random-edit-trigger" style="color: var(--el-color-danger);font-weight: bold;cursor: pointer;" data-token-index="${currentIndex}">${randDisplayToken}</span>`
        acc = acc.replace(rand[0], randDisplayTokenHtml)
        return acc
      }, retval)
    }
  },
  data() {
    return {
    }
  }
}

</script>

<style scoped>


div.day-separator{

}
p.day-separator {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
  color: var(--el-text-color-secondary);
  border-bottom: 1px solid #CCC;
  margin-left: -20px;
  margin-right: -20px;
}

</style>