<template>
  <support-view
      :reload-flag="reloadFlag"
      :ticket-title-formatter="ticketTitleFormatter"
      :load-tickets-method="loadTickets"
      :fetch-conversation-method="fetchConversation"
      :send-message-method="sendMessage"
  >
    <template #manage-ticket="{ticket, onTicketStatusChange}">
      <manage-ticket
          :ticket="ticket"
          :close-ticket-method="closeTicket"
          :reopen-ticket-method="reopenTicket"
          @onTicketLoaded="setPerformer(ticket)"
          @onTicketStatusChange="onTicketStatusChange"
      >
        <template #admin-actions="{ticket}">
          <div style="display: flex; flex-direction: column; align-items: flex-start">
            <p style="margin: 0px;">Автор:&nbsp;{{ ticket.extras.author }}</p>
            <span class="el-form-item__label" style="margin: 0px;">
              Ответственный: &nbsp;
              <el-select v-model="performer" size="small" @change="saveResponsibleManager(ticket)">
                <el-option
                    v-for="m in responsibleManagersOptions"
                    :value="m.system_id" :label="m.label"
                />
              </el-select>
            </span>
          </div>
        </template>
      </manage-ticket>
    </template>
    <template #ownership-select>
      <dialog-archetype-select
          v-model="activeOwnership"
          :archetypes="ownershipOptions"
          :extra-small="true"
      />
    </template>
  </support-view>

</template>


<script>


import SupportView from "@shared/views/support/index.view.vue";
import DialogArchetypeSelect from "@shared/components/dialogs/archetype-select.component.vue";
import ManageTicket from "@shared/components/support/manage-ticket.component.vue";

export default {
  name: 'ClientSupportRequests',
  title: "Запросы от клиентов",
  components: {ManageTicket, DialogArchetypeSelect, SupportView,},
  props: [],
  watch: {
    activeOwnership(v) {
      if (!!v)
        this.reloadFlag = !this.reloadFlag
    },
  },
  mounted(){
    this.axios.get("/admin/support/getSupportTicketResponsibleManagers")
        .then(resp => {
          this.responsibleManagersOptions = resp.data
        })
  },
  methods: {
    ticketTitleFormatter(d) {
      return `${d.extras.author} | ${d.title}`
    },
    loadTickets(filterToken, offset, archetype) {
      return this.axios.get("/admin/support/clientRequests",
          {params: {filterToken, offset, archetype, ownership: this.activeOwnership}})
    },
    fetchConversation(sentOffset, receivedOffset, ticket, signal) {
      return this.axios.get("/admin/support/getConversation", {
        signal,
        params: {ticket, sentOffset, receivedOffset}
      })
    },
    sendMessage(payload) {
      return this.axios.post("/admin/support/replyTicket", payload)
    },
    closeTicket(ticket){
      return this.axios.post("/admin/support/setTicketClosed", {ticketId: ticket._id})
    },
    reopenTicket(ticket){
      return this.axios.post("/admin/support/setTicketClosed", {ticketId: ticket._id})
    },
    setPerformer(ticket){
      if(ticket && ticket.extras) this.performer = ticket.extras.performer
    },
    saveResponsibleManager(ticket){
      this.axios.post("/admin/support/setTicketPerformer", {ticketId: ticket._id, performer: this.performer})
          .then(resp => {
            this.$gNotify("Ответственный назначен", "success")
          })
          .catch(e => {
            console.error(e)
            this.$gNotify(`Ошибка:\n${e.message}`, "error")
          })
    }
  },
  computed: {},
  data() {
    return {
      activeOwnership: "unassigned",
      ownershipOptions: [
        {key: 'unassigned', label: 'Не назначенные'},
        {key: 'my', label: 'Мои'},
        {key: 'all', label: 'Все'}
      ],

      reloadFlag: false,

      performer: "",
      responsibleManagersOptions: []
    }
  }
}


</script>

<style>

</style>