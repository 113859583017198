<template>
  <div>
<!--    <Breadcrumbs primary="Финансы" secondary="Настройки"/>-->

    <el-row :gutter="20">
      <el-col :span="24">
        <el-tabs v-model="visiblePanel" class="primary-heading-tabs">
          <el-tab-pane label="Нумерация актов" name="numbering">
            <FinanceActsNumbering
                :settings="settings"
                :loading="settingsLoading"
                @reload="refreshSettings"
            />
          </el-tab-pane>

<!--          <el-tab-pane label="Период счетов" name="billPeriod">-->

<!--          </el-tab-pane>-->
          <el-tab-pane label="Форма счета" name="legalEntities">
              <FinanceLegalEntities
                :settings="settings"
                :loading="settingsLoading"
                @reload="refreshSettings"
              />
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>


import moment from "moment"
import FinanceLegalEntities from "../../components/finance/settings/legalEntities.component.vue";
import FinanceActsNumbering from "../../components/finance/settings/actsNumbering.component.vue";

export default {
  name: "FinanceSettings",
  title: "Настройки",
  components: {
    FinanceActsNumbering,
    FinanceLegalEntities
  },
  mounted(){
    this.refreshSettings()
  },
  methods: {
    refreshSettings(){
      this.settingsLoading = true
      this.axios.get("/admin/finance/getSettings")
          .then(resp => {
            this.settings = resp.data
            this.settingsLoading = false
          })
    }
  },
  computed: {},
  data() {
    return {
      visiblePanel: "numbering",
      settingsLoading: false,
      settings: {}
    }
  },
}

</script>