

import {defineComponent} from 'vue'
import RemoveButton from "@shared/components/layout/removeButton.component.vue";
import {
  RoutingTypes,
  RoutingTypesT, RoutingTypesTS
} from "@shared/enums/routing";

interface TrafficSchema{
  _id: string
  is_common: boolean
  route_type: string
  sender: string
  priority: number
  operator_group: string
  agregator_pool: string
  registered_sender_agregator_pool: string
  unregistered_sender_agregator_pool: string
  comment: string
  separate_traffic_type: boolean
}

class GlobalTrafficSchema implements TrafficSchema{
  _id = ""
  route_type = "operatorGroup"
  sender = ""
  priority = 0
  operator_group = ""
  agregator_pool = ""
  registered_sender_agregator_pool = ""
  unregistered_sender_agregator_pool = ""
  comment = ""
  separate_traffic_type = false
  is_common = true

  fromJson(v): TrafficSchema {
    this._id = v._id || this._id
    this.route_type = v.route_type || this.route_type
    this.sender = v.sender || this.sender
    this.priority = Number(v.priority) || this.priority
    this.operator_group = v.operator_group || this.operator_group
    this.agregator_pool = v.agregator_pool || this.agregator_pool
    this.registered_sender_agregator_pool = v.registered_sender_agregator_pool || this.registered_sender_agregator_pool
    this.unregistered_sender_agregator_pool = v.unregistered_sender_agregator_pool || this.unregistered_sender_agregator_pool
    this.comment = v.comment || this.comment
    this.separate_traffic_type = v.separate_traffic_type === true || v.separate_traffic_type === '1' || false
    return this
  }
}

const rtp = {
  [RoutingTypes.Operator] : 0,
  [RoutingTypes.OperatorGroup] : 1,
  [RoutingTypes.Country] : 2,
}


export default defineComponent({
  name: "TrafficSchemas",
  title: "Схемы трафика",
  components: {RemoveButton},
  methods: {
    async getAllSchemas() {
      this.loading = true
      let resp = await this.axios.get("/admin/routing/trafficSchemas")
      this.allSchemas = resp.data.map(t => new GlobalTrafficSchema().fromJson(t)).sort((s1, s2) => {
        let priorityDiff = s1.priority - s2.priority
        let typeDiff = rtp[s1.route_type] - rtp[s2.route_type]
        let route1 = this.getRouteName(s1)
        let route2 = this.getRouteName(s2)

        let routeDiff = route1.startsWith("РФ") && route2.startsWith("РФ") ? 0 :
            route1.startsWith("РФ") && !route2.startsWith("РФ") ? -1 :
                !route1.startsWith("РФ") && route2.startsWith("РФ") ? 1 :
                    route1.localeCompare(route2)
        return priorityDiff === 0 ? typeDiff === 0 ? routeDiff : typeDiff : priorityDiff
      })
      this.loading = false
    },
    getPoolName(schema: TrafficSchema) {
      if (schema.separate_traffic_type) {
        return [
          "РЕГ > " + (this.allPools.find(p => p._id === schema.registered_sender_agregator_pool) || {name: "N/A"}).name,
          "НЕ РЕГ > " + (this.allPools.find(p => p._id === schema.unregistered_sender_agregator_pool) || {name: "N/A"}).name
        ].join("<br/>")
      } else
        return (this.allPools.find(p => p._id === schema.agregator_pool) || {name: "N/A"}).name
    },
    getRouteName(schema: TrafficSchema){
      switch(schema.route_type){
        case RoutingTypes.Operator:
          return (this.$store.getters.operators.find(o => o._id === schema.operator_group) || {OrgName: "N/A"}).OrgName
        case RoutingTypes.OperatorGroup:
          return (this.$store.getters.operatorsGroups.find(o => o._id === schema.operator_group) || {title: "N/A"}).title
        case RoutingTypes.Country:
          return this.$store.getters.countries.find(o => String(o.country_code) === String(schema.operator_group)).country_name
      }
    },
    addSchema() {
      this.editingSchema = new GlobalTrafficSchema()
      this.addEditModalVisible = true
    },
    editSchema(schema: TrafficSchema) {
      this.editingSchema = Object.assign({}, schema)
      this.addEditModalVisible = true
    },
    async removeSchema(schemaId: string) {
      try {
        let confirm = await this.$confirm(`Подтвердите удаление схемы`)
        if (!confirm) return
      }catch (e){return}
      this.removingOne = true
      await this.axios.delete("/admin/routing/schema", {params: {schemaId}})
      this.$gNotify("Схема удалена", "success")
      this.allSchemas.splice(this.allSchemas.findIndex(m => m._id === schemaId), 1)
      this.removingOne = false
    },
    async saveSchema() {
      this.saving = true
      let r = await this.axios.post("/admin/routing/saveSchema", {schema: this.editingSchema})
      this.$gNotify("Схема сохранена", "success")
      this.getAllSchemas()
      this.addEditModalVisible = false
      this.saving = false
    },


  },
  mounted() {
    this.getAllSchemas()
    this.axios.get("/admin/routing/allPools").then(resp => this.allPools = resp.data)
    this.axios.get("/admin/support/allActiveNames").then(resp => this.allNames = (resp.data || []).map(n => {return {label: n, value: n}}))
  },
  computed: {
    RoutingTypesTS() {
      return RoutingTypesTS
    },
    title(): string {
      return this.editingSchema._id ? "Редактирование схемы" : "Новая схема"
    }
  },
  setup() {
    return {RoutingTypes, RoutingTypesT, RoutingTypesTS}
  },
  data() {
    return {
      loading: false,
      allSchemas: [] as TrafficSchema[],
      allPools: [] as any[],
      allNames: [] as any[],
      removingOne: false,
      saving: false,
      addEditModalVisible: false,
      editingSchema: new GlobalTrafficSchema() as TrafficSchema
    }
  },
})

