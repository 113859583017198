

import {defineComponent} from 'vue'
import TimeSearch from "@shared/components/reports/time-search.component.vue";
import {
  ModerationActions,
  ModerationActionsT,
  ModerationRuleLookupPlace,
  ModerationRuleLookupPlaceT
} from "@shared/enums/moderation";
import ClientSelect from "../../components/layout/client-select.component.vue";


export default defineComponent({
  name: "ModerationQueue",
  title: "Очередь модерации",
  components: {ClientSelect, TimeSearch},

  methods: {
    handleSelectionChange(val) {
      this.selectedMessages = val
    },
    handlePageSizeChange(newSize) {
      this.pageSize = newSize
      this.getMessages(false)
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage
      this.getMessages(false)
    },
    getSearchParams(){
      return {filter: this.filterForm, pageSize: this.pageSize, currentPage: this.currentPage}
    },
    async getMessages(doCount = true) {
      this.selectedMessages = []
      this.loading = true
      if (doCount)
        this.currentPage = 1
      this.filterForm.date = (this.$refs.timeSearch as any).getDateTimeFilter()
      let resp = await this.axios.post(
          "/admin/moderation/getMessages",
          {...this.getSearchParams(), doCount}
      )
      this.loadedMesages = resp.data.messages
      if (doCount)
        this.totalRows = resp.data.totalCount
      this.loading = false
    },
    getMarked(sms, what) {
      let originalText = String(what === "text" ? sms.text : sms.senderName)
      let colorTokens = new Array<string>()
      for (let token of sms.tokens.sort((t1, t2) => t1.index - t2.index)) {
        if (token.where === what) {
          let tokenText = originalText.substring(token.index, token.index + token.token.length)
          colorTokens.push(`<span class="red-token">${tokenText}</span>`)
          originalText = originalText.substring(0, token.index) + "|" + new Array(token.token.length).join("_") + "|" + originalText.substring(token.index + token.token.length)
          // console.log(originalText)
        }
      }
      originalText = this.escapeHtml(originalText)
      // console.log(originalText)
      for (let token of colorTokens)
        originalText = originalText.replace(/\|_+\|/, token)
      return originalText
    },

    finalizeAllSelected(action) {
      this.$confirm(`Подтвердите действие: ${action === 'allow' ? 'Разрешить' : 'Заблокировать'}`)
          .then(async r => {
            if(r){
              let ids = this.selectedMessages.map(m => m._id)
              this.finalizing = true
              await this.axios.post("/admin/moderation/finalizeModerationStatus", {ids, action})
              this.finalizing = false
              this.getMessages()
            }
          })
          .catch(console.error)
    },
    async finalizeOne(id, action){
      await this.axios.post("/admin/moderation/finalizeModerationStatus", {ids: [id], action})
      this.loadedMesages.splice(this.loadedMesages.findIndex(m => m._id === id), 1)
    },
    exportMessages() {
      try {
        (document.getElementById("exportBody") as HTMLInputElement).value = JSON.stringify(this.getSearchParams());
        (document.getElementById("exportForm") as HTMLFormElement).submit()

      }catch (e){
        console.log(e)
      }
    }
  },

  mounted() {
    this.getMessages()
  },
  setup() {
    return {ModerationActions, ModerationRuleLookupPlace, ModerationActionsT, ModerationRuleLookupPlaceT}
  },
  data() {
    return {
      loading: false,
      currentPage: 1,
      pageSize: 100,
      loadedMesages: [] as any[],
      totalRows: 0,
      filterForm: {
        type: ModerationActions.Moderation,
        date: {
          from: "",
          to: ""
        },
        users: []
      },
      finalizing: false,
      selectedMessages: [] as any[]
    }
  },
})

