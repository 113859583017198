<template>
  <div>
    <el-dialog
        v-model="addEditFormVisible"
        :title="(entityForm._id ? 'Редактировать' : 'Добавить') + 'компанию'"
        class="modal-95-70-60"
    >

      <el-form label-position="top" ref="legalEntityForm" :rules="formRules" :model="entityForm">

        <el-row :gutter="20">
          <el-col :md="12" :xs="24">
            <el-form-item label="ОГРН" prop="ogrn">
              <el-input v-model="entityForm.ogrn"/>
            </el-form-item>
            <el-form-item label="КПП" prop="kpp">
              <el-input v-model="entityForm.kpp"/>
            </el-form-item>
            <el-form-item label="БИК" prop="bik">
              <el-input v-model="entityForm.bik"/>
            </el-form-item>
            <el-form-item label="Название банка" prop="bank_name">
              <el-input v-model="entityForm.bank_name"/>
            </el-form-item>
            <el-form-item label="Правовая форма" prop="law_form">
              <el-input v-model="entityForm.law_form"/>
            </el-form-item>
            <el-form-item label="Ф.И.О. директора" prop="initials">
              <el-input v-model="entityForm.initials"/>
            </el-form-item>
          </el-col>
          <el-col :md="12" :xs="24">
            <el-form-item label="ИНН" prop="inn">
              <el-input v-model="entityForm.inn"/>
            </el-form-item>
            <el-form-item label="Р/С" prop="rs">
              <el-input v-model="entityForm.rs"/>
            </el-form-item>
            <el-form-item label="Адрес" prop="address">
              <el-input v-model="entityForm.address"/>
            </el-form-item>
            <el-form-item label="Название компании" prop="company_name">
              <el-input v-model="entityForm.company_name"/>
            </el-form-item>
            <el-form-item label="К/С" prop="ks">
              <el-input v-model="entityForm.ks"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider class="blue-divider"/>
        <el-row>
          <el-col :span="24">
            <el-form-item label="Печать" prop="file.stamp">
              <el-upload
                  v-model:file-list="fileList"
                  :action="`${axios.defaults.baseURL}/admin/finance/uploadEntityFile?type=stamp&token=${$store.getters.bareToken}`"
                  :on-success="setUploadedFile('stamp')"
              >
                <el-button type="primary">Загрузить</el-button>
              </el-upload>
            </el-form-item>
            <el-form-item label="Подпись" prop="file.signature">
              <el-upload
                  v-model:file-list="fileList"
                  :action="`${axios.defaults.baseURL}/admin/finance/uploadEntityFile?type=signature&token=${$store.getters.bareToken}`"
                  :on-success="setUploadedFile('signature')"
              >
                <el-button type="primary">Загрузить</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <template #footer>
      <span class="dialog-footer">
        <el-button @click="addEditFormVisible = false">Закрыть</el-button>
        <el-button type="success" @click="saveCompany" :loading="saveLoading">Сохранить</el-button>
      </span>
      </template>
    </el-dialog>

    <el-row :gutter="20">
      <el-col :span="24">
        <TableSpinner :loading="loading">
          <el-table
              :data="legalEntities" style="width: 100%;"
          >
            <el-table-column prop="company_name" label="Название компании" min-width="200"/>
            <el-table-column prop="inn" label="ИНН" min-width="150"/>
            <el-table-column prop="bank_name" label="Название банка" min-width="200"/>
            <el-table-column prop="primary" label="Основная" min-width="100">
              <template #default="scope">
                <el-button type="success" size="small"
                           class="circle primary"
                           v-if="scope.row.primary" disabled></el-button>
                <el-button type="default" size="small"
                           class="circle disabled"
                           v-else
                           @click="setDefaultEntity(scope.row._id)"></el-button>
              </template>
            </el-table-column>
            <el-table-column min-width="100">
              <template #default="scope">
                <el-button-group size="small">
                  <el-button text class="text-button table-text-button" @click="showAddEditForm(scope.row)">
                    <font-awesome-icon icon="edit" class="el-icon--left"></font-awesome-icon>&nbsp;Редактировать
                  </el-button>

                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
          <el-button type="success" @click="showAddEditForm()" style="margin-top: 40px">
            <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp;Добавить компанию
          </el-button>
        </TableSpinner>

      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: "FinanceLegalEntities",
  props: ["settings", "loading"],
  components: {},
  methods: {
    async showAddEditForm(entity) {
      this.addEditFormVisible = true
      await this.$nextTick()
      if (!entity) {
        this.entityForm._id = ""
        this.$refs.legalEntityForm.resetFields()
        this.stamp = []
        this.signature = []
      }else
        this.entityForm = entity

    },
    setDefaultEntity(entityId) {
      this.axios.post("/admin/finance/setDefaultFinanceEntity", {entityId})
          .then(resp => {
            this.$emit('reload')
          })
    },
    setUploadedFile(type){
      return resp => {
        this.entityForm.files[type] = resp.filePath
      }
    },
    saveCompany(){
      if(!this.entityForm._id &&  !this.entityForm.files.signature)
        return this.$gNotify("Загрузите картинки подписи и печати", "error")

      this.saveLoading = true
      this.axios.post("/admin/finance/saveFinanceEntity", {entity: this.entityForm})
          .then(resp => {
            this.saveLoading = false
            this.$refs.legalEntityForm.resetFields()
            this.stamp = []
            this.signature = []
            this.entityForm._id = ""
            this.addEditFormVisible = false
            this.$emit('reload')
          })
    }
  },
  computed: {
    legalEntities() {
      return this.settings && this.settings.legalEntities ? this.settings.legalEntities : []
    }
  },
  data() {
    return {
      addEditFormVisible: false,
      saveLoading: false,
      entityForm: {
        _id: "",
        ogrn:"",
        inn:"",
        kpp:"",
        rs: "",
        bik:"",
        address:"",
        bank_name: "",
        company_name: "",
        law_form:"",
        ks:"",
        initials:"",
        files: {
          signature: "",
          stamp: ""
        }
      },
      formRules: {},
      stamp: [],
      signature: []
    }
  },
}

</script>