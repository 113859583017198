<template>
  <el-container class="full-height">

    <el-header class="light-header" height="100px">
      <Header/>
    </el-header>
    <el-container>
      <el-aside class="dark-aside" id="main-menu-wrapper" :class="$store.getters.menuOpened ? 'prog-open': ''">
        <Menu @closeMenu="$store.dispatch('toggleMenu')"/>
      </el-aside>
      <div class="overlay" v-show="$store.getters.menuOpened" @click="$store.dispatch('toggleMenu')"></div>
      <el-container>
        <el-main style="padding: 10px" class="el-main-admin">
          <router-view></router-view>
        </el-main>
<!--        <el-footer style="border-top: 1px solid  #CCC;display: flex;align-items: center;height:auto">-->
<!--          <p class="el-form-item__label no-padding small" style="margin: 20px; height: auto">SmsGold © 2014 - {{ year }}</p>-->
<!--        </el-footer>-->
      </el-container>
    </el-container>
  </el-container>

</template>


<script>

import Menu from '@/components/layout/menu.component'
import Header from '@/components/layout/header.component'
import moment from "moment"

export default {
  name: 'CabinetLayout',
  provide(){
    return {
      isAdminView: true
    }
  },
  components: {
    Menu,
    Header
  },
  data(){
    return {
      year: moment().format("YYYY")
    }
  }
}

</script>

<style>
#balance-container{
  padding-top: 40px;
  padding-left: 20px;
}
.dark-aside {
  background-color: #24313A;
  margin-top: -1px;
}

.light-header {
  border-bottom: 1px solid #d8d8d8;
  padding: 0px 20px;
  height: auto;
}

#menu-trigger {
  display: none
}

</style>